import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert'
import CardTitle from '../shared/CardTitle'

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    docTypeSelect: {},
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
      },
    inline: {
        display: 'inline',
      },
}))

export default function TransactionCommentsCard(props) {
    const classes = useStyles()
    const [transactionComments, setTransactionComments] = React.useState(props.transactionComments)

    return (
        <>
            <CardTitle title="Comments" checkOn={false} disablePadding={true}>
                {
                    transactionComments?.length === 0 && (
                        <Alert severity="info" className="w-100">
                            No records found.
                        </Alert>
                    )
                }
                <List className={classes.root}>
                    {
                        transactionComments?.map((comm, index) => {
                            const avatarText = comm.userFirstName.charAt(0) + comm.userLastName.charAt(0)
                            return (
                                <>
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            <Avatar>{ avatarText }</Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={comm.comment}
                                            secondary={
                                                <React.Fragment>
                                                    <Typography
                                                        component="span"
                                                        variant="body2"
                                                        className={classes.inline}
                                                        color="textPrimary"
                                                    >
                                                        { comm.userFirstName + " " + comm.userLastName }
                                                    </Typography>
                                                    {(comm.date ? (" — " + new Date(comm.date).toLocaleString()) : "")}
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                </>
                            )
                        })
                    }
                </List>
            </CardTitle>
        </>
    )
}
