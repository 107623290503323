import React from 'react'
import CardTitle from './CardTitle'
import { renderTable } from './TableHelper'
import { makeStyles } from '@material-ui/core/styles'

export default function ShipmentRoutingCard(props) {
    const { shipment } = props

    const useStyles = makeStyles(theme => ({
        title: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        },
        textFieldForm: {
            width: theme.textFieldForm.width
        }
    }))

    const classes = useStyles()

    const routingInfo = [
        { key: 'vesselName', label: 'Vessel Name', icon: false },
        { key: 'masterBillNumber', label: 'Master Bill', icon: false },
        { key: 'legNumber', label: 'Leg #', icon: false },
        { key: 'legType', label: 'Leg Type', icon: false },
        { key: 'transportMode', label: 'Transport Mode', icon: false },
        { key: 'consolMode', label: 'Consol Mode', icon: false },
        { key: 'loadPort', label: 'Load Port', icon: false },
        { key: 'etd', label: 'ETD', icon: false },
        { key: 'atd', label: 'ATD', icon: false },
        { key: 'dischargePort', label: 'Discharge Port', icon: false },
        { key: 'eta', label: 'ETA', icon: false },
        { key: 'ata', label: 'ATA', icon: false },
        { key: 'voyageFlight', label: 'Voyage Flight', icon: false },
        { key: 'vesselLloydsIMO', label: 'IMO Number', icon: false }
    ]

    return (
        <CardTitle title="Routing" checkOn={false} disablePadding={true}>
            {renderTable(
                shipment?.routingInfo ?? [],
                routingInfo,
                'routing',
                classes,
                null,
                {
                    maxHeight: '200px'
                }
            )}
        </CardTitle>
    )

}