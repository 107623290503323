export const getOrgNameFromOrganization = (organization) => {
    if (organization === null || organization === undefined) {
        return null;
    }
    if (organization.subdomain?.startsWith('efl.') || organization.subdomain?.startsWith('efltrn.')) {
        return "EFL";
    }
    else if (organization.subdomain?.startsWith('havi.') || organization.subdomain?.startsWith('havitst.')) {
        return 'HAVI';
    }
    else if (organization.subdomain?.startsWith('owl.') || organization.subdomain?.startsWith('owltst.')) {
        return 'OWL';
    }
    else if (organization.subdomain?.startsWith('visy.') || organization.subdomain?.startsWith('visytrn.')) {
        return 'VISY';
    }
    else {
        return null;
    }
}
