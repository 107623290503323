import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

export default function OrderSupplierInfoCard({ itemextra }) {
    const theme = useTheme()

    useEffect(() => {}, [])

    return (
        <div className="p-0 pl-3 data-data-info w-100">
            <h5 class="text-Curious-blue my-2 font-14" checkOn={false}>
                Delivery Info
            </h5>

            <Grid
                container
                direction="row"
                spacing={0}
                className={theme.bgColorSection}>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={1}>
                        <Grid item xs={12} lg={12}>
                            <div>
                                <span className="font-12 font-raleway-medium">
                                    Address
                                </span>
                                <br />
                                <span class="mb-0 font-14  font-nutosans-bold text-black">
                                    {(itemextra !== null &&
                                        itemextra.deliveryInfo.name) ||
                                        ''}
                                </span>
                                <br />
                                <span class="mb-0 font-14  font-nutosans-bold text-black">
                                    {(itemextra !== null &&
                                        itemextra.deliveryInfo.shortCode) ||
                                        ''}
                                </span>
                                <br />
                                <span class="mb-0 font-14  font-nutosans-bold text-black">
                                    {(itemextra !== null &&
                                        itemextra.deliveryInfo.addresLine1) ||
                                        ''}
                                </span>
                                <br />
                                <span class="mb-0 font-14  font-nutosans-bold text-black">
                                    {(itemextra !== null &&
                                        itemextra.deliveryInfo.city) ||
                                        ''}
                                    ,{' '}
                                    {(itemextra !== null &&
                                        itemextra.deliveryInfo.state) ||
                                        ''}
                                    ,{' '}
                                    {(itemextra !== null &&
                                        itemextra.deliveryInfo.postalCode) ||
                                        ''}
                                </span>
                                <br />
                                <span class="mb-0 font-14  font-nutosans-bold text-black">
                                    {(itemextra !== null &&
                                        itemextra.deliveryInfo.country) ||
                                        ''}
                                </span>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
