import { ApplicationPaths } from './../api-authorization/ApiAuthorizationConstants'

export function navItems(
    authenticated,
    isAdmin,
    canSeeCustoms,
    canCreateShipment,
    canSearchShipment,
    canSeeBooking,
    canSeeOrders,
    canCreateOrder,
    canSeeAccounting,
    canSeeContainers,
    organizationCustomLinkUrl,
    organizationCustomLinkName,
    organizationCustomLink2Url,
    organizationCustomLink2Name,
    organizationCustomLink3Url,
    organizationCustomLink3Name,
    organizationCustomLink4Url,
    organizationCustomLink4Name,
    organizationCustomLink5Url,
    organizationCustomLink5Name
) {
    const reportPath = {
        pathname: `${ApplicationPaths.Reports}`,
        state: { local: true }
    }
    const logoutPath = {
        pathname: `${ApplicationPaths.LogOut}`,
        state: { local: true }
    }
    const dashboardPath = {
        pathname: `${ApplicationPaths.DefaultLoginRedirectPath}`
    }
    const shipmentsPath = {
        pathname: `${ApplicationPaths.Shipments}`,
        CreateShipment: `${ApplicationPaths.CreateShipment}`,
        Delivery: `${ApplicationPaths.Delivery}`,
        Pickup: `${ApplicationPaths.Pickup}`,
        Container: `${ApplicationPaths.Container}`,
    }
    const deliveryPath = {
        pathname: `${ApplicationPaths.Delivery}`,
    }
    const pickupPath = {
        pathname: `${ApplicationPaths.Pickup}`,
    }
    const customsPath = {
        pathname: `${ApplicationPaths.Customs}`
    }

    const accountingPath = {
        pathname: `${ApplicationPaths.Accounting}`
    }

    const bookingsPath = {
        pathname: `${ApplicationPaths.Bookings}`
    }

    const ordersPath = {
        pathname: `${ApplicationPaths.Orders}`,
        createOrder: `${ApplicationPaths.CreateOrder}`
    }

    const orderLinesPath = {
        pathname: `${ApplicationPaths.OrderLines}`
    }

    const customLinkPath = {
        pathname: organizationCustomLinkUrl || null
    }

    const customLink2Path = {
        pathname: organizationCustomLink2Url || null
    }

    const customLink3Path = {
        pathname: organizationCustomLink3Url || null
    }

    const customLink4Path = {
        pathname: organizationCustomLink4Url || null
    }

    const customLink5Path = {
        pathname: organizationCustomLink5Url || null
    }
    // const pic0 = require('./logo.png');

    const clientsPath = { pathname: `${ApplicationPaths.Clients}` }
    const usersPath = { pathname: `${ApplicationPaths.OrgUsers}` }
    const dataMappingPath = { pathname: `${ApplicationPaths.DataMappingIndex}` }
    const organizationsPath = { pathname: `${ApplicationPaths.Organizations}` }
    const accountPath = { pathname: `${ApplicationPaths.Account}` }
    const adminUsersPath = { pathname: `${ApplicationPaths.AdminUsers}` }

    if (!authenticated) {
        return { list: [] }
    }

    var list = [
        {
            id: 1,
            title: '',
            items: [
                {
                    id: 1,
                    name: 'Dashboard',
                    icon: 'dashboard',
                    link: dashboardPath,
                    hidden: !authenticated,
                    external: false
                }
            ]
        },
        {
            id: 2,
            title: '',
            items: [
                {
                    id: 1,
                    name: 'Purchase Orders',
                    icon: 'assignment',
                    link: '#',
                    hidden: !canSeeOrders,
                    external: false,
                    subitems: [
                        {
                            id: 1,
                            name: 'Order Search',
                            // icon: 'search',
                            Image: '/logo.png',
                            link: ordersPath,
                            hidden: false,
                            external: false
                        },
                        {
                            id: 2,
                            name: 'Order Line Search',
                            // icon: 'reorder',
                            link: orderLinesPath,
                            hidden: false,
                            external: false
                        },
                        {
                            id: 3,
                            name: 'Create Order',
                            //icon: 'add',
                            link: ordersPath.createOrder,
                            hidden: !canCreateOrder,
                            external: false
                        }
                    ]
                }
            ]
        },
        {
            id: 3,
            title: '',
            items: [
                {
                    id: 1,
                    name: 'Bookings',
                    icon: 'assignment',
                    link: '#',
                    hidden: !canSeeBooking && !canCreateShipment,
                    external: false,
                    subitems: [
                        {
                            id: 1,
                            name: 'Bookings Search',
                            // icon: 'cropfree',
                            link: bookingsPath,
                            hidden: !canSeeBooking,
                            external: false
                        },
                        {
                            id: 1,
                            name: 'Create Booking',
                            // icon: 'add',
                            link: shipmentsPath.CreateShipment,
                            hidden: !canCreateShipment,
                            external: false
                        }
                    ]
                }
            ]
        },
        {
            id: 4,
            title: '',
            items: [
                {
                    id: 1,
                    name: 'Shipments',
                    icon: 'assignment_turned_in',
                    link: '#',
                    hidden: !canSearchShipment,
                    external: false,
                    subitems: [
                        {
                            id: 1,
                            name: 'Shipment Search',
                            // icon: 'cropfree',
                            link: shipmentsPath,
                            hidden: !canSearchShipment,
                            external: false
                        }
                    ]
                }
            ]
        },
        {
            id: 5,
            title: '',
            items: [
                {
                    id: 1,
                    name: 'Containers',
                    icon: 'border_all',
                    link: '#',
                    hidden: !canSeeContainers,
                    external: false,
                    subitems: [
                        {
                            id: 1,
                            name: 'Container Search',
                            // icon: 'cropfree',
                            link: shipmentsPath.Container,
                            hidden: !canSeeContainers,
                            external: false
                        },
                        // {
                        //     id: 2,
                        //     name: 'Delivery Management',
                        //     // icon: 'cropfree',
                        //     link: shipmentsPath.Delivery,
                        //     hidden: !canSearchShipment,
                        //     external: false
                        // },
                        // {
                        //     id: 3,
                        //     name: 'Pickup Management',
                        //     // icon: 'cropfree',
                        //     link: shipmentsPath.Pickup,
                        //     hidden: !canSearchShipment,
                        //     external: false
                        // }
                    ]
                }
            ]
        },
        {
            id: 6,
            title: '',
            items: [
                {
                    id: 1,
                    name: 'Customs',
                    icon: 'policy',
                    link: customsPath,
                    hidden: !canSeeCustoms,
                    external: false
                }
            ]
        },
        {
            id: 7,
            title: '',
            items: [
                {
                    id: 1,
                    name: 'Accounting',
                    icon: 'description',
                    link: accountingPath,
                    hidden: !canSeeAccounting,
                    external: false
                }
            ]
        },
        {
            id: 8,
            title: '',
            items: [
                {
                    id: 1,
                    name: 'Account',
                    icon: 'person',
                    link: accountPath,
                    hidden: !authenticated,
                    external: false
                }
            ]
        }
    ]

    var idReference = 9

    if (organizationCustomLinkUrl !== null) {
        list.push({
            id: idReference,
            title: '',
            items: [
                {
                    id: 1,
                    name: organizationCustomLinkName || 'Custom Link',
                    icon: 'link',
                    link: customLinkPath || '#',
                    external: true
                }
            ]
        })

        idReference = idReference + 1
    }

    if (organizationCustomLink2Url !== null) {
        list.push({
            id: idReference,
            title: '',
            items: [
                {
                    id: 1,
                    name: organizationCustomLink2Name || 'Custom Link',
                    icon: 'link',
                    link: customLink2Path || '#',
                    external: true
                }
            ]
        })

        idReference = idReference + 1
    }

    if (organizationCustomLink3Url !== null) {
        list.push({
            id: idReference,
            title: '',
            items: [
                {
                    id: 1,
                    name: organizationCustomLink3Name || 'Custom Link',
                    icon: 'link',
                    link: customLink3Path || '#',
                    external: true
                }
            ]
        })

        idReference = idReference + 1
    }

    if (organizationCustomLink4Url !== null) {
        list.push({
            id: idReference,
            title: '',
            items: [
                {
                    id: 1,
                    name: organizationCustomLink4Name || 'Custom Link',
                    icon: 'link',
                    link: customLink4Path || '#',
                    external: true
                }
            ]
        })

        idReference = idReference + 1
    }

    if (organizationCustomLink5Url !== null) {
        list.push({
            id: idReference,
            title: '',
            items: [
                {
                    id: 1,
                    name: organizationCustomLink5Name || 'Custom Link',
                    icon: 'link',
                    link: customLink5Path || '#',
                    external: true
                }
            ]
        })

        idReference = idReference + 1
    }

    if (isAdmin) {
        list.push({
            id: 20,
            title: '',
            items: [
                {
                    id: 1,
                    name: 'Administration',
                    icon: 'build',
                    link: '#',
                    hidden: false,
                    external: false,
                    subitems: [
                        {
                            id: 1,
                            name: 'Organization',
                            // icon: 'business',
                            link: organizationsPath,
                            hidden: false,
                            external: false
                        },
                        {
                            id: 2,
                            name: 'Clients',
                            // icon: 'person',
                            link: clientsPath,
                            hidden: false,
                            external: false
                        },
                        {
                            id: 2,
                            name: 'Client Users',
                            // icon: 'person',
                            link: usersPath,
                            hidden: false,
                            external: false
                        },
                        {
                            id: 3,
                            name: 'Data Mapping',
                            // icon: 'storage',
                            link: dataMappingPath,
                            hidden: false,
                            external: false
                        },
                        {
                            id: 4,
                            name: 'Admin Users',
                            // icon: 'supervisor_account',
                            link: adminUsersPath,
                            hidden: false,
                            external: false
                        }
                    ]
                }
            ]
        })
    }
    list.push({
        id: 22,
        title: '',
        items: [
            {
                id: 1,
                name: 'Reports',
                icon: 'assessment',
                link: reportPath,
                hidden: !authenticated,
                external: false
            }
        ]
    })
    list.push({
        id: 21,
        title: '',
        items: [
            {
                id: 1,
                name: 'Logout',
                icon: 'meeting_room',
                link: logoutPath,
                hidden: !authenticated,
                external: false
            }
        ]
    })

    return {
        list: list
    }
}
