import React, { useState, useEffect } from 'react'
import authService from './api-authorization/AuthorizeService'
import OrdersTable from './OrdersTable'
import { canSeeOrders } from '../utils/permissions'
import { LinearProgress } from '@material-ui/core'
import UnauthorizedMessage from './shared/UnauthorizedMessage'

export default function OrderLine() {
    const [loading, setIsLoading] = useState(true)
    const [loadingShow, setIsLoadingShow] = useState(true)
    const [show, setShow] = useState(false)

    useEffect(() => {
        getAppInfo()
    }, [])

    const getAppInfo = async () => {
        const token = await authService.getAccessToken()

        const response = await fetch('api/app/info', {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        })

        setIsLoading(false)

        if (response.ok) {
            setIsLoadingShow(true)
            const data = await response.json()
            setShow(await canSeeOrders(data))
            setIsLoadingShow(false)
        }
    }

    return (
        <div>
            {loading || loadingShow ? (
                <LinearProgress />
            ) : show ? (
                <OrdersTable searchItem="OrderLines" itemKey="orderline" />
            ) : (
                UnauthorizedMessage({ moduleName: 'Order Lines' })
            )}
        </div>
    )
}
