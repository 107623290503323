import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { renderTable } from '../shared/TableHelper'
import CardTitle from '../shared/CardTitle'
import Alert from '@material-ui/lab/Alert'

const classes = makeStyles(theme => ({}))

const containers = [
    { key: 'partNumber', label: 'Part #' },
    { key: 'partDescription', label: 'Part Description' },
    { key: 'quantity', label: 'Quantity'},
    { key: 'quantityUOM', label: 'Quantity UOM' },
    { key: 'price', label: 'Item Price' },
    { key: 'containerNumber', label: 'Container #' }
]

export default function CreateOrderlinesTable({ item, organization }) {
    return (
        <CardTitle title="Order Lines" checkOn={false} disablePadding={true}>
            {
                item !== null &&
                item !== undefined &&
                item.lines !== null &&
                item.lines !== undefined ? (
                    renderTable(
                        item.lines,
                        containers,
                        'container',
                        classes,
                        null,
                        {
                            maxHeight: '200px'
                        }
                    )
                ) : (
                    <Alert severity="info" className="w-100">
                        No records found.
                    </Alert>
                )}
        </CardTitle>
    )
}

