import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { renderTable } from '../shared/TableHelper'
import CardTitle from '../shared/CardTitle'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const classes = makeStyles(theme => ({}))

const containers = [
    { key: 'referenceNumber', label: 'Reference #' },
    { key: 'containerNumber', label: 'Container #' },
    { key: 'productCode', label: 'Product Code', link: true },
    { key: 'quantity', label: 'Pce Count' },
    { key: 'quantityUOM', label: 'Pce Count UOM' },
    { key: 'weight', label: 'Weight' },
    { key: 'weightUOM', label: 'Weight UOM' },
    { key: 'volume', label: 'Volume' },
    { key: 'volumeUOM', label: 'Volume UOM' },
    { key: 'dimensionLength', label: 'Length' },
    { key: 'dimensionWidth', label: 'Width' },
    { key: 'dimensionHeight', label: 'Height' },
    { key: 'dimensionUOM', label: 'Length UOM' },
    { key: 'commodityCode', label: 'Commodity' },
    { key: 'goodsDescription', label: 'Goods Description' }
]

const normanHKContainers = [
    { key: 'weight', label: 'Weight' },
    { key: 'weightUOM', label: 'Weight UOM' },
    { key: 'quantity', label: 'Pce Count' },
    { key: 'quantityUOM', label: 'Pce Count UOM' },
    { key: 'commodityCode', label: 'Description' }
]

function getContainers(isNormanHK) {
    if (isNormanHK) {
        return normanHKContainers
    } else {
        return containers
    }
}

export default function ShipmentPacklinesTable({ item, organization }) {
    const [productDialogOpen, setProductDialogOpen] = React.useState(false)

    const handleClose = () => {
        setProductDialogOpen(false)
    }

    const handleProductDialogClickOpen = () => {
        setProductDialogOpen(true)
    }

    const renderAllProductsDialog = (productCodesArray) => {
        return (
            <>
                <Button variant="outlined" color="primary" onClick={
                    () => {
                        handleProductDialogClickOpen()
                    }
                }>
                    View Multiple Products
                </Button>
                <Dialog
                    open={productDialogOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Pack Line Products"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {
                            productCodesArray.map((i, index) => {
                                return (
                                    <h4 key={index}>
                                        {i}
                                    </h4>
                                )
                            })
                        }
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Close
                    </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
    
    const renderSpecialColumns = (i, index, arrayKey) => {
        if (
            arrayKey.key === 'productCode'
        ) {
            var orderRef = i[arrayKey.key]
            var array = []
            if (orderRef != null) {
                array = orderRef.split(',')
            }
    
            return array.length > 1 ? (
                renderAllProductsDialog(array)
            ) : (
                i[arrayKey.key]
            )
        }
    }

    return (
        <CardTitle title="Pack Lines" checkOn={false} disablePadding={true}>
            {
                item !== null &&
                item !== undefined &&
                item.packLines !== null &&
                item.packLines !== undefined ? (
                    renderTable(
                        item.packLines,
                        getContainers(
                            organization?.shipmentServiceSelected == 'NORMANHK'
                        ),
                        'container',
                        classes,
                        renderSpecialColumns,
                        {
                            maxHeight: '200px'
                        }
                    )
                ) : (
                    <Alert severity="info" className="w-100">
                        No records found.
                    </Alert>
                )}
        </CardTitle>
    )
}

