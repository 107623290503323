import React, { useEffect, useState } from 'react'
import CreateShipmentTable from './CreateShipmentTable'
import ShipmentCreationProgress from './ShipmentCreationProgress'
import {
    getShipmentKey,
    isNotFclOrFtl
} from '../../utils/shipment'
import { getCreatedShipment, getClientData } from '../../api/api'
import { makeStyles } from '@material-ui/core/styles'
import { getNextEnabledBookingStep, getPreviousEnabledBookingStep, hasAccessToStep } from './CreateBookingFunctions'

const columns = [
    { id: 'referenceNumber', label: 'Reference #', type: 'text' },
    { id: 'weight', label: 'Weight', type: 'number' },
    {
        id: 'weightUOM',
        label: 'Weight UOM',
        type: 'dropdown',
        dataKey: 'weightUnits'
    },
    { id: 'volume', label: 'Volume', type: 'number' },
    {
        id: 'volumeUOM',
        label: 'Volume UOM',
        type: 'dropdown',
        dataKey: 'volumeUnits'
    },
    { id: 'quantity', label: 'Pce Count', type: 'number' },
    {
        id: 'quantityUOM',
        label: 'Pce Count UOM',
        type: 'dropdown',
        dataKey: 'pieceUnits'
    },
    { id: 'dimensionLength', label: 'Length', type: 'number' },
    { id: 'dimensionWidth', label: 'Width', type: 'number' },
    { id: 'dimensionHeight', label: 'Height', type: 'number' },
    {
        id: 'dimensionUOM',
        label: 'Length UOM',
        type: 'dropdown',
        dataKey: 'lengthUnits'
    },
    {
        id: 'commodityCode',
        label: 'Commodity',
        type: 'dropdown',
        dataKey: 'commodities'
    },
    {
        id: 'goodsDescription',
        label: 'Goods Description',
        type: 'text'
    }
]

const classes = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2)
    },
    heading: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    }
}))

export default function PackLinesInfo() {
    const [submitText, setSubmitText] = useState('Submit')
    const [showSkip, setShowSkip] = useState(true)
    const [shipment, setShipment] = useState(null)
    const [client, setClient] = useState(null)
    const [nextBookingStep, setNextBookingStep] = useState(null)
    const [previousBookingStep, setPreviousBookingStep] = useState(null)

    useEffect(() => {
        const shipmentNumber = getShipmentKey()
        if (shipmentNumber !== null && shipmentNumber !== undefined) {
            getCreatedShipment(shipmentNumber, null, null, data => {
                setShipment(data)
                const { containerTypeCode } = data
                setSubmitText(
                    isNotFclOrFtl(containerTypeCode) ? 'Save/Review' : 'Save/Next'
                )
            })
        } else {
            window.location.replace('/shipments/create')
        }
    }, [])

    useEffect(() => {
        if (!shipment) return
        getClientData(
            shipment.clientId,
            data => {
                setClient(data)
                const nextStep = getNextEnabledBookingStep(2, data.bookingSteps, shipment.containerTypeCode)
                setNextBookingStep(nextStep)
                setPreviousBookingStep(getPreviousEnabledBookingStep(2, data.bookingSteps))
                if (!hasAccessToStep(2, data.bookingSteps)) {
                    window.location.replace(nextStep.href)
                }
            },
            () => {
                setClient({})
            }
        )
    }, [shipment])

    return (
        client && shipment && (
            <div>
                <div className="mb-4 w-100">
                    <ShipmentCreationProgress
                        client={client}
                        index={2}
                        hideContainers={isNotFclOrFtl(shipment.containerTypeCode)}
                    />
                </div>
                <CreateShipmentTable
                    title="Packing Line Info"
                    columns={columns}
                    shipmentKey="packLines"
                    backURL={previousBookingStep?.href}
                    redirectURL={nextBookingStep?.href}
                    showSkip={showSkip}
                    submitButtonText={submitText}
                    sourceForm="bookingPackLines"
                />                      
            </div>
        )
    )
}
