import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Alert from '@material-ui/lab/Alert'

export default class AlertMessage extends PureComponent {
    render() {
        if (!this.props.open) {
            return null
        }
        return (
            <Alert
                onClose={() => this.props.onClose(false)}
                severity={this.props.success ? 'success' : 'error'}>
                {this.props.message}
            </Alert>
        )
    }
}
AlertMessage.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired
}
