import React, { useState, useEffect } from 'react'
import DeliveryTable from './DeliveryTable'
import { canSearchShipment } from '../utils/permissions'
import { getAppInfo } from '../api/api'

export default function Delivery() {
    const [loading, setIsLoading] = useState(true)
    const [showSearchShipment, setShowSearchShipment] = useState(false)

    useEffect(() => {
        getAppInfo(
            null,
            async data => {
                setIsLoading(false)
                setShowSearchShipment(await canSearchShipment(data))
            },
            () => {
                setIsLoading(false)
            }
        )
    }, [])

    return (
        <div>
            {loading ? (
                'Loading...'
            ) : showSearchShipment ? (
                <DeliveryTable searchItem="Delivery" itemKey="shipment" />
            ) : (
                'Sorry, current access to this page is unavailable.'
            )}
        </div>
    )
}
