import React, { useEffect, useState } from 'react'
import CreateOrderTable from './CreateOrderTable'
import OrderCreationProgress from './OrderCreationProgress'
import { getOrderKey } from '../../utils/order'
import { getCreatedOrder } from '../../api/api'

const columns = [
    {
        id: 'noteTypeCode',
        label: 'Note Type',
        type: 'dropdown',
        dataKey: 'noteUnits'
    },
    { id: 'noteText', label: 'Text', type: 'text' }
]

export default function CreateOrderNotes() {
    const [order, setOrder] = useState({})

    useEffect(() => {
        const orderId = getOrderKey()
        if (orderId !== null && orderId !== undefined) {
            getCreatedOrder(orderId, data => {
                setOrder(data)
            })
        }
    }, [])

    return (
        <div>
            <div className="mb-4 w-100">
                <OrderCreationProgress
                    index={1}
                />
            </div>
            <CreateOrderTable
                title="Order Notes"
                columns={columns}
                orderKey="notes"
                backURL="/orders/create"
                redirectURL="/orders/create/lines"
                showSkip={true}
                sourceForm="orderNotes"
            />
        </div>
    )
}
