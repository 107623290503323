/**
 * Attempts to parse a string as an integer, returning the parsed value if the string represents a valid number,
 * or the defaultValue if the string is empty or not a valid number.
 * @param input The string to parse.
 * @param defaultValue The value to return if the string is empty or not a valid number.
 * @return A parsed integer, or the defaultValue if the string could not be parsed.
 */
export const tryParseInt = (input, defaultValue) => {

    var retValue = defaultValue;

    // If the input is a string, try to parse it as an integer.
    if (typeof input === 'string') {
        if (input.length > 0) {
            if (!isNaN(input)) {
                retValue = parseInt(input);
            }
        }
    }
    
    // If the input is a number, return it as an integer.
    else if (typeof input === 'number') {
        retValue = parseInt(input);
    }

    return retValue;
}

/**
 * Attempts to parse a string as a floating point number, returning the parsed value if the string represents a valid number,
 * or the defaultValue if the string is empty or not a valid number.
 * @param input The string to parse.
 * @param defaultValue The value to return if the string is empty or not a valid number.
 * @return A parsed floating point number, or the defaultValue if the string could not be parsed.
 */
export const tryParseFloat = (input, defaultValue) => {

    var retValue = defaultValue;

    // If the input is a string, try to parse it as a floating point number.
    if (typeof input === 'string') {
        if (input.length > 0) {
            if (!isNaN(input)) {
                retValue = parseFloat(input);
            }
        }
    }

    // If the input is a number, return it as a floating point number.
    else if (typeof input === 'number') {
        retValue = parseFloat(input);
    }

    return retValue;
}


