import React from 'react'
import { Route } from 'react-router-dom'
import { Layout } from './components/Layout'
import Dashboard from './components/Dashboard'
import Shipments from './components/Shipments'
import Accounting from './components/Accounting'
import Booking from './components/Booking'
import BookingDetails from './components/BookingDetails'
import ShipmentDetails from './components/ShipmentDetails'
import Customs from './components/Customs'
import CustomDetails from './components/CustomDetails'
import CreateShipment from './components/CreateShipment'
import PackLinesInfo from './components/CreateShipment/PackLinesInfo'
import Containers from './components/CreateShipment/Containers'
import Notes from './components/CreateShipment/Notes'
import Review from './components/CreateShipment/Review'
import { PackageLineInfo } from './components/PackageLineInfo'
import CreateOrder from './components/CreateOrder'
import CreateOrderLines from './components/CreateOrder/CreateOrderLines'
import CreateOrderNotes from './components/CreateOrder/CreateOrderNotes'
import CreateOrderReview from './components/CreateOrder/CreateOrderReview'
import AccountDetails from './components/AccountDetails'
import Password from './components/Password'
import Clients from './components/Clients'
import UserClients from './components/UserClients'
import ClientDetails from './components/ClientDetails'
import ClientUsers from './components/ClientUsers'
import OrgUsers from './components/OrgUsers'
import OrganizationDetails from './components/OrganizationDetails'
import ClientUserDetails from './components/ClientUserDetails'
import AdminUsers from './components/AdminUsers'
import DataMappingIndex from './components/DataMapping/DataMappingIndex'
import DataMappingEdit from './components/DataMapping/DataMappingEdit'
import AdminUserDetails from './components/AdminUserDetails'
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute'
import AdminAuthorizeRoute from './components/api-authorization/AdminAuthorizeRoute'
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes'
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants'
import Orders from './components/Orders'
import OrderDetails from './components/OrderDetails'
import OrderLines from './components/OrderLines'
import Delivery from './components/Delivery'
import Pickup from './components/Pickup'
import Report from './components/Reports'
import ReportDetails from './components/ReportDetails'
import './custom.css'
import DashboardsMore from './components/DashboardsMore'
import DashboardDetails from './components/DashboardDetails'
import ContainerManagement from './components/ContainerManagement'
import ReactGA from "react-ga4";

// need to check what the current domain is and set the trackingId accordingly
if (window.location.hostname === "havi.connectcast.io") {
    ReactGA.initialize('G-EX0YRM3CEX');
}

const App = () => {

    return (
        <Layout>
            <AuthorizeRoute exact path="/" component={Dashboard} />
            <AuthorizeRoute
                exact
                path="/DashboardsMore"
                component={DashboardsMore}
            />
            <AuthorizeRoute
                exact
                path="/Dashboard/:dashboardId/:groupId"
                component={DashboardDetails}
            />
            <AuthorizeRoute exact path="/shipments" component={Shipments} />
            <AuthorizeRoute
                exact
                path="/powerbireports"
                component={Report}
            />
            <AuthorizeRoute
                exact
                path="/Report/:reportId/:groupId"
                component={ReportDetails}
            />
            <AuthorizeRoute
                exact
                path="/accounting"
                component={Accounting}
            />
            <AuthorizeRoute exact path="/bookings" component={Booking} />
            <AuthorizeRoute exact path="/customs" component={Customs} />
            <AuthorizeRoute exact path="/orders" component={Orders} />
            <AuthorizeRoute
                exact
                path="/orderlines"
                component={OrderLines}
            />
            <AuthorizeRoute
                exact
                path="/shipments/:clientId/:shipmentId/:containerId"
                component={ShipmentDetails}
            />
            <AuthorizeRoute
                exact
                path="/shipments/:clientId/:shipmentId"
                component={ShipmentDetails}
            />
            <AuthorizeRoute
                exact
                path="/customs/:clientId/:shipmentId"
                component={CustomDetails}
            />
            <AuthorizeRoute
                exact
                path="/bookings/:clientId/:shipmentId"
                component={BookingDetails}
            />
            <AuthorizeRoute
                exact
                path="/orders/:clientId/:orderNumber/:orderNumberSplit"
                component={OrderDetails}
            />
            <AuthorizeRoute
                exact
                path="/shipments/packlines"
                component={PackLinesInfo}
            />
            <AuthorizeRoute
                exact
                path="/shipments/containers"
                component={Containers}
            />
            <AuthorizeRoute
                exact
                path="/shipments/notes"
                component={Notes}
            />
            <AuthorizeRoute
                exact
                path="/shipments/review"
                component={Review}
            />
            <AuthorizeRoute
                exact
                path="/shipments/create"
                component={CreateShipment}
            />
            <AuthorizeRoute
                exact
                path="/shipments/create/package"
                component={PackageLineInfo}
            />
            <AuthorizeRoute
                exact
                path="/orders/create"
                component={CreateOrder}
            />
            <AuthorizeRoute
                exact
                path="/orders/create/lines"
                component={CreateOrderLines}
            />
            <AuthorizeRoute
                exact
                path="/orders/create/notes"
                component={CreateOrderNotes}
            />
            <AuthorizeRoute
                exact
                path="/orders/create/review"
                component={CreateOrderReview}
            />
            <AuthorizeRoute path="/account" component={AccountDetails} />
            <AuthorizeRoute path="/password" component={Password} />
            <AdminAuthorizeRoute
                exact
                path="/clients"
                component={Clients}
            />
            <AdminAuthorizeRoute
                exact
                path="/clients/:id"
                component={ClientDetails}
            />
            <AdminAuthorizeRoute
                exact
                path="/userclients/:userId"
                component={UserClients}
            />
            <AuthorizeRoute
                exact
                path="/users/:clientId"
                component={ClientUsers}
            />
            <AdminAuthorizeRoute
                path="/clientusers"
                component={OrgUsers}
            />
            <AuthorizeRoute
                exact
                path="/users/:clientId/:userId"
                component={ClientUserDetails}
            />
            <AdminAuthorizeRoute
                path="/organizations"
                component={OrganizationDetails}
            />
            <AdminAuthorizeRoute
                exact
                path="/admin/data_mapping/"
                component={DataMappingIndex}
            />
            <AdminAuthorizeRoute
                exact
                path="/admin/data_mapping/new"
                component={DataMappingEdit}
            />
            <AdminAuthorizeRoute
                exact
                path="/admin/data_mapping/edit/:propertyType/:propertyKey"
                component={DataMappingEdit}
            />
            <AdminAuthorizeRoute
                exact
                path="/admin/users/"
                component={AdminUsers}
            />
            <AdminAuthorizeRoute
                exact
                path="/admin/users/:userId"
                component={AdminUserDetails}
            />
            <Route
                path={ApplicationPaths.ApiAuthorizationPrefix}
                component={ApiAuthorizationRoutes}
            />
            
            <AuthorizeRoute
                exact
                path="/containers/delivery"
                component={Delivery}
            />
            <AuthorizeRoute
                exact
                path="/containers/pickup"
                component={Pickup}
            />
            <AuthorizeRoute
                exact
                path="/containers"
                component={ContainerManagement}
            />
        </Layout>
    )
}

export default App;
