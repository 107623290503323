import React from 'react'
import PropTypes from 'prop-types'
import ListSubheader from '@material-ui/core/ListSubheader'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import Icon from '@material-ui/core/Icon'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import NavLinkMui from './Navigation/NavLinkMui'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        background: theme.palette.primary.main
    },
    nested: {
        paddingLeft: theme.spacing(4)
    }
})

class NestedList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            items: null
        }
    }

    state = {}

    handleClick = e => {
        this.setState({ [e]: !this.state[e] })
    }

    getSelected = (index, subindex = null) => {
        const path = window.location.pathname

        if (subindex !== null) {
            if (
                path.indexOf('/shipments/create') === 0 &&
                index === 1 &&
                subindex === 0
            ) {
                return true
            } else if (
                path.indexOf('/organizations') === 0 &&
                index === 20 &&
                subindex === 0
            ) {
                return true
            } else if (
                path.indexOf('/clients') === 0 &&
                index === 20 &&
                subindex === 1
            ) {
                return true
            } else if (
                path.indexOf('/admin/data_mapping') === 0 &&
                index === 20 &&
                subindex === 2
            ) {
                return true
            } else if (
                path.indexOf('/admin/users') === 0 &&
                index === 20 &&
                subindex === 3
            ) {
                return true
            } /*else if (path.length === 1 && index === 1 && subindex === 0) {
                return true
            }*/
        } else if (subindex === null) {
            if (path === '/account' && index === 5) {
                return true
            } else if (path === '/accounting' && index === 3) {
                return true
            } else if (path === '/booking' && index === 4) {
                return true
            } else if (
                path.indexOf('/shipments') === 0 &&
                path.indexOf('/shipments/create') === -1 &&
                index === 1
            ) {
                return true
            } else if (path.indexOf('/customs') === 0 && index === 2) {
                return true
            } else if (path === '/' && index === 0) {
                return true
            }
        }

        return false
    }

    render() {
        const { classes, items, textColor } = this.props
        return (
            <div>
                {items.list.map((list, index) => {
                    return (
                        <List
                            component="nav"
                            className={classes.root}
                            key={list.id}
                            subheader={
                                <ListSubheader>{list.title}</ListSubheader>
                            }>
                            {list.items.map(item => {
                                const selected = this.getSelected(index)
                                const isExternal =
                                    item.external !== null &&
                                    item.external !== undefined &&
                                    item.external
                                const targetProps = isExternal
                                    ? { target: '_blank' }
                                    : {}
                                return (
                                    <div key={item.id}>
                                        {item.subitems != null ? (
                                            <div key={item.id}>
                                                <ListItem
                                                    button
                                                    hidden={
                                                        item.hidden !== null &&
                                                        item.hidden !==
                                                            undefined
                                                            ? item.hidden
                                                            : false
                                                    }
                                                    key={item.id}
                                                    selected={selected}
                                                    style={{ color: textColor }}
                                                    onClick={this.handleClick.bind(
                                                        this,
                                                        item.name
                                                    )}>
                                                    <ListItemIcon>
                                                        <Icon
                                                            style={{
                                                                color: textColor
                                                            }}>
                                                            {item.icon}
                                                        </Icon>
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={item.name}
                                                    />
                                                    {this.state[item.name] ? (
                                                        <ExpandLess />
                                                    ) : (
                                                        <ExpandMore />
                                                    )}
                                                </ListItem>
                                                <Collapse
                                                    key={list.items.id}
                                                    component="li"
                                                    in={this.state[item.name]}
                                                    timeout="auto"
                                                    unmountOnExit>
                                                    <List disablePadding>
                                                        {item.subitems.map(
                                                            (
                                                                sitem,
                                                                subindex
                                                            ) => {
                                                                const selected = this.getSelected(
                                                                    index,
                                                                    subindex
                                                                )
                                                                return (
                                                                    <ListItem
                                                                        hidden={
                                                                            sitem.hidden !==
                                                                                null &&
                                                                            sitem.hidden !==
                                                                                undefined
                                                                                ? sitem.hidden
                                                                                : false
                                                                        }
                                                                        button
                                                                        selected={
                                                                            selected
                                                                        }
                                                                        style={{
                                                                            color: textColor
                                                                        }}
                                                                        key={
                                                                            sitem.id
                                                                        }
                                                                        component={
                                                                            isExternal
                                                                                ? 'a'
                                                                                : NavLinkMui
                                                                        }
                                                                        {...targetProps}
                                                                        href={
                                                                            sitem
                                                                                .link
                                                                                .pathname
                                                                        }
                                                                        to={
                                                                            sitem.link
                                                                        }>
                                                                        <ListItemIcon>
                                                                            <Icon
                                                                                style={{
                                                                                    color: textColor
                                                                                }}>
                                                                                {
                                                                                    sitem.icon
                                                                                }
                                                                            </Icon>
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            key={
                                                                                sitem.id
                                                                            }
                                                                            primary={
                                                                                sitem.name
                                                                            }
                                                                        />
                                                                    </ListItem>
                                                                )
                                                            }
                                                        )}
                                                    </List>
                                                </Collapse>{' '}
                                            </div>
                                        ) : (
                                            <ListItem
                                                button
                                                hidden={
                                                    item.hidden !== null &&
                                                    item.hidden !== undefined
                                                        ? item.hidden
                                                        : false
                                                }
                                                onClick={this.handleClick.bind(
                                                    this,
                                                    item.name
                                                )}
                                                selected={selected}
                                                style={{ color: textColor }}
                                                key={item.id}
                                                component={
                                                    isExternal
                                                        ? 'a'
                                                        : NavLinkMui
                                                }
                                                {...targetProps}
                                                href={item.link.pathname}
                                                to={item.link}>
                                                <ListItemIcon>
                                                    <Icon
                                                        style={{
                                                            color: textColor
                                                        }}>
                                                        {item.icon}
                                                    </Icon>
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={item.name}
                                                />
                                            </ListItem>
                                        )}
                                    </div>
                                )
                            })}
                        </List>
                    )
                })}
            </div>
        )
    }
}
NestedList.propTypes = {
    classes: PropTypes.object.isRequired
}
export default withStyles(styles)(NestedList)
