import React, { useEffect, useState } from 'react'
import CreateOrderTable from './CreateOrderTable'
import OrderCreationProgress from './OrderCreationProgress'
import {
    redirectUrlForPacklines,
    getOrderKey
} from '../../utils/order'
import { getCreatedOrder } from '../../api/api'

const columns = [
    { id: 'partNumber', label: 'Part #', type: 'text' },
    { id: 'partDescription', label: 'Part Description', type: 'text' },
    { id: 'quantity', label: 'Quantity', type: 'number' },
    {
        id: 'quantityUOM',
        label: 'Quantity UOM',
        type: 'dropdown',
        dataKey: 'pieceUnits'
    },
    { id: 'price', label: 'Item Price', type: 'number' },
    { id: 'containerNumber', label: 'Container #', type: 'text' }
]

export default function CreateOrderLines() {
    const [redirectURL, setRedirectURL] = useState()
    const [submitText, setSubmitText] = useState('Submit')
    const [showSkip, setShowSkip] = useState(false)
    const [order, setOrder] = useState({})

    useEffect(() => {
        const orderId = getOrderKey()
        if (orderId !== null && orderId !== undefined) {
            getCreatedOrder(orderId, data => {
                setOrder(data)
                setRedirectURL(redirectUrlForPacklines())
                setSubmitText('Save/Review')
                setShowSkip(false)
            })
        }
    }, [])

    return (
        <div>
            <div className="mb-4 w-100">
                <OrderCreationProgress
                    index={2}
                />
            </div>
            <CreateOrderTable
                title="Order Lines"
                columns={columns}
                orderKey="lines"
                backURL="/orders/create/notes"
                redirectURL={redirectURL}
                showSkip={showSkip}
                submitButtonText={submitText}
                sourceForm="orderLines"
            />
        </div>
    )
}
