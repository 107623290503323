import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'

export default function OrderSupplierInfoCard({ item }) {
    const theme = useTheme()

    useEffect(() => {}, [])

    return (
        <div className="p-0 pl-3 data-data-info w-100">
            <h5 class="text-Curious-blue my-2 font-14" checkOn={false}>
                Supplier Info
            </h5>

            <Grid
                container
                direction="row"
                spacing={0}
                className={theme.bgColorSection}>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={1}>
                        <Grid item xs={12} lg={12}>
                            <div>
                                <span className="font-12 font-raleway-medium">
                                    Address
                                </span>
                                <br />
                                <span class="mb-0 font-14  font-nutosans-bold text-black">
                                    {(item !== null && item.supplierFullName) ||
                                        ''}
                                </span>
                                <br />
                                <span class="mb-0 font-14  font-nutosans-bold text-black">
                                    {(item !== null && item.supplierAddress1) ||
                                        ''}
                                </span>
                                <br />
                                <span class="mb-0 font-14  font-nutosans-bold text-black">
                                    {(item !== null && item.supplierAddress2) ||
                                        ''}
                                </span>
                                <br />
                                <span class="mb-0 font-14  font-nutosans-bold text-black">
                                    {(item !== null && item.supplierCity) || ''}
                                    ,{' '}
                                    {(item !== null && item.supplierState) ||
                                        ''}
                                    ,{' '}
                                    {(item !== null && item.supplierPostCode) ||
                                        ''}
                                </span>
                                <br />
                                <span class="mb-0 font-14  font-nutosans-bold text-black">
                                    {(item !== null &&
                                        item.supplierCountryCode) ||
                                        ''}
                                </span>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
