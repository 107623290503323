import React, { PureComponent } from 'react'
import ShipmentDetailPage from './ShipmentDetailPage'

class BookingDetails extends PureComponent {
    render() {
        let contents = <ShipmentDetailPage uri="GetBooking" {...this.props} />

        return <div>{contents}</div>
    }
}
export default BookingDetails
