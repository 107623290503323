import React, { PureComponent } from 'react'
import ShipmentDetailPage from './ShipmentDetailPage'

class ShipmentDetails extends PureComponent {
    render() {
        let contents = <ShipmentDetailPage uri="GetShipment" {...this.props} />

        return <div>{contents}</div>
    }
}
export default ShipmentDetails
