export const sortData = (column, a, b) => {
    const sortField = column.sortField ? column.sortField : column.field

    if (column.type === 'Date') {
        return new Date(a[sortField]) - new Date(b[sortField])
    } else if (column.type === 'Float') {
        return Number.parseFloat(a[sortField]) - Number.parseFloat(b[sortField])
    } else if (column.type === 'Integer') {
        return (
            Number.parseInt(a[column.field]) - Number.parseInt(b[column.field])
        )
    } else {
        // assume string
        return ('' + a[sortField]).localeCompare(b[sortField])
    }
}
