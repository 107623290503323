import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import authService from './api-authorization/AuthorizeService'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { useHistory } from 'react-router-dom'
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants'
import {
    canSeeCustoms,
    canSeeAccounting,
    canSeeBooking,
    canSeeOrders,
    canSeeContainers,
    canCreateOrder,
    canSearchShipment
} from '../utils/permissions'
import '../custom.css'
import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'
import *  as pbi from 'powerbi-client';
import { MenuItem, Select, Input, InputLabel, FormControl } from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
const useStyles = makeStyles({
    root: {
        minWidth: 275
    },
    dashboardTile: {
        minWidth: 275,
        cursor: 'pointer'
    }
})

export default function Dashboard() {
    //static displayName = Dashboard.name
    const classes = useStyles()
    const [dashboardsList, setDashboardlist] = useState([])
    const [selectedIndex, setIndexValue] = useState(0)
    const [groupId, setGroupId] = useState('')
    const [dashboardId, setDashboardId] = useState('')
    const [dashboardName, setDashboardName] = useState('')
    const [embedUrl, setEmbedUrl] = useState('')
    const [accessToken, setAccessToken] = useState('')
    const [displayDashboards, setDisplayDashboards] = useState(false)

    const [loading, setIsLoading] = useState(true)
    const [showCustoms, setShowCustoms] = useState(false)
    const [showAccounting, setShowAccounting] = useState(false)
    const [showBooking, setShowBooking] = useState(false)
    const [showShipments, setShowShipments] = useState(false)
    const [showOrders, setShowOrders] = useState(false)
    const [showCreateOrder, setShowCreateOrder] = useState(false)
    const [showContainers, setShowContainers] = useState(false)
    const [colorThemePirmaryRGB, setcolorThemePirmaryRGB] = useState('')
    const [colorThemeSecondaryRGB, setcolorThemeSecondaryRGB] = useState('')
    const [colorThemeTertiaryRGB, setcolorThemeTertiaryRGB] = useState('')
    const [colorThemeRGB, setcolorThemeRGB] = useState('')
    const [colorThemeTextRGB, setcolorThemeTextRGB] = useState('')

    const history = useHistory();

    useEffect(() => {
        getAppInfo()
    }, [])
    useEffect(() => {
        populateDashboardsData()
    }, [])

    const getAppInfo = async () => {
        const token = await authService.getAccessToken()
        const response = await fetch('api/app/info', {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        })

        setIsLoading(false)

        if (response.ok) {
            const data = await response.json()
            setShowCustoms(await canSeeCustoms(data))
            setShowAccounting(await canSeeAccounting(data))
            setShowBooking(await canSeeBooking(data))
            setShowShipments(await canSearchShipment(data))
            setShowOrders(await canSeeOrders(data))
            setShowCreateOrder(await canCreateOrder(data))
            setShowContainers(await canSeeContainers(data))
            setcolorThemePirmaryRGB(data.colorThemePirmaryRGB)
            setcolorThemeSecondaryRGB(data.colorThemeSecondaryRGB)
            setcolorThemeTertiaryRGB(data.colorThemeTertiaryRGB)
            setcolorThemeRGB(data.colorThemeRGB)
            setcolorThemeTextRGB(data.colorThemeTextRGB)
        }
    }

    //to get the dashboards list based on the login userid
    const populateDashboardsData = async () => {
        const user = await authService.getUser()
        let userId = ''
        if (user) {
            userId = user.sub
        } else {
            userId = null
        }
        const token = await authService.getAccessToken()
        const response = await fetch(
            'api/users/GetUserBIDashboards/' + userId,
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }
        )

        if (response.ok) {
            const data = await response.json()
            if (data.length > 0) {
                setDisplayDashboards(true)
            }
            setDashboardlist(data)
            if (data.length > 0) {
                await populateDashboardData(
                    data[selectedIndex].groupId,
                    data[selectedIndex].dashboardId
                )
            }
        }
    }
    const handleDashboardChange = async event => {
        const index = event.target.value
        const dashboard = dashboardsList.find(dashboard => dashboard.dashboardId === index)
        setIndexValue(index)

        await populateDashboardData(dashboard.groupId, dashboard.dashboardId)
    }

    const goToSection = (path) => {
        history.push(path)
    }

    //to get the dashboard(access_token, embed_url) fotr display the dashboard
    const populateDashboardData = async (groupId, dashboardId) => {
        setIsLoading(true)
        const token = await authService.getAccessToken()
        const user = await authService.getUser()
        let userId = ''
        if (user) {
            userId = user.sub
        } else {
            userId = null
        }

        let data = {
            Id: dashboardId,
            GroupId: groupId,
            UserId: userId
        }
        await fetch('api/bidashboard/GetDashboardDetails', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    setIsLoading(true)
                    return response.json()
                }
            })

            .then(d => {
                //const data = d.json()
                setDashboardId(d.dashboardId)
                setDashboardName(d.dashboardName)
                setEmbedUrl(d.embedUrl)
                setAccessToken(d.token)
                
                let dashboardContainer = document.getElementById("dashboardEmbedded");
                let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory,pbi.factories.routerFactory);
                powerbi.reset(dashboardContainer);

                let embedConfig={
                    type: 'dashboard',
                    id: d.dashboardId,
                    embedUrl: d.embedUrl,
                    accessToken: d.token,
                    tokenType: models.TokenType.Embed,
                    pageView: 'fitToWidth',
                    settings: {
                        panes: {
                            filters: {
                                expanded: false,
                                visible: false
                            }
                        },
                        background: models.BackgroundType.Transparent
                    }

                }
                powerbi.embed(dashboardContainer, embedConfig)
            })
            .catch(error => {})
    }

    return (
        <div class="dashboard-inner">
            <Grid
                container
                className={classes.root}
                spacing={3}
                direction="row">
                {showOrders && (
                    <Grid
                        item
                        xxl={2}
                        xl={3}
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                        className="xxxl-2">
                        <div className="dashboard-card">
                            <Card 
                                className={classes.dashboardTile}
                                onClick={() => goToSection(ApplicationPaths.Orders)}>
                                <CardContent>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div>
                                            <Typography
                                                variant="h5"
                                                component="h2"
                                                style={{
                                                    color: colorThemeRGB
                                                }}>
                                                Orders
                                            </Typography>

                                            <Typography
                                                variant="body2"
                                                component="p">
                                                Purchase Order Search
                                            </Typography>
                                        </div>
                                        <div>
                                            <Icon
                                                className="mr-4 font-40 opacity-40"
                                                style={{
                                                    color: colorThemeRGB
                                                }}>
                                                assignment
                                            </Icon>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </Grid>
                )}
                {showOrders && (
                    <Grid
                        item
                        xxl={2}
                        xl={3}
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                        className="xxxl-2">
                        <div className="dashboard-card">
                            <Card 
                                className={classes.dashboardTile}
                                onClick={() => goToSection(ApplicationPaths.OrderLines)}>
                                <CardContent>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div>
                                            <Typography
                                                variant="h5"
                                                component="h2"
                                                style={{
                                                    color: colorThemeRGB
                                                }}>
                                                Order Lines
                                            </Typography>

                                            <Typography
                                                variant="body2"
                                                component="p">
                                                Purchase Order Line Search
                                            </Typography>
                                        </div>
                                        <div>
                                            <Icon
                                                className="mr-4 font-40 opacity-40"
                                                style={{
                                                    color: colorThemeRGB
                                                }}>
                                                assignment
                                            </Icon>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </Grid>
                )}
                {showCreateOrder && (
                    <Grid
                        item
                        xxl={2}
                        xl={3}
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                        className="xxxl-2">
                        <div className="dashboard-card">
                            <Card 
                                className={classes.dashboardTile}
                                onClick={() => goToSection(ApplicationPaths.CreateOrder)}>
                                <CardContent>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div>
                                            <Typography
                                                variant="h5"
                                                component="h2"
                                                style={{
                                                    color: colorThemeRGB
                                                }}>
                                                Create Order
                                            </Typography>

                                            <Typography
                                                variant="body2"
                                                component="p">
                                                Create New Purchase Order
                                            </Typography>
                                        </div>
                                        <div>
                                            <Icon
                                                className="mr-4 font-40 opacity-40"
                                                style={{
                                                    color: colorThemeRGB
                                                }}>
                                                assignment
                                            </Icon>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </Grid>
                )}
                {showBooking && (
                    <Grid
                        item
                        xxl={2}
                        xl={3}
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                        className="xxxl-2">
                        <div className="dashboard-card">
                            <Card 
                                className={classes.dashboardTile}
                                onClick={() => goToSection(ApplicationPaths.Bookings)}>
                                <CardContent>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div>
                                            <Typography
                                                variant="h5"
                                                component="h2"
                                                style={{
                                                    color: colorThemeRGB
                                                }}>
                                                Bookings
                                            </Typography>

                                            <Typography
                                                variant="body2"
                                                component="p">
                                                Booking Search
                                            </Typography>
                                        </div>
                                        <div>
                                            <Icon
                                                className="mr-4 font-40 opacity-40"
                                                style={{
                                                    color: colorThemeRGB
                                                }}>
                                                assignment
                                            </Icon>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </Grid>
                )}
                {showShipments && (
                    <Grid
                        item
                        xxl={2}
                        xl={3}
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                        className="xxxl-2">
                        <div className="dashboard-card">
                            <Card 
                                className={classes.dashboardTile}
                                onClick={() => goToSection(ApplicationPaths.Shipments)}>
                                <CardContent>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div>
                                            <Typography
                                                variant="h5"
                                                component="h2"
                                                style={{
                                                    color: colorThemeRGB
                                                }}>
                                                Shipments
                                            </Typography>

                                            <Typography
                                                variant="body2"
                                                component="p">
                                                Shipment Search
                                            </Typography>
                                        </div>
                                        <div>
                                            <Icon
                                                className="mr-4 font-40 opacity-40"
                                                style={{
                                                    color: colorThemeRGB
                                                }}>
                                                assignment_turned_in
                                            </Icon>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </Grid>
                )}
                {showContainers && (
                    <Grid
                        item
                        xxl={2}
                        xl={3}
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                        className="xxxl-2">
                        <div className="dashboard-card">
                            <Card 
                                className={classes.dashboardTile}
                                onClick={() => goToSection(ApplicationPaths.Container)}>
                                <CardContent>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div>
                                            <Typography
                                                variant="h5"
                                                component="h2"
                                                style={{
                                                    color: colorThemeRGB
                                                }}>
                                                Containers
                                            </Typography>

                                            <Typography
                                                variant="body2"
                                                component="p">
                                                Container Search
                                            </Typography>
                                        </div>
                                        <div>
                                            <Icon
                                                className="mr-4 font-40 opacity-40"
                                                style={{
                                                    color: colorThemeRGB
                                                }}>
                                                border_all
                                            </Icon>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </Grid>
                )}
                {showCustoms && (
                    <Grid
                        item
                        xxl={2}
                        xl={3}
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                        className="xxxl-2">
                        <div className="dashboard-card">
                            <Card 
                                className={classes.dashboardTile}
                                onClick={() => goToSection(ApplicationPaths.Customs)}>
                                <CardContent>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div>
                                            <Typography
                                                variant="h5"
                                                component="h2"
                                                style={{
                                                    color: colorThemeRGB
                                                }}>
                                                Customs
                                            </Typography>

                                            <Typography
                                                variant="body2"
                                                component="p">
                                                Customs Search
                                            </Typography>
                                        </div>
                                        <div>
                                            <Icon
                                                className="mr-4 font-40 opacity-40"
                                                style={{
                                                    color: colorThemeRGB
                                                }}>
                                                policy
                                            </Icon>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </Grid>
                )}
                {showAccounting && (
                    <Grid
                        item
                        xxl={2}
                        xl={3}
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                        className="xxxl-2">
                        <div className="dashboard-card">
                            <Card 
                                className={classes.dashboardTile}
                                onClick={() => goToSection(ApplicationPaths.Accounting)}>
                                <CardContent>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div>
                                            <Typography
                                                variant="h5"
                                                component="h2"
                                                style={{
                                                    color: colorThemeRGB
                                                }}>
                                                Accounting
                                            </Typography>

                                            <Typography
                                                variant="body2"
                                                component="p">
                                                Accounting Search
                                            </Typography>
                                        </div>
                                        <div>
                                            <Icon
                                                className="mr-4 font-40 opacity-40"
                                                style={{
                                                    color: colorThemeRGB
                                                }}>
                                                description
                                            </Icon>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </Grid>
                )}
                {displayDashboards && (
                    <Grid
                        item
                        xs={12}>
                        <div className="dashboard-card" style={{marginRight: '25px'}}>
                            <Card>
                                <CardContent>
                                    <Grid 
                                        container>
                                        <Grid item xs={3}>
                                            <FormControl>
                                                <Typography
                                                    variant="h6"
                                                    component="h6"
                                                    style={{
                                                        color: colorThemeRGB
                                                    }}>
                                                    Dashboard Views
                                                </Typography>
                                                <Select
                                                    label="Available Dashboards"
                                                    name="dashboards"
                                                    value={dashboardId}
                                                    onChange={e =>
                                                        handleDashboardChange(e)
                                                    }>
                                                    {dashboardsList ? (dashboardsList.map(
                                                                    dashboard => (
                                                                        <MenuItem
                                                                            key={
                                                                                dashboard.dashboardId
                                                                            }
                                                                            value={
                                                                                dashboard.dashboardId
                                                                            }>
                                                                            {
                                                                                dashboard.dashboardName
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                )) : (null)
                                                    }
                                                </Select>
                                            </FormControl>
                                            <br/>
                                        </Grid>
                                    </Grid>
                                </CardContent>  
                                <CardContent style={{marginTop: '10px'}}>
                                    <div>
                                        <div id="dashboardEmbedded" style={{height: '80vh'}}>
                                            <PowerBIEmbed
                                                embedConfig={{
                                                    type: 'dashboard',
                                                    id: dashboardId,
                                                    embedUrl: embedUrl,
                                                    accessToken: accessToken,
                                                    tokenType: models.TokenType.Embed,
                                                    pageView: 'fitToWidth',
                                                    settings: {
                                                        panes: {
                                                            filters: {
                                                                expanded: false,
                                                                visible: false
                                                            }
                                                        },
                                                        background: models.BackgroundType.Transparent
                                                    }

                                                }}
                                                eventHandlers={
                                                    new Map([
                                                        [
                                                            'loaded',
                                                            function() {
                                                                console.log('Dashboard loaded')
                                                            }
                                                        ],
                                                        [
                                                            'rendered',
                                                            function() {
                                                                console.log('Dashboard rendered')
                                                            }
                                                        ],
                                                        [
                                                            'error',
                                                            function(event) {
                                                                console.log(event.detail)
                                                            }
                                                        ]
                                                    ])
                                                }
                                                cssClassName={'report-style-class'}
                                                getEmbeddedComponent={embeddedDash => {
                                                    window.dashboard = embeddedDash
                                                }}
                                            />
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </Grid>
                )}
            </Grid>
        </div>
    )
}

