import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CardTitle from './CardTitle'
import { useTheme } from '@material-ui/core/styles'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

export default function ShipmentContactCard({ item, title }) {
    const theme = useTheme()

    return (
        <CardTitle title={title} checkOn={false}>
            <Grid container direction="row">
                <Grid item xs={12}>
                    <div className="column">
                        <span className="font-weight-bold">Address</span>
                        <br />
                        <span>{(item !== null && item.name) || ''}</span>
                        <br />
                        <span>
                            {(item !== null && item.addressLine1) || ''}
                        </span>
                        <br />
                        <span>
                            {(item !== null && item.city) || ''},{' '}
                            {(item !== null && item.state) || ''},{' '}
                            {(item !== null && item.zipCode) || ''}
                        </span>
                        <br />
                        <span>{(item !== null && item.country) || ''}</span>
                    </div>
                </Grid>
            </Grid>
        </CardTitle>
    )
}
