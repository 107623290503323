import 'bootstrap/dist/css/bootstrap.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
//import registerServiceWorker from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')
const root = createRoot(document.getElementById('root'))

// need to get zoom level from local storage
// if it exists, set it
// if it doesn't, set it to 100
let zoomLevel = localStorage.getItem('zoomLevel')
if (zoomLevel === null) {
    zoomLevel = 100
    localStorage.setItem('zoomLevel', zoomLevel)
}

document.body.style.zoom = `${zoomLevel}%`

root.render(
    <BrowserRouter basename={baseUrl}>
        <App />
    </BrowserRouter>
)

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();
