import React, { useState, useEffect } from 'react'
import authService from './api-authorization/AuthorizeService'
import { makeStyles } from '@material-ui/core/styles'
import { CardContent, Grid } from '@material-ui/core'
import {
    FormControl,
    TextField,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Input, InputLabel } from '@material-ui/core'
import { MenuItem, Select, Typography } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import AlertMessage from './shared/Alert'
import voca from 'voca'
import { useForm, Controller } from 'react-hook-form'
import _ from 'lodash'
import { CustomDialog } from './shared/CustomDialog'
import IconButton from '@material-ui/core/IconButton'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Paper from '@material-ui/core/Paper'
import OrderCreationProgress from './CreateOrder/OrderCreationProgress'
import { canCreateOrder} from '../utils/permissions'
import DateFnsUtils from '@date-io/moment'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers'
import { getCreatedOrder, getCreateOrderTemplate, getUNLOCO } from '../api/api'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { getOrgNameFromOrganization } from '../utils/organizations'
import { getOrderKey } from '../utils/order'
import DeleteIcon from '@material-ui/icons/Delete'

const classes = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2)
    },
    heading: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    }
}))

export default function CreateOrderForm(props) {
    const {
        register,
        handleSubmit,
        errors,
        formState,
        setValue,
        getValues,
        control,
        watch
    } = useForm()
    const { isDirty } = formState

    const [hasAccess, setHasAccess] = useState(false)
    const [templateLoading, setTemplateLoading] = useState(false)
    const watchTransportMode = watch('transportModeCode')
    const [organization, setOrganization] = useState([])
    const [clients, setClients] = useState([])
    const [transportModes, setTransportModes] = useState([])
    const [incoTerms, setIncoTerms] = useState([])
    const [containerModes, setContainerModes] = useState([])
    const [serviceLevels, setServiceLevels] = useState([])
    const [currencyCodes, setCurrencyCodes] = useState([])
    const [addresses, setAddresses] = useState([])
    const [filteredAddresses, setFilteredAddresses] = useState([])
    const [orgConsigneeAddresses, setOrgConsigneeAddresses] = useState([])
    const [filteredOrgConsigneeAddresses, setFilteredOrgConsigneeAddresses] = useState([])
    const [orgConsignorAddresses, setOrgConsignorAddresses] = useState([])
    const [filteredOrgConsignorAddresses, setFilteredOrgConsignorAddresses] = useState([])
    const [isConsignor, setIsConsignor] = useState(false)
    const [originUnlocoCodes, setOriginUnlocoCodes] = useState([])
    const [destinationUnlocoCodes, setDestinationUnlocoCodes] = useState([])
    const [loadPorts, setLoadUnlocoCodes] = useState([])
    const [dischargePorts, setDischargeUnlocoCodes] = useState([])
    const [filteredContainerModes, setFilteredContainerModes] = useState([])
    const [didSetOriginUnlocoCode, setDidSetOriginUnlocoCode] = useState(false)
    const [didSetDestinationUnlocoCode, setDidSetDestinationUnlocoCode] = useState(false)
    const [didSetLoadPort, setDidSetLoadPortUnlocoCode] = useState(false)
    const [didSetDischargePort, setDidSetDischargePortUnlocoCode] = useState(false)
    const [originUNLOCOCodeObject, setOriginUNLOCOCodeObject] = useState(null)
    const [destinationUNLOCOCodeObject, setDestinationUNLOCOCodeObject] = useState(null)
    const [loadPortObject, setLoadPortUNLOCOCodeObject] = useState(null)
    const [dischargePortObject, setDischargePortUNLOCOCodeObject] = useState(null)
    const [didLoadOrder, setDidLoadOrder] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [consignorAddressesModalOpen, setConsignorAddressesModalOpen] = useState(false)
    const [consigneeAddressesModalOpen, setConsigneeAddressesModalOpen] = useState(false)
    const [key, setKey] = useState(['order.create'])
    const [organizationId, setOrganizationId] = useState(null)
    const [orderAlert, setOrderAlert] = useState({
        open: false,
        success: false,
        message: ''
    })
    const [templateDialogAlert, setTemplateDialogAlert] = useState({
        open: false,
        success: false,
        message: ''
    })
    const [expandedPanels, setExpandedPanels] = useState([
        'panel0',
        'panel1',
        'panel2',
        'panel3',
        'panel4',
        'panel5'
    ])
    const [colorThemePrimaryRGB, setcolorThemePrimaryRGB] = useState('')
    const [colorThemeSecondaryRGB, setcolorThemeSecondaryRGB] = useState('')
    const [colorThemeTertiaryRGB, setcolorThemeTertiaryRGB] = useState('')
    const [colorThemeRGB, setcolorThemeRGB] = useState('')
    const [colorThemeTextRGB, setcolorThemeTextRGB] = useState('')
    const [retrievingOrder, setRetrievingOrder] = useState(false)
    const [retrievingOrderReferred, setRetrievingOrderReferred] = useState(false)
    const [addressSearchValue, setAddressSearchValue] = useState('')
    const [orderNumberLabel, setOrderNumberLabel] = useState('Order #')
    const [templates, setTemplates] = useState([])
    const [templateModalOpen, setTemplateModalOpen] = useState(false)
    const [filteredTemplates, setFilteredTemplates] = useState([])
    const [selectedAddressForeignKey, setselectedAddressForeignKey] = useState(null)
    const [currentClient, setCurrentClient] = useState(null)


    useEffect(() => {

        const orderId = sessionStorage.getItem(orderIdKey())
        if (orderId !== null && orderId !== undefined) {
            loadOrder(orderId)
        } else {
            setDidSetDestinationUnlocoCode(true)
            setDidSetOriginUnlocoCode(true)
            setDidSetLoadPortUnlocoCode(true)
            setDidSetDischargePortUnlocoCode(true)
        }

        getTypesData()

        if (!getValues('orderNumberSplit')) {
            setValue('orderNumberSplit', '0')
        }

        getClients()
        getOrderTemplates()
    }, [])

    useEffect(() => {
        setValue('containerTypeMode', null)
        if (getValues('transportModeCode') === 'AIR') {
            setFilteredContainerModes(
                containerModes.filter(mode => mode.propertyKey === 'LSE')
            )
        } else if (getValues('transportModeCode') === 'SEA') {
            setFilteredContainerModes(
                containerModes.filter(
                    mode =>
                        mode.propertyKey === 'FCL' || mode.propertyKey === 'LCL'
                )
            )
        } else if (getValues('transportModeCode') === 'RAI') {
            setFilteredContainerModes(
                containerModes.filter(mode => mode.propertyKey === 'FCL')
            )
        } else if (getValues('transportModeCode') === 'ROA') {
            setFilteredContainerModes(
                containerModes.filter(
                    mode =>
                        mode.propertyKey === 'FCL' ||
                        mode.propertyKey === 'LCL' ||
                        mode.propertyKey === 'LTL' ||
                        mode.propertyKey === 'FTL'
                )
            )
        } else {
            setFilteredContainerModes(containerModes)
        }
    }, [watchTransportMode])

    useEffect(() => {
        if (originUnlocoCodes.length > 0 && !didSetOriginUnlocoCode) {
            setOriginUNLOCOCodeObject(originUnlocoCodes[0])
            setDidSetOriginUnlocoCode(true)
        }
    }, [originUnlocoCodes])

    useEffect(() => {
        if (destinationUnlocoCodes.length > 0 && !didSetDestinationUnlocoCode) {
            setDestinationUNLOCOCodeObject(destinationUnlocoCodes[0])
            setDidSetDestinationUnlocoCode(true)
        }
    }, [destinationUnlocoCodes])

    useEffect(() => {
        if (loadPorts.length > 0 && !didSetLoadPort) {
            setLoadPortUNLOCOCodeObject(loadPorts[0])
            setDidSetLoadPortUnlocoCode(true)
        }
    }, [loadPorts])

    useEffect(() => {
        if (dischargePorts.length > 0 && !didSetDischargePort) {
            setDischargePortUNLOCOCodeObject(dischargePorts[0])
            setDidSetDischargePortUnlocoCode(true)
        }
    }, [dischargePorts])
    
    const contactsAreEqual = () => {
        const consignor = getValues('consignor')
        const consignee = getValues('consignee')
        if (consignor !== undefined && consignee !== undefined) {
            if (
                consignor.addressLine1 === consignee.addressLine1 &&
                consignor.city === consignee.city &&
                consignor.zipCode === consignee.zipCode &&
                consignor.state === consignee.state &&
                consignor.countryCode === consignee.countryCode
            ) {
                return true
            }
        }
        return false
    }

    const orderIdKey = () => {
        return key + '.orderId'
    }

    const loadOrderData = async data => {
        if (data.originUNLOCOCode !== null) {
            await searchUNLOCO(data.originUNLOCOCode, 'origin', false)
        }

        if (data.destinationUNLOCOCode !== null) {
            await searchUNLOCO(data.destinationUNLOCOCode, 'destination', false)
        }

        if (data.loadPort !== null) {
            await searchUNLOCO(data.loadPort, 'load', false)
        }

        if (data.dischargePort !== null) {
            await searchUNLOCO(data.dischargePort, 'discharge', false)
        }

        setOrganizationId(data.organizationId)
        setValue('clientId', data.clientId)
        setCurrentClient(clients.find(c => c.id === data.clientId))
        setValue('orderNumber', data.orderNumber)
        setValue('transportModeCode', data.transportModeCode)
        setValue('incoTermsCode', data.incoTermsCode)
        // setValue('containerTypeCode', data.containerTypeCode)
        setValue('serviceLevelCode', data.serviceLevelCode)
        setValue('currencyCode', data.currencyCode)
        setValue('goodsDescription', data.goodsDescription)
        setValue('orderDate', data.orderDate === '0001-01-01T00:00:00' ? null : data.orderDate)
        setValue('exWorksRequiredBy', data.exWorksRequiredBy === '0001-01-01T00:00:00' ? null : data.exWorksRequiredBy)
        setValue('deliveryRequiredBy', data.deliveryRequiredBy === '0001-01-01T00:00:00' ? null : data.deliveryRequiredBy)
        setValue('estimatedDeparture', data.estimatedDeparture === '0001-01-01T00:00:00' ? null : data.estimatedDeparture)
        setValue('estimatedArrival', data.estimatedArrival === '0001-01-01T00:00:00' ? null : data.estimatedArrival)
        setValue('estimatedDelivery', data.estimatedDelivery === '0001-01-01T00:00:00' ? null : data.estimatedDelivery)
        setValue('estimatedExFactory', data.estimatedExFactory === '0001-01-01T00:00:00' ? null : data.estimatedExFactory)
        setValue('estimatedOriginReceipt', data.estimatedOriginReceipt === '0001-01-01T00:00:00' ? null : data.estimatedOriginReceipt)

        const consignors = data.contacts.filter(
            c => c.addressType === 'PIC'
        )
        if (consignors.length > 0) {
            setValue('consignor', consignors[0])
        }

        const consignees = data.contacts.filter(
            c => c.addressType === 'DLV'
        )
        if (consignees.length > 0) {
            setValue('consignee', consignees[0])
        }
    }

    const loadOrder = async (orderId) => {
        getCreatedOrder(orderId, async data => {
            setDidLoadOrder(true)
            loadOrderData(data)
        })
    }

    const loadTemplate = async (orderId) => {
        getCreateOrderTemplate(orderId, async data => {
            setDidLoadOrder(true)
            loadOrderData(data)
            sessionStorage.setItem(
                orderIdKey(),
                data.id
            )
            // need to clear the order number
            setValue('orderNumber', '')
        })
    }

    const handleSetIsConsignor = (event, newValue) => {
        const clientId = getValues('clientId')
        if (clientId) {
            const client = clients.find(c => c.id === clientId)
            if (client) {  
                setClientAddresses(client, newValue)
            }
        }
        searchAddresses('', newValue, true)
        setIsConsignor(newValue);
      };

    const setDataTypes = data => {
        const values1 = data.filter(
            item => item.propertyType === 'TRANSPORT_MODE'
        )
        if (values1.length > 0) {
            setTransportModes(values1.filter(item => item.isAvailable === true))
            const defaultTransportMode = values1.filter(item => item.isDefault === true)
            if (defaultTransportMode.length > 0) {
                setValue('transportModeCode', defaultTransportMode[0].propertyKey)
            }
        }

        const values2 = data.filter(item => item.propertyType === 'INCO_TERMS')
        if (values2.length > 0) {
            setIncoTerms(values2.filter(item => item.isAvailable === true))
            const defaultIncoTerm = values2.filter(item => item.isDefault === true)
            if (defaultIncoTerm.length > 0) {
                setValue('incoTermsCode', defaultIncoTerm[0].propertyKey)
            }
        }

        const values4 = data.filter(
            item => item.propertyType === 'CONTAINER_TYPE_MODE'
        )
        if (values4.length > 0) {
            setContainerModes(values4.filter(item => item.isAvailable === true))
            const defaultContainerMode = values4.filter(item => item.isDefault === true)
            if (defaultContainerMode.length > 0) {
                setValue('containerTypeMode', defaultContainerMode[0].propertyKey)
            }
        }

        const values5 = data.filter(
            item => item.propertyType === 'SERVICE_LEVEL'
        )
        if (values5.length > 0) {
            setServiceLevels(values5.filter(item => item.isAvailable === true))
            const defaultServiceLevel = values5.filter(item => item.isDefault === true)
            if (defaultServiceLevel.length > 0) {
                setValue('serviceLevelCode', defaultServiceLevel[0].propertyKey)
            }
        }

        const values6 = data.filter(
            item => item.propertyType === 'CURRENCY_CODE'
        )
        if (values6.length > 0) {
            const currencyCodesArray = values6.filter(item => item.isAvailable === true)
            setCurrencyCodes(currencyCodesArray)
            const defaultCurrencyCode = values6.filter(item => item.isDefault === true)
            if (defaultCurrencyCode.length > 0) {
                setValue('currencyCode', defaultCurrencyCode[0].propertyKey)
            }
        }

    }

    const prepareData = data => {
        const newData = _.clone(_.omitBy(data, _.isNil))
        newData['originUNLOCOCode'] = originUNLOCOCodeObject?.unloco
        newData['destinationUNLOCOCode'] = destinationUNLOCOCodeObject?.unloco
        newData['loadPort'] = loadPortObject?.unloco
        newData['dischargePort'] = dischargePortObject?.unloco
        newData['piecesCount'] = parseFloat(data.piecesCount)
        newData['weight'] = parseFloat(data.weight)
        newData['volume'] = parseFloat(data.volume)
        newData['organizationId'] = organizationId

        const newConsignor = _.clone(data.consignor)
        newConsignor['addressType'] = 'PIC'

        const newConsignee = _.clone(data.consignee)
        newConsignee['addressType'] = 'DLV'

        newData['contacts'] = [newConsignor, newConsignee]
        return newData
    }

    const handleFormSubmit = data => {
        if (contactsAreEqual()) {
            setOrderAlert({
                open: true,
                success: false,
                message: 'Consignor and consignee addresses are the same.'
            })
        } else {
            const preparedData = prepareData(data)
            const orderId = getOrderKey()
            if (orderId) {
                preparedData['id'] = orderId
            }
            saveOrderData(preparedData)
        }
    }

    const handleCloseAlert = () => {
        setOrderAlert({
            open: false,
            success: false,
            message: ''
        })
    }

    const handleCloseOrderAlert = () => {
        setOrderAlert({
            open: false,
            success: false,
            message: ''
        })
    }

    const handleConsignorAddressModalClose = () => {
        setConsignorAddressesModalOpen(false)
        setFilteredOrgConsignorAddresses(orgConsignorAddresses)
    }

    const handleConsigneeAddressModalClose = () => {
        setConsigneeAddressesModalOpen(false)
        setFilteredOrgConsigneeAddresses(orgConsigneeAddresses)
    }

    const handleConsignorModalOpen = () => {
        searchAddresses('', isConsignor, false, true).then(([searchedAddresses]) => {
            setOrgConsignorAddresses(searchedAddresses)
            setFilteredOrgConsignorAddresses(searchedAddresses)
            setConsignorAddressesModalOpen(true)
        })
    }

    const handleConsigneeModalOpen = () => {
        searchAddresses('', isConsignor, false, false).then(([searchedAddresses]) => {
            setOrgConsigneeAddresses(searchedAddresses)
            setFilteredOrgConsigneeAddresses(searchedAddresses)
            setConsigneeAddressesModalOpen(true)
        })
    }

    const updateAddressInfoFromSelection = (data, sourceParty) => {
        if (data === null) {
            return
        }

        setValue(`${sourceParty}.name`, data.name)
        setValue(`${sourceParty}.portCode`, data.portCode)
        setValue(`${sourceParty}.addressLine1`, data.addressLine1)
        setValue(`${sourceParty}.city`, data.city)
        setValue(`${sourceParty}.state`, data.state)
        setValue(`${sourceParty}.countryCode`, data.countryCode)
        setValue(`${sourceParty}.zipCode`, data.zipCode)
        setValue(`${sourceParty}.clientBuyerSupplierCode`, data.orgCode)

    }


    const updateAddressInfo = data => {
        if (data === null) {
            return
        }

        const key = !isConsignor ? 'consignor' : 'consignee'

        setValue(`${key}.name`, data.name)
        setValue(`${key}.portCode`, data.portCode)
        setValue(`${key}.addressLine1`, data.addressLine1)
        setValue(`${key}.city`, data.city)
        setValue(`${key}.state`, data.state)
        setValue(`${key}.countryCode`, data.countryCode)
        setValue(`${key}.zipCode`, data.zipCode)
        setValue(`${key}.clientBuyerSupplierCode`, data.orgCode)
        setselectedAddressForeignKey(data.psaClientBuyerSupplierFK)
    }

    const renderOrderAlert = () => {
        return orderAlert.open ? (
            <div className="row mb-0">
                <AlertMessage
                    open={orderAlert.open}
                    success={orderAlert.success}
                    message={orderAlert.message}
                    onClose={() => handleCloseAlert()}
                />
            </div>
        ) : null
    }

    const renderTemplateDialogAlert = () => {
        return templateDialogAlert.open ? (
            <Alert
                onClose={() => setTemplateDialogAlert({ open: false })}
                severity={templateDialogAlert.success ? 'success' : 'error'}>
                {templateDialogAlert.message}
            </Alert>
        ) : null
    }

    useEffect(() => {
        getAppInfo()
    }, [])

    const getAppInfo = async () => {
        const token = await authService.getAccessToken()
        const response = await fetch('api/app/info', {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        })

        if (response.ok) {
            const data = await response.json()
            setOrganization(data)
            if (getOrgNameFromOrganization(data) === 'HAVI') setOrderNumberLabel('Customer PO #')
            setcolorThemePrimaryRGB(data.colorThemePrimaryRGB)
            setcolorThemeSecondaryRGB(data.colorThemeSecondaryRGB)
            setcolorThemeTertiaryRGB(data.colorThemeTertiaryRGB)
            setcolorThemeRGB(data.colorThemeRGB)
            setcolorThemeTextRGB(data.colorThemeTextRGB)

            setHasAccess(await canCreateOrder(data))
        }
    }

    const renderInput = (
        label,
        name,
        required = true,
        inputProps = {},
        customKey = null,
        customSubKey = null,
        fieldPattern = null
    ) => {
        return (
            <FormControl style={{ width: '100%' }}>
                <Grid container spacing={0} alignItems="flex-end">
                    <Grid item style={{ width: '100%' }}>
                        <Controller
                            rules={{
                                pattern: {
                                    value: fieldPattern ? fieldPattern : /.*/,
                                    message: 'Invalid field value.'
                                },
                                required: {
                                    value: required,
                                    message: 'This field is required.'
                                }
                            }}
                            name={name}
                            control={control}
                            defaultValue={''}
                            render={({
                                onChange,
                                onBlur,
                                value,
                                name
                            }) => (
                                <TextField
                                    style={{ width: '80%' }}
                                    label={label}
                                    onChange={onChange}
                                    inputProps={inputProps}
                                    placeholder={`Enter ${label}`}
                                    name={name}
                                    value={value ? value : ''}
                                />
                            )}
                        />

                        {errors[name] && (
                            <p className="m-0 message-validation">
                                {errors[name].message}
                            </p>
                        )}
                        {customKey !== undefined &&
                            customSubKey !== undefined &&
                            customKey !== null &&
                            customSubKey !== null &&
                            errors[customKey] &&
                            errors[customKey][customSubKey] && (
                                <p className="m-0 message-validation">
                                    {errors[customKey][customSubKey].message}
                                </p>
                            )}
                    </Grid>
                </Grid>
            </FormControl>
        )
    }

    const setClientAddresses = (client, isConsignor) => {

        const setKey = isConsignor ? 'consignor' : 'consignee'
        const clearKey = isConsignor ? 'consignee' : 'consignor'

        setValue(`${setKey}.name`, client.name)
        setValue(`${setKey}.addressLine1`, client.contactAddressLine1)
        setValue(`${setKey}.city`, client.contactAddressCity)
        setValue(`${setKey}.state`, client.contactAddressRegion)
        setValue(`${setKey}.countryCode`, client.contactAddressCountryCode)
        setValue(`${setKey}.zipCode`, client.contactAddressZipCode)
        setValue(`${setKey}.clientBuyerSupplierCode`, client.integrationCompanyCode)

        setValue(`${clearKey}.name`, '')
        setValue(`${clearKey}.addressLine1`, '')
        setValue(`${clearKey}.city`, '')
        setValue(`${clearKey}.state`, '')
        setValue(`${clearKey}.countryCode`, '')
        setValue(`${clearKey}.zipCode`, '')
        setValue(`${clearKey}.clientBuyerSupplierCode`, '')
    }

    const loadOrderFromTemplate = async template => {
        setTemplateLoading(true)
        loadTemplate(template.id).then(() => {
            setTemplateDialogAlert({
                open: true,
                success: true,
                message: 'Order template loaded successfully.'
            })
            setTemplateLoading(false)
        })
    }

    const handleSelectChange = (e, name) => {
        if (name === 'clientId') {
            const client = clients.find(c => c.id === e.target.value)
            setClientAddresses(client, isConsignor)
            // clear consignee address
            setAddresses([])
            setFilteredAddresses([])
            setOrgConsigneeAddresses([])
            setFilteredOrgConsigneeAddresses([])
            setOrgConsignorAddresses([])
            setFilteredOrgConsignorAddresses([])
            searchAddresses('', isConsignor, true).then(([searchedAddresses]) => {
                // something
            })
            setCurrentClient(client)

        }
    }

    const renderSelect = (
        label,
        name,
        data,
        keyValue = 'key',
        valueKey = 'value',
        showKeyInValue = false,
        required = true
    ) => {
        return (
            <FormControl style={{ width: '100%' }}>
                <InputLabel>{label}</InputLabel>
                <Controller
                    name={name}
                    rules={{ 
                        required: {
                            value: required,
                            message: 'This field is required.'
                        }
                    }}
                    control={control}
                    defaultValue={''}
                    render={({
                        onChange,
                        onBlur,
                        value,
                        name
                    }) => (
                            <Select
                                style={{ width: '100%' }}
                                label={label}
                                onChange={(e) => {
                                    onChange(e)
                                    handleSelectChange(e, name)
                                  }}
                                value={value ? value : ""}
                                name={name}
                                input={<Input />}>
                                {data.map(option => (
                                    <MenuItem
                                        key={option[keyValue]}
                                        value={option[keyValue]}>
                                        {showKeyInValue
                                            ? `${option[keyValue]} - ${option[valueKey]}`
                                            : option[valueKey]}
                                    </MenuItem>
                                ))}
                            </Select>
                    )}
                />
                {errors[name] && (
                    <p className="m-0 message-validation">
                        {errors[name].message}
                    </p>
                )}
            </FormControl>
        )
    }

    const renderTitle = (title, width = 12) => {
        return (
            <Grid item xs={width}>
                <Grid container>
                    <Grid>
                        <Typography gutterBottom variant="p">
                            {title}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const renderAutocomplete = (
        data,
        value,
        label,
        labelRender = () => {},
        selectChangeCallback = () => {},
        textChangeCallback = () => {}
    ) => {
        return (
            <Autocomplete
                style={{ width: '100%' }}
                value={value}
                getOptionSelected={(option, value) => {
                    return option.unloco === value.unloco
                }}
                onChange={selectChangeCallback}
                getOptionLabel={labelRender}
                options={data}
                loading={false}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={label}
                        style={{ width: '100%' }}
                        onChange={textChangeCallback}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps
                        }}
                    />
                )}
            />
        )
    }

    const handleDeleteTemplateClick = async template => {
        
        if (!window.confirm('Are you sure you want to delete this order template?')) {
            return
        }

        const token = await authService.getAccessToken()
        const response = await fetch('api/createOrders/DeleteOrderTemplate?orderId=' + template.id, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.ok) {
            getOrderTemplates()
            setTemplateDialogAlert({
                open: true,
                success: true,
                message: 'Order template deleted successfully.'
            })
        }
        else {
            setTemplateDialogAlert({
                open: true,
                success: false,
                message: 'Sorry, there was an error while deleting this order template.'
            })
        }
    }

    const renderUnitSection = (key, selectKey, data) => {
        const cKey = voca.capitalize(key)
        return (
            <div class="auto-compling-col">
                <Grid container item xs spacing={3} className="p-0 m-0">
                    <Grid item xs={6}>
                        <div className="">{renderInput(cKey, key)}</div>
                    </Grid>
                    <Grid item xs={6}>
                        <div>
                            {renderSelect(
                                `${cKey} Unit`,
                                selectKey,
                                data,
                                'propertyKey',
                                'value',
                                true
                            )}
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }

    const handleTemplateModalClose = () => {
        setTemplateModalOpen(false)
        setTemplateDialogAlert({
            open: false,
            success: false,
            message: ''
        })
    }

    const handleTemplateModalOpen = () => {
        setTemplateModalOpen(true)
    }

    const getOrderTemplates = async () => {
        const token = await authService.getAccessToken()
        const response = await fetch('api/createOrders/GetCreateOrderTemplates', {
            headers: !token ? {} : {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.ok) {
            const data = await response.json()
            setTemplates(data)
            setFilteredTemplates(data)
        }
    }

    const templateSearchOnChange = e => {
        const value = e.target.value.toLowerCase()
        setFilteredTemplates(
            templates.filter(template => {
                return (
                    template.templateName.toLowerCase().indexOf(value) !== -1
                )
            })
        )
    }

    const getClients = async () => {
        const token = await authService.getAccessToken()
        const response = await fetch('api/clients/getclientslist?module=createorder', {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        })
        if (response.ok) {
            const data = await response.json()

            setClients(data)
            if (data.length > 0) {
                if (!getValues('clientId')) {
                    setValue('clientId', data[0].id)
                    setCurrentClient(data[0])
                    const setKey = isConsignor ? 'consignor' : 'consignee'

                    setValue(`${setKey}.name`, data[0].name)
                    setValue(`${setKey}.addressLine1`, data[0].contactAddressLine1)
                    setValue(`${setKey}.city`, data[0].contactAddressCity)
                    setValue(`${setKey}.state`, data[0].contactAddressRegion)
                    setValue(`${setKey}.countryCode`, data[0].contactAddressCountryCode)
                    setValue(`${setKey}.zipCode`, data[0].contactAddressZipCode)
                    setValue(`${setKey}.clientBuyerSupplierCode`, data[0].integrationCompanyCode)

                    setAddresses([])
                    setFilteredAddresses([])
                    searchAddresses('', isConsignor, true)
                }
                setOrganizationId(data[0].psaOrganizationId)
            }
        }
    }

    const getTypesData = async () => {
        const token = await authService.getAccessToken()
        const response = await fetch(
            'api/DataManagement/GetDataTypes?dataTypesRequested=ALL',
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }
        )
        if (response.ok) {
            const data = await response.json()
            setDataTypes(data)
        }
    }

    const searchUNLOCO = async (query, portType, isName = true) => {
        getUNLOCO(query, isName, async data => {
            if (portType === 'origin') {
                setOriginUnlocoCodes(data)
            } else if (portType === 'destination') {
                setDestinationUnlocoCodes(data)
            } else if (portType === 'load') {
                setLoadUnlocoCodes(data)
            } else if (portType === 'discharge') {
                setDischargeUnlocoCodes(data)
            }
        })
    }

    const searchAddresses = async (query, isShipper, isPrimary, fromShipper) => {
        const token = await authService.getAccessToken()
        const clientId = getValues('clientId')

        if (!clientId) {
            return
        }

        let selectedPartyForeignKey = selectedAddressForeignKey

        if ((isShipper && fromShipper) || (!isShipper && !fromShipper)) {
            selectedPartyForeignKey = currentClient?.sourcePK
        }

        const response = await fetch(
            `api/DataManagement/GetClientOrgAddresses?clientId=${clientId}${
                isShipper ? '&IsSearchingShipper=true' : ''
            }&SearchQuery=${query}&IsPrimary=${isPrimary}&CW1OrgPK=${selectedPartyForeignKey ? selectedPartyForeignKey : ''}`,
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }
        )
        if (response.ok) {
            const data = await response.json()
            if (isPrimary) {
                setAddresses(data)
                setFilteredAddresses(data)
            }
            return [data]
        }
    }

    const addressSearchOnChange = e => {
        const value = e.target.value.toLowerCase()
        setAddressSearchValue(value)
    }

    useEffect(() => {
        addressSearchFilterList()
    }, [addressSearchValue])

    const addressSearchFilterList = () => {
        const filteredAddresses = addresses.filter(address => {
            return (
                address.orgCode
                    .toLowerCase()
                    .indexOf(addressSearchValue) !== -1 ||
                address.name
                    .toLowerCase()
                    .indexOf(addressSearchValue) !== -1 
            )
        })
        setFilteredAddresses(filteredAddresses)
    }

    const saveOrderData = async data => {
        const token = await authService.getAccessToken()
        await fetch('api/createOrders/PutCreateOrder?sourceForm=orderDetails', {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(async response => {
                let results = null
                if (response.ok) {
                    const newData = await response.json()

                    setOrderAlert({
                        open: true,
                        success: true,
                        message: 'Order information is saved. Redirecting...'
                    })

                    sessionStorage.setItem(
                        orderIdKey(),
                        newData.id
                    )

                    setTimeout(() => {
                        window.location.href = '/orders/create/notes'
                    }, 3000)
                } else {
                    setOrderAlert({
                        open: true,
                        success: false,
                        message:
                            'Sorry, there was an error while saving this details.'
                    })
                }
            })
            .catch(error => {
                setOrderAlert({
                    open: true,
                    success: false,
                    message:
                        'Sorry, there was an error while saving this order information.'
                })
            })
    }

    const renderDatePicker = (name, label, organization, required) => {
        return (
            <div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Controller
                        name={name}
                        rules={
                            required
                                ? { required: 'This field is required.' }
                                : {}
                        }
                        control={control}
                        defaultValue={null}
                        as={
                            <KeyboardDatePicker
                                margin="normal"
                                variant="inline"
                                label={label}
                                autoOk={true}
                                format={organization.pickerDateFormat}
                                onChange={(date, value) => {
                                    setValue(name, value + 'T00:00:00')
                                }}
                            />
                        }
                    />
                </MuiPickersUtilsProvider>

                {errors[name] && (
                    <p className="m-0 message-validation">
                        {errors[name].message}
                    </p>
                )}
            </div>
        )
    }

    const handleModalOpen = () => {
        setModalOpen(true)
    }

    const handleModalClose = () => {
        setModalOpen(false)
        setAddressSearchValue('')
        setFilteredAddresses(addresses)
    }

    const handleACCChange = panel => (event) => {
        // check if panel exists in expanded array, it not add, otherwise remove
        const newPanels = [...expandedPanels]
        const index = expandedPanels.indexOf(panel)
        if (index === -1) {
            newPanels.push(panel)
        }
        else {
            newPanels.splice(index, 1)
        }
        setExpandedPanels(newPanels)
    }

    return (
        <div className="w-100">
            <CardContent className="p-0 psa-shipment-paper-card">
                <Paper className="paper psashipmentpaper">
                    <form
                        className={classes.container}
                        onSubmit={handleSubmit(handleFormSubmit)}
                        noValidate
                        autoComplete="off">
                        <Grid container spacing={1} direction="column">
                            <Grid item xs={12} className="p-0 w-100">
                                <div className="create-shipment-stepper mx-1">
                                    <OrderCreationProgress index={0} />
                                </div>
                            </Grid>

                            <div class="accordian-body-padding">
                                <Grid
                                    className="mb-4 w-100"
                                    direction="column">
                                    <Grid item xs={12}>
                                        <div className="no-p-0 custom-psa-accordian">
                                        <Accordion
                                                square
                                                className="w-100"
                                                expanded={expandedPanels.includes('panel0')}
                                                onChange={handleACCChange(
                                                    'panel0'
                                                )}>
                                                <AccordionSummary
                                                    expandIcon={
                                                        <ExpandMoreIcon
                                                            style={{
                                                                border: colorThemePrimaryRGB,
                                                                color: colorThemePrimaryRGB
                                                            }}
                                                        />
                                                    }>
                                                    <Typography
                                                        className={
                                                            classes.heading
                                                        }>
                                                        {' '}
                                                        <h5
                                                            style={{
                                                                color: colorThemePrimaryRGB
                                                            }}>
                                                            {renderTitle(
                                                                'New Order Options'
                                                            )}
                                                        </h5>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            space={2}
                                                            spacing={4}
                                                            className="mb-4">
                                                            <Grid
                                                                item
                                                                xl={4}
                                                                lg={4}
                                                                md={4}
                                                                sm={6}
                                                                xs={12}
                                                                className="mb-2 xxl-2 ">
                                                                <Button
                                                                    onClick={() => {
                                                                        handleTemplateModalOpen()
                                                                    }}
                                                                    style={{
                                                                        background: colorThemePrimaryRGB,
                                                                        border: colorThemePrimaryRGB,
                                                                        color: colorThemeTextRGB
                                                                    }}
                                                                    variant="outlined"
                                                                    color="primary">
                                                                    {templateLoading ? 'Loading...' : 'Load from Template'}
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <div
                                                class="separator"
                                                style={{
                                                    background: colorThemePrimaryRGB
                                                }}></div>
                                            <Accordion
                                                square
                                                className="w-100"
                                                expanded={expandedPanels.includes('panel1')}
                                                onChange={handleACCChange(
                                                    'panel1'
                                                )}>
                                                <AccordionSummary
                                                    expandIcon={
                                                        <ExpandMoreIcon
                                                            style={{
                                                                border: colorThemePrimaryRGB,
                                                                color: colorThemePrimaryRGB
                                                            }}
                                                        />
                                                    }>
                                                    <Typography
                                                        className={
                                                            classes.heading
                                                        }>
                                                        {' '}
                                                        <h5
                                                            style={{
                                                                color: colorThemePrimaryRGB
                                                            }}>
                                                            {renderTitle(
                                                                'Client'
                                                            )}
                                                        </h5>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            space={2}
                                                            spacing={4}
                                                            className="mb-4">
                                                            <Grid
                                                                item
                                                                xl={4}
                                                                lg={4}
                                                                md={4}
                                                                sm={6}
                                                                xs={12}
                                                                className="mb-2 xxl-2 ">
                                                                {renderSelect(
                                                                    'Client',
                                                                    'clientId',
                                                                    clients,
                                                                    'id',
                                                                    'name'
                                                                )}
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xl={4}
                                                                lg={4}
                                                                md={4}
                                                                sm={6}
                                                                xs={12}
                                                                className="mb-2 xxl-2 ">
                                                                <ToggleButtonGroup
                                                                    value={isConsignor}
                                                                    exclusive
                                                                    onChange={handleSetIsConsignor}
                                                                    aria-label="is consignor"
                                                                    >
                                                                    <ToggleButton style={{
                                                                        width: '150px', 
                                                                        backgroundColor: isConsignor ? colorThemePrimaryRGB : null,
                                                                        color: isConsignor ? colorThemeTextRGB : null
                                                                        }} value={true} aria-label="Consignor">
                                                                        Consignor
                                                                    </ToggleButton>
                                                                    <ToggleButton style={{
                                                                        width: '150px', 
                                                                        backgroundColor: isConsignor ? null : colorThemePrimaryRGB,
                                                                        color: isConsignor ? null : colorThemeTextRGB
                                                                        }} value={false} aria-label="Consignee">
                                                                        Consignee
                                                                    </ToggleButton>
                                                                </ToggleButtonGroup>
                                                            </Grid>
                                                        </Grid>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <div
                                                class="separator"
                                                style={{
                                                    background: colorThemePrimaryRGB
                                                }}></div>
                                            <Accordion
                                                expanded={expandedPanels.includes('panel2')}
                                                onChange={handleACCChange(
                                                    'panel2'
                                                )}>
                                                <AccordionSummary
                                                    expandIcon={
                                                        <ExpandMoreIcon
                                                            style={{
                                                                border: colorThemePrimaryRGB,
                                                                color: colorThemePrimaryRGB
                                                            }}
                                                        />
                                                    }>
                                                    <Typography
                                                        className={
                                                            classes.heading
                                                        }>
                                                        {' '}
                                                        <h5
                                                            style={{
                                                                color: colorThemePrimaryRGB
                                                            }}>
                                                            {renderTitle(
                                                                'Consignor'
                                                            )}
                                                        </h5>
                                                    </Typography>
                                                    <FormControlLabel
                                                        aria-label="SelectConsignor"
                                                        onClick={(event) => event.stopPropagation()}
                                                        onFocus={(event) => event.stopPropagation()}
                                                        control={!isConsignor ? (
                                                            <IconButton
                                                                className="MuiButton-containedPrimary addbtn mr-2 text-white"
                                                                style={{
                                                                    background: colorThemePrimaryRGB,
                                                                    border: colorThemePrimaryRGB,
                                                                    color: colorThemeTextRGB
                                                                }}
                                                                onClick={() => {
                                                                    handleModalOpen()
                                                                }}
                                                                variant="contained"
                                                                color="primary">
                                                                <AddCircleIcon />
                                                                Select Consignor
                                                            </IconButton>
                                                        ) : <></>}
                                                        label=''
                                                    />               
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            space={3}
                                                            spacing={4}
                                                            className="mb-4">
                                                            <Grid
                                                                item
                                                                xl={3}
                                                                lg={4}
                                                                md={4}
                                                                sm={6}
                                                                xs={12}
                                                                className="mb-2 xxl-2">
                                                                {renderInput(
                                                                    'Consignor Code',
                                                                    'consignor.clientBuyerSupplierCode',
                                                                    true,
                                                                    {readOnly: true},
                                                                    'consignor',
                                                                    'clientBuyerSupplierCode'
                                                                )}
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xl={3}
                                                                lg={4}
                                                                md={4}
                                                                sm={6}
                                                                xs={12}
                                                                className="mb-2 xxl-2">
                                                                {renderInput(
                                                                    'Consignor Name',
                                                                    'consignor.name',
                                                                    true,
                                                                    {readOnly: true},
                                                                    'consignor',
                                                                    'name'
                                                                )}
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xl={3}
                                                                lg={4}
                                                                md={4}
                                                                sm={6}
                                                                xs={12}
                                                                className="mb-2 xxl-2">
                                                                {renderInput(
                                                                    'Address',
                                                                    'consignor.addressLine1',
                                                                    true,
                                                                    {readOnly: true},
                                                                    'consignor',
                                                                    'addressLine1'
                                                                )}
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xl={3}
                                                                lg={4}
                                                                md={4}
                                                                sm={6}
                                                                xs={12}
                                                                className="mb-2 xxl-2">
                                                                {renderInput(
                                                                    'City',
                                                                    'consignor.city',
                                                                    true,
                                                                    {readOnly: true},
                                                                    'consignor',
                                                                    'city'
                                                                )}
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xl={3}
                                                                lg={4}
                                                                md={4}
                                                                sm={6}
                                                                xs={12}
                                                                className="mb-2 xxl-2">
                                                                {renderInput(
                                                                    'State',
                                                                    'consignor.state',
                                                                    false,
                                                                    {readOnly: true},
                                                                )}
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xl={3}
                                                                lg={4}
                                                                md={4}
                                                                sm={6}
                                                                xs={12}
                                                                className="mb-2 xxl-2">
                                                                {renderInput(
                                                                    'Postal Code',
                                                                    'consignor.zipCode',
                                                                    false,
                                                                    {readOnly: true},
                                                                )}
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xl={3}
                                                                lg={4}
                                                                md={4}
                                                                sm={6}
                                                                xs={12}
                                                                className="mb-2 xxl-2">
                                                                {renderInput(
                                                                    'Country (2 Characters)',
                                                                    'consignor.countryCode',
                                                                    true,
                                                                    {
                                                                        maxLength: 2,
                                                                        readOnly: true
                                                                    },
                                                                    'consignor',
                                                                    'countryCode'
                                                                )}
                                                            </Grid>
                                                            <Grid
                                                                    item
                                                                    xs={8}>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={2}>
                                                                    <FormControlLabel
                                                                        aria-label="Select Consignor Address"
                                                                        onClick={(event) => event.stopPropagation()}
                                                                        onFocus={(event) => event.stopPropagation()}
                                                                        control={
                                                                            <IconButton
                                                                                className="MuiButton-containedPrimary addbtn mr-2 text-white"
                                                                                style={{
                                                                                    background: colorThemePrimaryRGB,
                                                                                    border: colorThemePrimaryRGB,
                                                                                    color: colorThemeTextRGB
                                                                                }}
                                                                                onClick={() => {
                                                                                    setFilteredOrgConsignorAddresses(orgConsignorAddresses)
                                                                                    handleConsignorModalOpen()
                                                                                }}
                                                                                variant="contained"
                                                                                color="primary">
                                                                                <AddCircleIcon />
                                                                                Select Address
                                                                            </IconButton>
                                                                        }
                                                                        label=''
                                                                    />             
                                                                </Grid>
                                                        </Grid>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <div
                                                class="separator"
                                                style={{
                                                    background: colorThemePrimaryRGB
                                                }}></div>
                                            <Accordion
                                                expanded={expandedPanels.includes('panel3')}
                                                onChange={handleACCChange(
                                                    'panel3'
                                                )}>
                                                <AccordionSummary
                                                    expandIcon={
                                                        <ExpandMoreIcon
                                                            style={{
                                                                border: colorThemePrimaryRGB,
                                                                color: colorThemePrimaryRGB
                                                            }}
                                                        />
                                                    }>
                                                    <Typography
                                                        className={
                                                            classes.heading
                                                        }>
                                                        {' '}
                                                        <h5
                                                            style={{
                                                                color: colorThemePrimaryRGB
                                                            }}>
                                                            {' '}
                                                            {renderTitle(
                                                                'Consignee'
                                                            )}
                                                        </h5>
                                                    </Typography>
                                                    <FormControlLabel
                                                        aria-label="SelectConsignee"
                                                        onClick={(event) => event.stopPropagation()}
                                                        onFocus={(event) => event.stopPropagation()}
                                                        control={isConsignor ? (
                                                            <IconButton
                                                                className="MuiButton-containedPrimary addbtn mr-2 text-white"
                                                                style={{
                                                                    background: colorThemePrimaryRGB,
                                                                    border: colorThemePrimaryRGB,
                                                                    color: colorThemeTextRGB
                                                                }}
                                                                onClick={() => {
                                                                    handleModalOpen()
                                                                }}
                                                                variant="contained"
                                                                color="primary">
                                                                <AddCircleIcon />
                                                                Select Consignee
                                                            </IconButton>
                                                        ) : <></>}
                                                        label=''
                                                    />   
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            space={3}
                                                            spacing={4}
                                                            className="mb-4">
                                                            <Grid
                                                                item
                                                                xl={3}
                                                                lg={4}
                                                                md={4}
                                                                sm={6}
                                                                xs={12}
                                                                className="mb-2 xxl-2">
                                                                {renderInput(
                                                                    'Consignee Code',
                                                                    'consignee.clientBuyerSupplierCode',
                                                                    true,
                                                                    {readOnly: true},
                                                                    'consignee',
                                                                    'clientBuyerSupplierCode'
                                                                )}
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xl={3}
                                                                lg={4}
                                                                md={4}
                                                                sm={6}
                                                                xs={12}
                                                                className="mb-2 xxl-2">
                                                                {renderInput(
                                                                    'Consignee Name',
                                                                    'consignee.name',
                                                                    true,
                                                                    {readOnly: true},
                                                                    'consignee',
                                                                    'name'
                                                                )}
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xl={3}
                                                                lg={4}
                                                                md={4}
                                                                sm={6}
                                                                xs={12}
                                                                className="mb-2 xxl-2">
                                                                {renderInput(
                                                                    'Address',
                                                                    'consignee.addressLine1',
                                                                    true,
                                                                    {readOnly: true},
                                                                    'consignee',
                                                                    'addressLine1'
                                                                )}
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xl={3}
                                                                lg={4}
                                                                md={4}
                                                                sm={6}
                                                                xs={12}
                                                                className="mb-2 xxl-2">
                                                                {renderInput(
                                                                    'City',
                                                                    'consignee.city',
                                                                    true,
                                                                    {readOnly: true},
                                                                    'consignee',
                                                                    'city'
                                                                )}
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xl={3}
                                                                lg={4}
                                                                md={4}
                                                                sm={6}
                                                                xs={12}
                                                                className="mb-2 xxl-2">
                                                                {renderInput(
                                                                    'State',
                                                                    'consignee.state',
                                                                    false,
                                                                    {readOnly: true}
                                                                )}
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xl={3}
                                                                lg={4}
                                                                md={4}
                                                                sm={6}
                                                                xs={12}
                                                                className="mb-2 xxl-2">
                                                                {renderInput(
                                                                    'Postal Code',
                                                                    'consignee.zipCode',
                                                                    false,
                                                                    {readOnly: true}
                                                                )}
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xl={3}
                                                                lg={4}
                                                                md={4}
                                                                sm={6}
                                                                xs={12}
                                                                className="mb-2 xxl-2">
                                                                {renderInput(
                                                                    'Country (2 Characters)',
                                                                    'consignee.countryCode',
                                                                    true,
                                                                    {
                                                                        maxLength: 2,
                                                                        readOnly: true
                                                                    },
                                                                    'consignee',
                                                                    'countryCode'
                                                                )}
                                                            </Grid>
                                                            <Grid
                                                                    item
                                                                    xs={8}>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={2}>
                                                                    <FormControlLabel
                                                                        aria-label="Select Consignee Address"
                                                                        onClick={(event) => event.stopPropagation()}
                                                                        onFocus={(event) => event.stopPropagation()}
                                                                        control={
                                                                            <IconButton
                                                                                className="MuiButton-containedPrimary addbtn mr-2 text-white"
                                                                                style={{
                                                                                    background: colorThemePrimaryRGB,
                                                                                    border: colorThemePrimaryRGB,
                                                                                    color: colorThemeTextRGB
                                                                                }}
                                                                                onClick={() => {
                                                                                    setFilteredOrgConsigneeAddresses(orgConsigneeAddresses)
                                                                                    handleConsigneeModalOpen()
                                                                                }}
                                                                                variant="contained"
                                                                                color="primary">
                                                                                <AddCircleIcon />
                                                                                Select Address
                                                                            </IconButton>
                                                                        }
                                                                        label=''
                                                                    />             
                                                                </Grid>
                                                        </Grid>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <div
                                                class="separator"
                                                style={{
                                                    background: colorThemePrimaryRGB
                                                }}></div>
                                            <Accordion
                                                expanded={expandedPanels.includes('panel4')}
                                                onChange={handleACCChange(
                                                    'panel4'
                                                )}>
                                                <AccordionSummary
                                                    expandIcon={
                                                        <ExpandMoreIcon
                                                            style={{
                                                                border: colorThemePrimaryRGB,
                                                                color: colorThemePrimaryRGB
                                                            }}
                                                        />
                                                    }>
                                                    <Typography
                                                        className={
                                                            classes.heading
                                                        }>
                                                        {' '}
                                                        <h5
                                                            style={{
                                                                color: colorThemePrimaryRGB
                                                            }}>
                                                            {' '}
                                                            {renderTitle(
                                                                'Details'
                                                            )}
                                                        </h5>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            space={3}
                                                            spacing={4}
                                                            className="mb-4">
                                                            <Grid
                                                                item
                                                                xl={4}
                                                                lg={4}
                                                                md={4}
                                                                sm={6}
                                                                xs={12}
                                                                className="mb-2 xxl-2">
                                                                {renderInput(
                                                                    orderNumberLabel,
                                                                    'orderNumber',
                                                                    true,
                                                                    {},
                                                                    null,
                                                                    null,
                                                                    /^[a-zA-Z0-9]*$/
                                                                )}
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xl={2}
                                                                lg={2}
                                                                md={4}
                                                                sm={4}
                                                                xs={6}
                                                                className="mb-2">
                                                                {renderSelect(
                                                                    'Transport Mode',
                                                                    'transportModeCode',
                                                                    transportModes,
                                                                    'propertyKey',
                                                                    'value',
                                                                    true
                                                                )}
                                                            </Grid>
                                                            {/* <Grid
                                                                item
                                                                xl={2}
                                                                lg={2}
                                                                md={4}
                                                                sm={4}
                                                                xs={6}
                                                                className="mb-2">
                                                                {renderSelect(
                                                                    'Container Mode',
                                                                    'containerTypeCode',
                                                                    filteredContainerModes,
                                                                    'propertyKey',
                                                                    'value',
                                                                    true
                                                                )}
                                                            </Grid> */}
                                                            <Grid
                                                                item
                                                                xl={2}
                                                                lg={2}
                                                                md={4}
                                                                sm={4}
                                                                xs={6}
                                                                className="mb-2">
                                                                {renderSelect(
                                                                    'Inco Terms',
                                                                    'incoTermsCode',
                                                                    incoTerms,
                                                                    'propertyKey',
                                                                    'value',
                                                                    true
                                                                )}
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xl={2}
                                                                lg={2}
                                                                md={4}
                                                                sm={4}
                                                                xs={6}
                                                                className="mb-2">
                                                                {renderSelect(
                                                                    'Service Level',
                                                                    'serviceLevelCode',
                                                                    serviceLevels,
                                                                    'propertyKey',
                                                                    'value',
                                                                    true
                                                                )}
                                                            </Grid>
                                                            {
                                                                getOrgNameFromOrganization(organization) === 'HAVI' ? ( 
                                                                    <Grid
                                                                        item
                                                                        xl={2}
                                                                        lg={2}
                                                                        md={4}
                                                                        sm={4}
                                                                        xs={6}
                                                                        className="mb-2">
                                                                        {renderSelect(
                                                                            'Currency',
                                                                            'currencyCode',
                                                                            currencyCodes,
                                                                            'propertyKey',
                                                                            'value',
                                                                            true,
                                                                            true
                                                                        )}
                                                                    </Grid>
                                                                ) : (
                                                                    null
                                                                )
                                                            }
                                                            <Grid
                                                                item
                                                                xl={4}
                                                                lg={4}
                                                                md={6}
                                                                sm={6}
                                                                xs={12}
                                                                className="mb-2">
                                                                {renderInput(
                                                                    'Goods Description',
                                                                    'goodsDescription'
                                                                )}
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xl={3}
                                                                lg={4}
                                                                md={4}
                                                                sm={6}
                                                                xs={12}
                                                                className="mb-2 xxl-2">
                                                                <div class="auto-complete">
                                                                    {renderAutocomplete(
                                                                        originUnlocoCodes,
                                                                        originUNLOCOCodeObject,
                                                                        'Origin',
                                                                        option =>
                                                                            `${option.unloco} - ${option.portName}`,
                                                                        (
                                                                            e,
                                                                            value
                                                                        ) => {
                                                                            if (
                                                                                value !==
                                                                                null
                                                                            ) {
                                                                                setOriginUNLOCOCodeObject(
                                                                                    value
                                                                                )
                                                                            }
                                                                        },
                                                                        e => {
                                                                            const value =
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            searchUNLOCO(
                                                                                value,
                                                                                'origin',
                                                                                true
                                                                            )
                                                                        }
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xl={3}
                                                                lg={4}
                                                                md={4}
                                                                sm={6}
                                                                xs={12}
                                                                className="mb-2 xxl-2">
                                                                <div class="auto-complete">
                                                                    {renderAutocomplete(
                                                                        destinationUnlocoCodes,
                                                                        destinationUNLOCOCodeObject,
                                                                        'Destination',
                                                                        option =>
                                                                            `${option.unloco} - ${option.portName}`,
                                                                        (
                                                                            e,
                                                                            value
                                                                        ) => {
                                                                            if (
                                                                                value !==
                                                                                null
                                                                            ) {
                                                                                setDestinationUNLOCOCodeObject(
                                                                                    value
                                                                                )
                                                                            }
                                                                        },
                                                                        e => {
                                                                            const value =
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            searchUNLOCO(
                                                                                value,
                                                                                'destination',
                                                                                true
                                                                            )
                                                                        }
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xl={3}
                                                                lg={4}
                                                                md={4}
                                                                sm={6}
                                                                xs={12}
                                                                className="mb-2 xxl-2">
                                                                <div class="auto-complete">
                                                                    {renderAutocomplete(
                                                                        loadPorts,
                                                                        loadPortObject,
                                                                        'Load Port',
                                                                        option =>
                                                                            `${option.unloco} - ${option.portName}`,
                                                                        (
                                                                            e,
                                                                            value
                                                                        ) => {
                                                                            if (
                                                                                value !==
                                                                                null
                                                                            ) {
                                                                                setLoadPortUNLOCOCodeObject(
                                                                                    value
                                                                                )
                                                                            }
                                                                        },
                                                                        e => {
                                                                            const value =
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            searchUNLOCO(
                                                                                value,
                                                                                'load',
                                                                                true
                                                                            )
                                                                        }
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xl={3}
                                                                lg={4}
                                                                md={4}
                                                                sm={6}
                                                                xs={12}
                                                                className="mb-2 xxl-2">
                                                                <div class="auto-complete">
                                                                    {renderAutocomplete(
                                                                        dischargePorts,
                                                                        dischargePortObject,
                                                                        'Discharge Port',
                                                                        option =>
                                                                            `${option.unloco} - ${option.portName}`,
                                                                        (
                                                                            e,
                                                                            value
                                                                        ) => {
                                                                            if (
                                                                                value !==
                                                                                null
                                                                            ) {
                                                                                setDischargePortUNLOCOCodeObject(
                                                                                    value
                                                                                )
                                                                            }
                                                                        },
                                                                        e => {
                                                                            const value =
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            searchUNLOCO(
                                                                                value,
                                                                                'discharge',
                                                                                true
                                                                            )
                                                                        }
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <div
                                                class="separator"
                                                style={{
                                                    background: colorThemePrimaryRGB
                                                }}></div>
                                            <Accordion
                                                expanded={expandedPanels.includes('panel5')}
                                                onChange={handleACCChange(
                                                    'panel5'
                                                )}>
                                                <AccordionSummary
                                                    expandIcon={
                                                        <ExpandMoreIcon
                                                            style={{
                                                                border: colorThemePrimaryRGB,
                                                                color: colorThemePrimaryRGB
                                                            }}
                                                        />
                                                    }>
                                                    <Typography
                                                        className={
                                                            classes.heading
                                                        }>
                                                        {' '}
                                                        <h5
                                                            style={{
                                                                color: colorThemePrimaryRGB
                                                            }}>
                                                            {' '}
                                                            {renderTitle(
                                                                'Dates'
                                                            )}
                                                        </h5>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            space={3}
                                                            spacing={4}
                                                            className="mb-4 button-no-padding">
                                                            {
                                                                getOrgNameFromOrganization(organization) === 'HAVI' ? ( 
                                                                   null
                                                                ) : (
                                                                    <Grid
                                                                        item
                                                                        xl={3}
                                                                        lg={4}
                                                                        md={4}
                                                                        sm={6}
                                                                        xs={12}
                                                                        className="mb-2 xxl-2">
                                                                        {renderDatePicker(
                                                                            'orderDate',
                                                                            'Order Date',
                                                                            organization,
                                                                            true
                                                                        )}
                                                                    </Grid>
                                                                )
                                                            }
                                                            <Grid
                                                                item
                                                                xl={3}
                                                                lg={4}
                                                                md={4}
                                                                sm={6}
                                                                xs={12}
                                                                className="mb-2 xxl-2">
                                                                {renderDatePicker(
                                                                    'exWorksRequiredBy',
                                                                    'Ex Works Required By',
                                                                    organization
                                                                )}
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xl={3}
                                                                lg={4}
                                                                md={4}
                                                                sm={6}
                                                                xs={12}
                                                                className="mb-2 xxl-2">
                                                                {renderDatePicker(
                                                                    'deliveryRequiredBy',
                                                                    'Delivery Required By',
                                                                    organization
                                                                )}
                                                            </Grid>
                                                                {
                                                                    getOrgNameFromOrganization(organization) === 'HAVI' ? ( 
                                                                        null
                                                                    ) : (
                                                                        <Grid
                                                                            item
                                                                            xl={3}
                                                                            lg={4}
                                                                            md={4}
                                                                            sm={6}
                                                                            xs={12}
                                                                            className="mb-2 xxl-2">
                                                                            {renderDatePicker(
                                                                                'estimatedExFactory',
                                                                                'Estimated Ex Factory',
                                                                                organization
                                                                            )}
                                                                        </Grid>
                                                                    )
                                                                }
                                                            <Grid
                                                                item
                                                                xl={3}
                                                                lg={4}
                                                                md={4}
                                                                sm={6}
                                                                xs={12}
                                                                className="mb-2 xxl-2">
                                                                {renderDatePicker(
                                                                    'estimatedOriginReceipt',
                                                                    'Estimated Origin Receipt',
                                                                    organization
                                                                )}
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xl={3}
                                                                lg={4}
                                                                md={4}
                                                                sm={6}
                                                                xs={12}
                                                                className="mb-2 xxl-2">
                                                                {renderDatePicker(
                                                                    'estimatedDeparture',
                                                                    'Estimated Departure',
                                                                    organization
                                                                )}
                                                            </Grid>
                                                            {
                                                                getOrgNameFromOrganization(organization) === 'HAVI' ? ( 
                                                                    null
                                                                ) : (
                                                                    <Grid
                                                                        item
                                                                        xl={3}
                                                                        lg={4}
                                                                        md={4}
                                                                        sm={6}
                                                                        xs={12}
                                                                        className="mb-2 xxl-2">
                                                                        {renderDatePicker(
                                                                            'estimatedArrival',
                                                                            'Estimated Arrival',
                                                                            organization
                                                                        )}
                                                                    </Grid>
                                                                )
                                                            }
                                                            <Grid
                                                                item
                                                                xl={3}
                                                                lg={4}
                                                                md={4}
                                                                sm={6}
                                                                xs={12}
                                                                className="mb-2 xxl-2">
                                                                {renderDatePicker(
                                                                    'estimatedDelivery',
                                                                    'Estimated Delivery',
                                                                    organization
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <div
                                                class="separator"
                                                style={{
                                                    background: colorThemePrimaryRGB
                                                }}></div>
                                            <div class="spacer mb-40px w-100"></div>
                                            <Grid container spacing={4}>
                                                <Grid item xs={12}>
                                                    <div className="w-100 mb-4 d-flex justify-content-end">
                                                        {renderOrderAlert()}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <div className="w-100 mb-4 d-flex justify-content-end">
                                                        <Button
                                                            style={{
                                                                background: colorThemePrimaryRGB,
                                                                border: colorThemePrimaryRGB,
                                                                color: colorThemeTextRGB
                                                            }}
                                                            variant="contained"
                                                            color="primary"
                                                            type='submit'>
                                                            Save/Next
                                                        </Button>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6} />
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} />
                                </Grid>
                            </div>
                        </Grid>
                    </form> 
                </Paper>
            </CardContent>
            <CustomDialog
                isOpen={modalOpen}
                handleClose={handleModalClose}
                title=""
                //className="overflow-y-hidden"
                style={{
                    border: colorThemePrimaryRGB
                }}>
                <h4
                    className="px-4 pb-3"
                    style={{
                        color: colorThemePrimaryRGB
                    }}>
                    {isConsignor ? 'Select Consignee' : 'Select Consignor'}
                </h4>
                <TextField
                    style={{ width: '80%' }}
                    onChange={(e) => {
                        addressSearchOnChange(e)
                    }}
                    placeholder={'Enter org code or name..'}
                />
                <div
                    class="separator"
                    style={{
                        background: colorThemePrimaryRGB
                    }}></div>
                <>
                    {
                        // if addresses is empty then display 'no results'
                        addresses.length === 0 && (
                            <div class="modaldialog-content">
                                <p>No records found!</p>
                            </div>
                        )
                    }
                    <List>
                        {filteredAddresses.map((address, i) => (
                            <ListItem
                                button
                                onClick={() => {
                                    updateAddressInfo(address)
                                    setModalOpen(false)
                                }}
                                key={`${i}_${address.orgCode}`}>
                                {`${address.orgCode} | ${address.name} | ${address.city + ', ' + address.addressLine1.substring(0,15) + '...'} `}
                            </ListItem>
                        ))}
                    </List>
                </>
            </CustomDialog>
            <CustomDialog
                isOpen={templateModalOpen}
                handleClose={handleTemplateModalClose}
                title=""
                //className="overflow-y-hidden"
                style={{
                    border: colorThemePrimaryRGB
                }}>
                <h4
                    className="px-4 pb-3"
                    style={{
                        color: colorThemePrimaryRGB
                    }}>
                    Select Template
                </h4>
                {
                    renderTemplateDialogAlert()
                }
                <TextField
                    style={{ width: '80%' }}
                    onChange={(e) => {
                        // do something
                        templateSearchOnChange(e)
                    }}
                    placeholder={'Enter template name..'}
                />
                <div
                    class="separator"
                    style={{
                        background: colorThemePrimaryRGB
                    }}></div>
                <>
                    {
                        // if addresses is empty then display 'no results'
                        templates.length === 0 && (
                            <div class="modaldialog-content">
                                <p>No templates found!</p>
                            </div>
                        )
                    }
                <List>
                        {filteredTemplates.map((template, i) => (
                            <ListItem key={`${i}_${template.templateName}`} role={undefined} dense button onClick={() => {
                                    loadOrderFromTemplate(template)
                                }}>
                                <ListItemText id={`${i}_label_${template.templateName}`} primary={`${template.templateName}`} />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" onClick={() => {
                                        handleDeleteTemplateClick(template)
                                    }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </>
            </CustomDialog>
            <CustomDialog
                isOpen={consigneeAddressesModalOpen}
                handleClose={handleConsigneeAddressModalClose}
                title=""
                //className="overflow-y-hidden"
                style={{
                    border: colorThemePrimaryRGB
                }}>
                <h4
                    className="px-4 pb-3"
                    style={{
                        color: colorThemePrimaryRGB
                    }}>
                    Select Address
                </h4>
                <TextField
                    style={{ width: '80%' }}
                    onChange={(e) => {
                        // need to filter the addresses based on the input
                        const value = e.target.value
                        const filteredConsigneeAddresses = orgConsigneeAddresses.filter(address => {
                            return (
                                address.orgCode
                                    .toLowerCase()
                                    .includes(value.toLowerCase()) ||
                                address.name
                                    .toLowerCase()
                                    .includes(value.toLowerCase())
                            )
                        })
                        setFilteredOrgConsigneeAddresses(filteredConsigneeAddresses)
                    }}
                    placeholder={'Enter org code or name..'}
                />
                <div
                    class="separator"
                    style={{
                        background: colorThemePrimaryRGB
                    }}></div>
                <>
                    {
                        // if addresses is empty then display 'no results'
                        orgConsigneeAddresses.length === 0 && (
                            <div class="modaldialog-content">
                                <p>No records found!</p>
                            </div>
                        )
                    }
                    <List>
                        {filteredOrgConsigneeAddresses.map((address, i) => (
                            <ListItem
                                button
                                onClick={() => {
                                    updateAddressInfoFromSelection(address, 'consignee')
                                    setConsigneeAddressesModalOpen(false)
                                }}
                                // set bold if isprimary = true
                                style={{
                                    fontWeight: address.isPrimary ? 'bold' : 'normal'
                                }}
                                key={`${i}_${address.orgCode}`}>
                                {`${address.orgCode} | ${address.name} | ${address.city + ', ' + address.addressLine1.substring(0,15) + '...'} `}
                            </ListItem>
                        ))}
                    </List>
                </>
            </CustomDialog>
            <CustomDialog
                isOpen={consignorAddressesModalOpen}
                handleClose={handleConsignorAddressModalClose}
                title=""
                //className="overflow-y-hidden"
                style={{
                    border: colorThemePrimaryRGB
                }}>
                <h4
                    className="px-4 pb-3"
                    style={{
                        color: colorThemePrimaryRGB
                    }}>
                    Select Address
                </h4>
                <TextField
                    style={{ width: '80%' }}
                    onChange={(e) => {
                        // need to filter the addresses based on the input
                        const value = e.target.value
                        const filteredConsignorAddresses = orgConsignorAddresses.filter(address => {
                            return (
                                address.orgCode
                                    .toLowerCase()
                                    .includes(value.toLowerCase()) ||
                                address.name
                                    .toLowerCase()
                                    .includes(value.toLowerCase())
                            )
                        })
                        setFilteredOrgConsignorAddresses(filteredConsignorAddresses)
                    }}
                    placeholder={'Enter org code or name..'}
                />
                <div
                    class="separator"
                    style={{
                        background: colorThemePrimaryRGB
                    }}></div>
                <>
                    {
                        // if addresses is empty then display 'no results'
                        orgConsignorAddresses.length === 0 && (
                            <div class="modaldialog-content">
                                <p>No records found!</p>
                            </div>
                        )
                    }
                    <List>
                        {filteredOrgConsignorAddresses.map((address, i) => (
                            <ListItem
                                button
                                onClick={() => {
                                    updateAddressInfoFromSelection(address, 'consignor')
                                    setConsignorAddressesModalOpen(false)
                                }}
                                // set bold if isprimary = true
                                style={{
                                    fontWeight: address.isPrimary ? 'bold' : 'normal'
                                }}
                                key={`${i}_${address.orgCode}`}>
                                {`${address.orgCode} | ${address.name} | ${address.city + ', ' + address.addressLine1.substring(0,15) + '...'} `}
                            </ListItem>
                        ))}
                    </List>
                </>
            </CustomDialog>
        </div>
    )
}
