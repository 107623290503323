import React, { useState, useEffect } from 'react'
import authService from '../api-authorization/AuthorizeService'
import { makeStyles } from '@material-ui/core/styles'
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants'
import { IconButton, Paper, Grid } from '@material-ui/core'

import MaterialTable from 'material-table'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import { ThemeProvider, createTheme } from '@mui/material'
import { NavLink } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    textFieldForm: {
        width: theme.textFieldForm.width
    },
    link: {
        color: 'blue'
    }
}))

const newDataMappingPath = { pathname: `${ApplicationPaths.DataMappingCreate}` }

export default function DataMappingIndex(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [items, setItems] = useState([])

    const tableTheme = createTheme()
    const classes = useStyles()

    useEffect(() => {
        populateItems()
    }, [])





    const headCells = [
        {
            field: 'propertyType',
            title: 'Data Type'
        },
        {
            field: 'propertyKey',
            title: 'Code'
        },
        {
            field: 'maxLength',
            title: 'Max Code Length'
        },
        {
            field: 'value',
            title: 'Description',
            render: params => {
                var path = `/admin/data_mapping/edit/${params.propertyType}/${params.propertyKey}`
                return (
                    <div>
                        <NavLink
                            to={path}>
                            {params['value']}
                        </NavLink>
                    </div>
                )
            }
        },
        {
            field: 'isAvailable',
            title: 'Is Visible',
            render: params => {
                return params.isAvailable ? (
                    <RadioButtonCheckedIcon fontSize="large" />
                ) : (
                    <RadioButtonUncheckedIcon fontSize="large" />
                )
            }
        },
        {
            field: 'isDefault',
            title: 'Is Default',
            render: params => {
                return params.isDefault ? (
                    <RadioButtonCheckedIcon fontSize="large" />
                ) : (
                    <RadioButtonUncheckedIcon fontSize="large" />
                )
            }
        },
        {
            field: 'hasDataLinks',
            title: 'Has Data Links',
            render: params => {                
                return params.hasLinks ? (
                    <RadioButtonCheckedIcon fontSize="large" />
                ) : (
                    <RadioButtonUncheckedIcon fontSize="large" />
                )
            }
        }
    ]

    const populateItems = async () => {
        setIsLoading(true)
        const token = await authService.getAccessToken()
        await fetch('api/DataManagement/GetDataTypes?dataTypesRequested=ALL', {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        })
            .then(async response => {
                if (response.ok) {
                    const items = await response.json()
                    setItems(items)
                    setIsLoading(false)
                } else {
                    return response.text
                }
            })
            .catch(err => {
                setIsLoading(false)
                console.log(err)
            })
    }

    return (
        <div>
            <div className="psa-shipment-table">
                <Grid container>
                    <Grid item xs={12}>
                        <div className="mb-4">
                            <Paper elevation={0}>
                                <ThemeProvider theme={tableTheme}>
                                    <MaterialTable
                                        columns={headCells}
                                        data={items}
                                        isLoading={isLoading}
                                        actions={[
                                            {
                                                icon: () => {
                                                    return (
                                                        <NavLink
                                                            to={newDataMappingPath.pathname}>
                                                            <IconButton color="primary">
                                                                <AddCircleOutlineIcon />
                                                                ADD
                                                            </IconButton>
                                                        </NavLink>
                                                    )
                                                },
                                                tooltip: 'Add New Data Map',
                                                isFreeAction: true
                                            }
                                        ]}
                                        options={{
                                            filtering: true,
                                            maxBodyHeight: 'calc(100vh - 348px)',
                                            pageSizeOptions: [25, 50, 75],
                                            sorting: true,
                                            pageSize: 25,
                                            showTitle: false,
                                            title: 'Data Mappings',
                                            search: true,
                                            columnsButton: false,
                                            doubleHorizontalScroll: true,
                                            showFirstLastPageButtons: true,
                                            toolbar: true,
                                            padding: 'dense',
                                            pagination: {
                                                rowsPerPage: 'Rows per page:',
                                                displayRows: 'off'
                                            },
                                            exportButton: true,
                                            exportFileName:
                                                'DataTypesExport_' + Date.now(),
                                            exportAllData: true,
                                            rowStyle: rowData => ({
                                                // check if master data
                                                backgroundColor: rowData.isMasterData
                                                    ? '#DADFE8'
                                                    : null
                                            })
                                        }}
                                    />
                                </ThemeProvider>
                            </Paper>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
