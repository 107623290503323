import * as React from 'react'
import PropTypes from 'prop-types'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Button,
    Divider
} from '@material-ui/core'

export default class DialogOk extends React.Component {
    render() {
        if (!this.props.show) {
            return null
        }
        return (
            <Dialog
                open={this.props.show}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {' '}
                    {this.props.title}
                </DialogTitle>
                <DialogContent>{this.props.children}</DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        autoFocus
                        onClick={() => this.props.callback()}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}
DialogOk.propTypes = {
    callback: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired
}
