import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import authService from "../api-authorization/AuthorizeService"
import Alert from "@material-ui/lab/Alert"
import CloseIcon from "@material-ui/icons/Close"
import AddIcon from "@material-ui/icons/Add"
import Button from "@mui/material/Button"
import { createView } from "./Views"
import { createTheme } from '@mui/material/styles'
import { useEffect } from "react"
import TextField from '@material-ui/core/TextField'


const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  docTypeSelect: {},
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  editColumnTextField: {
    width: '40ch',
  }
}))

export default function TableViewCreateDialog(props) {
  const classes = useStyles()
  const [isCreateSuccess, setIsCreateSuccess] = React.useState(false)
  const [creatingView, setCreatingView] = React.useState(false)
  const [isCreateViewError, setIsCreateViewError] = React.useState(false)
  const [createViewErrorMessage, setCreateViewErrorMessage] = React.useState("")
  const [adminUser, setAdminUser] = React.useState(false)
  const [promptForViewName, setPromptForViewName] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [superAdminUser, setSuperAdminUser] = React.useState(false)

  useEffect(() => {
    async function fetchIsAdmin() {
      const userIsAdmin = await authService.isAdmin()
      const userIsSuperAdmin = await authService.isSuperAdmin()
      setAdminUser(userIsAdmin || userIsSuperAdmin)
      setSuperAdminUser(userIsSuperAdmin)
    }
    fetchIsAdmin()
  }, [])

  const { palette } = createTheme();
  const theme = createTheme({
    palette: {
      primary: {
        main: props.backgroundColor,
        contrastText: props.fontColor,
      },
    },
  });

  const handleViewNameClose = () => {
    setPromptForViewName(false)
    setIsLoading(false)
  }

  const handleViewNameSave = async () => {
    setCreatingView(true)
    const viewName = document.getElementById("viewName").value
    if (viewName === "") {
      alert("Please provide a name for the new view.")
      return
    }
    await createView(viewName, handleCreateViewSuccess, handleCreateViewError)

  }

  const handleCreateClickOpen = async () => {
    setPromptForViewName(true)
  }

  const handleCreateViewSuccess = () => {
    setIsCreateSuccess(true)
    setInterval(function () {
      // call callback function passed in from props
      setIsCreateSuccess(false)
      setPromptForViewName(false)
      setCreatingView(false)
    }, 3000)
  }

  const handleCreateViewError = (errorMessage) => {
    setCreateViewErrorMessage(errorMessage)
    setIsCreateViewError(true)
    setCreatingView(false)
  }

  return (
    <>
      {
        adminUser &&
        <IconButton style={{ float: 'left', zIndex: 99}} color="inherit" size="small" onClick={handleCreateClickOpen} disableRipple disableFocusRipple>
          <AddIcon />
          Create View
        </IconButton>
      }
      <Dialog open={promptForViewName} onClose={handleViewNameClose}>
        <DialogTitle>Enter New View Name</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="viewName"
            label="View Name"
            type="text"
            fullWidth
            disabled={creatingView}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleViewNameClose} color="primary" disabled={creatingView}>
            Cancel
          </Button>
          <Button onClick={handleViewNameSave} color="primary" disabled={creatingView}>
            Save
          </Button>
        </DialogActions>
        {isCreateSuccess && <Alert severity="success" variant='filled'>View Created!</Alert>}
        {isCreateViewError && (
            <Alert
              severity="error"
              variant="filled"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setIsCreateViewError(false)
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {createViewErrorMessage}
            </Alert>
          )}
      </Dialog>
    </>
  )
}
