import React, { PureComponent } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Root, Header, Sidebar, Content } from '@mui-treasury/layout'
import { CollapseBtn, CollapseIcon } from '@mui-treasury/layout'
import { createTheme } from '@material-ui/core/styles'
import { Card, CardMedia, CardActionArea, CardContent } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { standardLayoutPreset } from '@mui-treasury/layout/presets'
import NestedList from './NestedList'
import FooterNav from './shared/FooterNav'
import { navItems } from './Navigation/NavItems'
import authService from './api-authorization/AuthorizeService'
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants'
import NavBar from './shared/NavBar'
import { Alert, AlertTitle } from '@material-ui/lab';

import {
    canSeeCustoms,
    canCreateShipment,
    canSearchShipment,
    canSeeBooking,
    canSeeOrders,
    canCreateOrder,
    canSeeAccounting,
    canSeeContainers
} from '../utils/permissions'
import { getAppInfo } from '../api/api'

import './styles.css'
import '../../src/custom.css'

export class Layout extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            organizations: [],
            isAuthenticated: false,
            isAdmin: false,
            isSuperAdmin: false,
            organization: null,
            canShowCustoms: false,
            canCreateShipment: false,
            canSearchShipment: false,
            canSeeBooking: false,
            canSeeOrders: false,
            canCreateOrder: false,
            canSeeAccounting: false,
            canSeeContainers: false,
            bannerInfo: null,
            showBannerAlert: false
        }
    }

    async componentWillUnmount() {
        if (this._subscription) {
            authService.unsubscribe(this._subscription)
        }
    }

    async componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState())
        this.populateState().then(() => {
            getAppInfo(null, async data => {
                this.setState({
                    organization: data,
                    canShowCustoms: await canSeeCustoms(data),
                    canCreateShipment: await canCreateShipment(data),
                    canSearchShipment: await canSearchShipment(data),
                    canSeeBooking: await canSeeBooking(data),
                    canSeeOrders: await canSeeOrders(data),
                    canCreateOrder: await canCreateOrder(data),
                    canSeeAccounting: await canSeeAccounting(data),
                    canSeeContainers: await canSeeContainers(data)
                })

                if (this.state.isSuperAdmin) {
                    this.getOrganizations()
                }

                if (data.showBanner === true) {
                    const bannerInfo = {
                        bannerText: data.bannerText,
                        bannerBackgroundColor: data.bannerBackgroundColor,
                        bannerTextColor: data.bannerTextColor,
                        bannerTitle: data.bannerTitle
                    }
                    this.setState({ bannerInfo, showBannerAlert: true })
                }

                // set zoom level in local storage to ensure it's applied for user
                localStorage.setItem('zoomLevel', data.zoomLevel)
                localStorage.setItem('orgLogoURL', data.organizationLogoUrl)
            })
        })
    }

    async populateState() {
        const [
            isAuthenticated,
            isAdmin,
            isSuperAdmin
        ] = await Promise.all([
            authService.isAuthenticated(),
            authService.isAdmin(),
            authService.isSuperAdmin()
        ])

        this.setState({
            isAuthenticated,
            isAdmin,
            isSuperAdmin
        })
    }

    handleCloseBannerAlert = () => {
        this.setState({ showBannerAlert: false })
    }

    async getOrganizations() {
        const token = await authService.getAccessToken()
        const response = await fetch(
            'api' + ApplicationPaths.Organizations + '/GetOrganizations',
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }
        )
        if (response.ok) {
            const data = await response.json()
            this.setState({ organizations: data })
        }
    }

    render() {
        const {
            isAuthenticated,
            organization,
            organizations,
            isAdmin,
            isSuperAdmin,
            canShowCustoms,
            canCreateShipment,
            canSearchShipment,
            canSeeBooking,
            canSeeOrders,
            canCreateOrder,
            canSeeAccounting,
            canSeeContainers,
            showBannerAlert,
            bannerInfo
        } = this.state

        const customLinkUrl =
            organization !== null &&
            organization.organizationCustomLinkUrl !== null &&
            organization.organizationCustomLinkUrl.length > 0
                ? organization.organizationCustomLinkUrl
                : null

        const customLinkName =
            organization !== null &&
            organization.organizationCustomLinkName !== null &&
            organization.organizationCustomLinkName.length > 0
                ? organization.organizationCustomLinkName
                : null

        const customLink2Url =
            organization !== null &&
            organization.organizationCustomLink2Url !== null &&
            organization.organizationCustomLink2Url.length > 0
                ? organization.organizationCustomLink2Url
                : null

        const customLink2Name =
            organization !== null &&
            organization.organizationCustomLink2Name !== null &&
            organization.organizationCustomLink2Name.length > 0
                ? organization.organizationCustomLink2Name
                : null

        const customLink3Url =
            organization !== null &&
            organization.organizationCustomLink3Url !== null &&
            organization.organizationCustomLink3Url.length > 0
                ? organization.organizationCustomLink3Url
                : null

        const customLink3Name =
            organization !== null &&
            organization.organizationCustomLink3Name !== null &&
            organization.organizationCustomLink3Name.length > 0
                ? organization.organizationCustomLink3Name
                : null

        const customLink4Url =
            organization !== null &&
            organization.organizationCustomLink4Url !== null &&
            organization.organizationCustomLink4Url.length > 0
                ? organization.organizationCustomLink4Url
                : null

        const customLink4Name =
            organization !== null &&
            organization.organizationCustomLink4Name !== null &&
            organization.organizationCustomLink4Name.length > 0
                ? organization.organizationCustomLink4Name
                : null

        const customLink5Url =
            organization !== null &&
            organization.organizationCustomLink5Url !== null &&
            organization.organizationCustomLink5Url.length > 0
                ? organization.organizationCustomLink5Url
                : null

        const customLink5Name =
            organization !== null &&
            organization.organizationCustomLink5Name !== null &&
            organization.organizationCustomLink5Name.length > 0
                ? organization.organizationCustomLink5Name
                : null

        var items = navItems(
            isAuthenticated,
            isAdmin || isSuperAdmin,
            canShowCustoms,
            canCreateShipment,
            canSearchShipment,
            canSeeBooking,
            canSeeOrders,
            canCreateOrder,
            canSeeAccounting,
            canSeeContainers,
            customLinkUrl,
            customLinkName,
            customLink2Url,
            customLink2Name,
            customLink3Url,
            customLink3Name,
            customLink4Url,
            customLink4Name,
            customLink5Url,
            customLink5Name
        )

        const backgroundColor =
            organization !== null && organization.colorThemeRGB !== null
                ? `${organization.colorThemeRGB}`
                : '#ffffff'
        const textColor =
            organization !== null && organization.colorThemeTextRGB !== null
                ? `${organization.colorThemeTextRGB}`
                : '#000000'
        const primarycolor =
            organization !== null && organization.colorThemePrimaryRGB !== null
                ? `${organization.colorThemePrimaryRGB}`
                : '#ffffff'
        const secodarycolor =
            organization !== null &&
            organization.colorThemeSecondaryRGB !== null
                ? `${organization.colorThemeSecondaryRGB}`
                : '#000000'
        const teritorycolor =
            organization !== null && organization.colorThemeTertiaryRGB !== null
                ? `${organization.colorThemeTertiaryRGB}`
                : '#000000'

        const customTheme = createTheme({
            overrides: {
                MUIDataTableToolbar: {
                    actions: {
                        display: 'flex',
                        flex: 'initial',
                        '& > span, & > button': {
                            order: 99
                        },
                        '& > span:last-child, & > button:last-child': {
                            order: 1
                        },
                        '& > span:nth-child(4), & > button:nth-child(4)': {
                            order: 2
                        }
                    }
                },
                MUIDataTableHeadCell: {
                    fixedHeaderCommon: {
                        backgroundColor: backgroundColor,
                        color: textColor
                    }
                },
                MuiDrawer: {
                    paper: {
                        background: backgroundColor,
                        borderRight: 'none !important'
                    }
                }
            },
            palette: {
                primary: {
                    main: backgroundColor,
                    contrastText: textColor
                },
                secondary: {
                    main: '#fff',
                    contrastText: backgroundColor,
                    border: backgroundColor
                },
                background: {
                    default: '#F9F9F5'
                }
            },
            zIndex: {
                drawer: 1100
            },
            root: {
                height: '100%'
            },

            textFieldForm: {
                width: '250px'
            },

            content: {
                minHeight: '600px'
            }
        })

        return (
            <div className="mt-66px">
                <Root theme={customTheme} config={standardLayoutPreset}>
                    {({ headerStyles, sidebarStyles }) => (
                        <div component="Drawer">
                            <CssBaseline />
                            <Header />

                            <Content
                                className="content"
                                style={{
                                    float: 'MuiDrawer-rootleft'
                                }}>
                                <NavBar
                                    organizations={
                                        isSuperAdmin ? organizations : []
                                    }
                                    logoutButtonTitle={isAuthenticated ? 'Logout' : ''}
                                />
                                <div className="p-40">
                                    {
                                        showBannerAlert &&
                                        <Alert
                                            onClose={this.handleCloseBannerAlert}
                                            icon={false}
                                            className="mb-20"
                                            style={{
                                                backgroundColor: bannerInfo.bannerBackgroundColor,
                                                color: bannerInfo.bannerTextColor
                                            }}
                                        >
                                            {
                                                bannerInfo.bannerTitle &&
                                                <Typography variant="h6">
                                                    {bannerInfo.bannerTitle}
                                                </Typography>
                                            }
                                            {bannerInfo.bannerText}
                                        </Alert>
                                    }
                                    <main className={customTheme.content}>
                                        {this.props.children}
                                    </main>
                                </div>
                            </Content>
                            <Sidebar>
                                {({ collapsed }) => (
                                    <div>
                                        <div
                                            className={sidebarStyles.container}
                                            style={{
                                                transition: '0.3s',
                                                backgroundColor: backgroundColor
                                            }}>
                                            {
                                                isAuthenticated &&
                                                <NestedList
                                                    textColor={textColor}
                                                    items={items}
                                                />
                                            }
                                        </div>
                                    </div>
                                )}
                            </Sidebar>
                        </div>
                    )}
                </Root>
                <FooterNav />
            </div>
        )
    }
}
