import '../custom.css'
import React, { PureComponent } from 'react'

import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'
import authService from './api-authorization/AuthorizeService'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    textFieldForm: {
        width: theme.textFieldForm.width
    },
    link: {
        color: 'blue'
    }
})

class DashboardDetails extends PureComponent {
    constructor(props) {
        super(props)
        let dashboardId = props.match.params.dashboardId
        let groupId = props.match.params.groupId
        if (
            dashboardId == 'null' ||
            dashboardId == undefined ||
            dashboardId == '' ||
            groupId == 'null' ||
            groupId == undefined ||
            groupId == ''
        ) {
            this.goBack()
        }

        this.state = {
            dashboardData: {},
            embedUrl: '',
            accessToken: '',
            dashboardId: props.match.params.dashboardId,
            groupId: props.match.params.groupId
        }
    }

    goBack = () => {
        this.props.history.goBack()
    }

    componentDidMount() {
        this.populateDashboardData()
    }

    //to get the dashboard(access_token, embed_url) fotr display the dashboard
    async populateDashboardData() {
        const token = await authService.getAccessToken()
        const { dashboardId, groupId } = this.state

        const user = await authService.getUser()
        let userId = ''
        if (user) {
            userId = user.sub
        } else {
            userId = null
        }

        let data = {
            Id: dashboardId,
            GroupId: groupId,
            UserId: userId
        }
        await fetch('api/bidashboard/GetDashboardDetails', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                }
            })

            .then(d => {
                //const data = d.json()
                this.setState({
                    dashboardData: d
                })
            })
            .catch(error => {
                this.setState({
                    dashboardData: {
                        embedUrl: '',
                        token: '',
                        dashboardId: '',
                        dashboardName: ''
                    }
                })
            })
    }

    render() {
        const { dashboardData, embedUrl, accessToken, dashboardId } = this.state
        return (
            <div class="custom-graph mt-3">
                <h3 class="mb-3">{dashboardData.dashboardName}</h3>
                <PowerBIEmbed
                    embedConfig={{
                        type: 'dashboard', // ;lmSupported types: report, dashboard, tile, visual and qna
                        id: dashboardData.dashboardId,
                        embedUrl: dashboardData.embedUrl, //test-bi.psabg.com
                        accessToken: dashboardData.token,
                        tokenType: models.TokenType.Embed,
                        pageView: 'fitToWidth',
                        settings: {
                            panes: {
                                filters: {
                                    expanded: false,
                                    visible: false
                                }
                            },
                            background: models.BackgroundType.Transparent
                        }
                    }}
                    eventHandlers={
                        new Map([
                            [
                                'loaded',
                                function() {
                                    console.log('Dashboard loaded')
                                }
                            ],
                            [
                                'rendered',
                                function() {
                                    console.log('Dashboard rendered')
                                }
                            ],
                            [
                                'error',
                                function(event) {
                                    console.log(event.detail)
                                }
                            ]
                        ])
                    }
                    cssClassName={'report-style-class'}
                    getEmbeddedComponent={embeddedDashboard => {
                        window.dashboard = embeddedDashboard
                    }}
                />
            </div>
        )
    }
}

export default withStyles(styles)(DashboardDetails)
