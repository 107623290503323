import Papa from 'papaparse';
import moment from 'moment'
 
const exportCsv = (data, filename) => {
    const csv = Papa.unparse({ data });
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
 
export const prepareCsvExport = (columns, data, organization, exportFileName) => {

    // first need to remove any hidden columns from the export
    columns = columns.filter(col => !col.hidden);

    // need to iterate through data and apply date format for any date columns
    const parsedData = data.map(row => {
        columns.forEach(col => {
            if (col.type === "Date") {
                if (col.sortField) {
                    if (row[col.sortField]) {
                        row[col.sortField] = moment(row[col.sortField]).format(organization.exportDateFormat);
                    }
                }
            }
        })
        return row;
    })
 
    const parsedDataNewKeys = parsedData.map(row => {
        const newKeyObject = {}
        columns.forEach(col => {
            newKeyObject[col.title] = row[col.sortField] || row[col.field]
        })
        return newKeyObject
    })
 
    return exportCsv(parsedDataNewKeys, exportFileName + '.csv');
}