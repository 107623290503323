import FlightIcon from '@material-ui/icons/Flight'
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import { Polyline, OverlayView, OverlayViewF } from '@react-google-maps/api'
import React from 'react'
import MapBadge from './MapBadge'
import { CardMedia } from '@mui/material'
import { LoadScript, GoogleMap } from '@react-google-maps/api'
import { checkValidPosition } from '../../utils/map'
import MapPolylines from '../GoogleMaps/MapPolylines'

const arrowPath = 'M2,2 L5,8 L8,2'
const dashPath = 'M 0,-1 0,1'

var polylineLineSymbol = {
    path: dashPath,
    strokeOpacity: 1,
    scale: 2
}

const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2)
})

const getPortOverlay = (port, position) => {
    return (
        <OverlayViewF
            position={position}
            getPixelPositionOffset={getPixelPositionOffset}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
            <MapBadge badgeContent={port} />
        </OverlayViewF>
    )
}

const getCurrentLocationOverlay = (position) => {
    return (
        <OverlayViewF
            position={position}
            zIndex={99}
            getPixelPositionOffset={getPixelPositionOffset}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                {getVesselCurrentLocationIcon()}
        </OverlayViewF>
    )
}

const getBulkPortMarkers = (uniquePorts) => {
    return (
        uniquePorts.map(port => {
            return renderMarker(port.portName, port.port)
        })
    )
}

const getMapOverlay = (uniquePorts, uniqueItems) => {
    return (
        <>
            {getBulkPortMarkers(uniquePorts)}
            <MapPolylines uniqueRoutes={uniqueItems} renderPolyline={renderPolyline} getLatLng={getLatLng} />
        </>
    )
}

export function renderMarker(badgeContent, position) {
    const positionObj = getLatLngFromString(position)
    return (
        checkValidPosition(positionObj) ? ( getPortOverlay(badgeContent, positionObj) ) : null
    )
}

export function renderMap(items) {

    const mapContainerStyle = {
        position: 'relative',
        height: '100%',
        width: '100%'
    }

    // build a property we can use to look for duplicate locations
    const itemsLocMap = items.map(item => ({
        ...item,
        locCode:
            item.geoLocationOrigin +
            item.geoLocationDestination 
    }))

    // build unique location array to speed up map render (markers)
    const uniqueItems = itemsLocMap.filter(
        (item, index, self) =>
            index ===
            self.findIndex(
                t =>
                    t.locCode === item.locCode &&
                    t.geoLocationOrigin !== '' &&
                    t.geoLocationDestination !== ''
            )
    )

    // build unique list of origin and destination ports with names
    let uniquePorts = []
    uniqueItems.forEach(item => {
        if (item.geoLocationOrigin !== '') {
            if (uniquePorts.find(p => p.port === item.geoLocationOrigin) === undefined) {
                uniquePorts.push({
                    port: item.geoLocationOrigin,
                    portName: item.originPortCode || item.origin
                })
            }
        }
        if (item.geoLocationDestination !== '') {
            if (uniquePorts.find(p => p.port === item.geoLocationDestination) === undefined) {
                uniquePorts.push({
                    port: item.geoLocationDestination,
                    portName: item.destinationPortCode || item.destination
                })
            }
        }
    })

    return (
        <LoadScript
            id="script-loader"
            googleMapsApiKey="AIzaSyChJdyVnqwtrE_-7mUBGQNRUwa3oJFrJsE">
            <GoogleMap
                id="InfoBox-example"
                mapContainerStyle={mapContainerStyle}
                zoom={1}
                options={{ streetViewControl: false }}
                onLoad={map => {
                    const bounds = new window.google.maps.LatLngBounds()
                    
                    bounds.extend(
                        new window.google.maps.LatLng(
                            42.2611071,
                            -100.0855555
                        )
                    )
                    var listener = window.google.maps.event.addListener(
                        map,
                        'idle',
                        function() {
                            if (map.getZoom() > 16) map.setZoom(3)
                            window.google.maps.event.removeListener(
                                listener
                            )
                        }
                    )

                    map.fitBounds(bounds)
                }}>
                {
                    uniquePorts.length > 0 && (
                        getMapOverlay(uniquePorts, uniqueItems)
                    )
                }
            </GoogleMap>
        </LoadScript>
    )
}

const renderDetailMapInner = (itemIndex, item, itemextra, organization, height) => {

    if (item === null) {
        return null
    }

    const currLoc = getLatLng(itemextra, 'geoLocationCurrentLocation')
    const origin = getLatLng(item, 'geoLocationOrigin') ?? getLatLng(itemextra, 'geoLocationOrigin')
    const destination = getLatLng(item, 'geoLocationDestination') ?? getLatLng(itemextra, 'geoLocationDestination')
    const portOfLoading = getLatLng(itemextra, 'geoLocationPortOfLoading') ?? getLatLng(item, 'geoLocationPortOfLoading')
    const portOfDischarge = getLatLng(itemextra, 'geoLocationPortOfDischarge') ?? getLatLng(item, 'geoLocationPortOfDischarge')

    // default map
    return (
        <GoogleMap
            id="connectcast-portal-map"
            mapContainerStyle={{
                position: 'relative',
                width: '100%',
                height: height
            }}
            zoom={6}
            options={{
                streetViewControl: false
            }}
            onLoad={map => {
                const bounds = new window.google.maps.LatLngBounds()
                if (bounds && checkValidPosition(origin) && checkValidPosition(destination)) {
                    bounds.extend(
                        new window.google.maps.LatLng(
                            origin.lat,
                            origin.lng
                        )
                    )
                    bounds.extend(
                        new window.google.maps.LatLng(
                            destination.lat,
                            destination.lng
                        )
                    )
                    map.fitBounds(bounds)
                }
            }}>
            {item !== null && renderMarkers(itemIndex, item, itemextra, origin, destination, portOfLoading, portOfDischarge, currLoc)}
        </GoogleMap>
    )
}

export const renderDetailMap = (itemIndex, item, itemextra, organization, height, source = 'page') => {
    return (
        source === 'page' ? (
            <CardMedia>
                <LoadScript
                    id="script-loader"
                    googleMapsApiKey="AIzaSyChJdyVnqwtrE_-7mUBGQNRUwa3oJFrJsE">
                    {renderDetailMapInner(itemIndex, item, itemextra, organization, height)}
                </LoadScript>
            </CardMedia>
        ) : (
            <CardMedia>
                {renderDetailMapInner(itemIndex, item, itemextra, organization, height)}
            </CardMedia>
        )    
    )
}

const renderMarkers = (
    index,
    shipment,
    shipmentExtra,
    origin,
    destination,
    portOfLoading,
    portOfDischarge,
    currentLocation = null
) => {

    var trackedArray = []
    var originToTrackedArray = []
    var trackedToDestinationArray = []

    if (!portOfLoading) {
        portOfLoading = origin
    }

    if (!portOfDischarge) {
        portOfDischarge = destination
    }

    if (shipmentExtra?.vesselCoordinates && shipmentExtra?.vesselCoordinates.length > 0) {
        trackedArray = shipmentExtra.vesselCoordinates

        // need to add values to the other arrays
        if (checkValidPosition(origin)) {
            originToTrackedArray.push(origin)
        }

        if (checkValidPosition(portOfLoading)) {
            originToTrackedArray.push(portOfLoading)
        }

        // add the first tracked position to the array
        originToTrackedArray.push(trackedArray[trackedArray.length - 1])

        // reverse
        originToTrackedArray = originToTrackedArray.reverse()

        if (checkValidPosition(destination)) {
            trackedToDestinationArray.push(destination)
        }

        if (checkValidPosition(portOfDischarge)) {
            trackedToDestinationArray.push(portOfDischarge)
        }

        // add the last tracked position to the array
        trackedToDestinationArray.push(trackedArray[0])

    } else {
        if (checkValidPosition(origin)) {
            trackedArray.push(origin)
        }

        if (checkValidPosition(portOfLoading)) {
            trackedArray.push(portOfLoading)
        }

        if (checkValidPosition(currentLocation)) {
            trackedArray.push(currentLocation)
        }

        if (checkValidPosition(portOfDischarge)) {
            trackedArray.push(portOfDischarge)
        }

        if (checkValidPosition(destination)) {
            trackedArray.push(destination)
        }
    }

    // remove any duplicate coordinates
    // array = array.filter(
    //     (v, i, a) => a.findIndex(t => t.lat === v.lat && t.lng === v.lng) === i
    // )

    const options = {
        fillColor: '#757575',
        fillOpacity: 0,
        strokeColor: '#757575',
        strokeOpacity: 0,
        path: trackedArray,
        zIndex: 1,
        geodesic: true,
        icons: [
            {
                icon: polylineLineSymbol,
                offset: '0',
                repeat: '10px'
            }
        ]
    }

    if (
        (checkValidPosition(origin) || checkValidPosition(portOfLoading)) &&
        (checkValidPosition(destination) || checkValidPosition(portOfDischarge))
    ) {
        if (currentLocation == null) {
            return (
                <div key={index}>
                    <Polyline path={trackedArray} options={{ options }} />
                    {
                        checkValidPosition(origin) &&
                            getPortOverlay(shipment.originPortCode || shipment.origin || shipmentExtra?.originPortCode, origin)
                    }
                    {
                        checkValidPosition(portOfLoading) && portOfLoading !== origin &&
                            getPortOverlay(shipmentExtra?.portOfLoading || shipment?.portOfLoading || shipment?.loadPort || shipmentExtra?.loadPort, portOfLoading)
                    }
                    {
                        checkValidPosition(destination) &&
                            getPortOverlay(shipment.destinationPortCode || shipment.destination || shipmentExtra?.destinationPortCode, destination)
                    }
                    {
                        checkValidPosition(portOfDischarge) && portOfDischarge !== destination &&
                            getPortOverlay(shipmentExtra?.portOfDischarge || shipment?.portOfDischarge || shipment?.dischargePort || shipmentExtra?.dischargePort, portOfDischarge)
                    }
                </div>
            )
        } else {
            return (
                <div key={index}>
                    <Polyline path={trackedArray} options={{ options }} />
                    <Polyline path={originToTrackedArray} options={{ 
                        fillColor: '#0470cf',
                        fillOpacity: 0,
                        strokeColor: '#0470cf',
                        strokeOpacity: 0,
                        zIndex: 1,
                        icons: [
                            {
                                icon: polylineLineSymbol,
                                offset: '0',
                                repeat: '10px'
                            }
                        ]
                     }} />
                    <Polyline path={trackedToDestinationArray} options={{ 
                        fillColor: '#0470cf',
                        fillOpacity: 0,
                        strokeColor: '#0470cf',
                        strokeOpacity: 0,
                        zIndex: 1,
                        icons: [
                            {
                                icon: polylineLineSymbol,
                                offset: '0',
                                repeat: '10px'
                            }
                        ]
                     }} />
                    {
                        checkValidPosition(origin) &&
                        getPortOverlay(shipment.originPortCode || shipment.origin || shipmentExtra?.originPortCode, origin)
                    }
                    {
                        checkValidPosition(portOfLoading) && portOfLoading !== origin &&
                        getPortOverlay(shipmentExtra?.portOfLoading || shipment?.portOfLoading || shipment?.loadPort || shipmentExtra?.loadPort, portOfLoading)
                    }
                    {
                        checkValidPosition(currentLocation) &&
                        getCurrentLocationOverlay(currentLocation)
                    }
                    {
                        checkValidPosition(destination) &&
                        getPortOverlay(shipment.destinationPortCode || shipment.destination || shipmentExtra?.destinationPortCode, destination)
                    }
                    {
                        checkValidPosition(portOfDischarge) && portOfDischarge !== destination &&
                        getPortOverlay(shipmentExtra?.portOfDischarge || shipment?.portOfDischarge || shipment?.dischargePort || shipmentExtra?.dischargePort, portOfDischarge)
                    }
                </div>
            )
        }
    } else {
        if (checkValidPosition(currentLocation)) {
            return (
                checkValidPosition(currentLocation) &&
                getCurrentLocationOverlay(currentLocation)
            )
        }
        return <div key={index}>&nbsp;</div>
    }
}

export function renderPolyline(
    index,
    shipment,
    shipmentExtra,
    origin,
    destination,
    portOfLoading,
    portOfDischarge,
    currentLocation = null
) {

    var array = []

    if (shipmentExtra?.vesselCoordinates) {
        array = shipmentExtra.vesselCoordinates
    } else {
        if (checkValidPosition(origin)) {
            array.push(origin)
        }

        if (checkValidPosition(portOfLoading)) {
            array.push(portOfLoading)
        }

        if (checkValidPosition(currentLocation)) {
            array.push(currentLocation)
        }

        if (checkValidPosition(portOfDischarge)) {
            array.push(portOfDischarge)
        }

        if (checkValidPosition(destination)) {
            array.push(destination)
        }
    }

    const options = {
        fillColor: '#757575',
        fillOpacity: 0,
        strokeColor: '#757575',
        strokeOpacity: 0,
        path: array,
        zIndex: 1,
        geodesic: true,
        icons: [
            {
                icon: polylineLineSymbol,
                offset: '0',
                repeat: '10px'
            }
        ]
    }

    if (
        (checkValidPosition(origin) || checkValidPosition(portOfLoading)) &&
        (checkValidPosition(destination) || checkValidPosition(portOfDischarge))
    ) {
        return <Polyline path={array} options={{ options }} />
    } else {
        return null
    }
}

export function getLatLng(shipment, key) {
    if (!shipment) {
        return null
    }

    const locationGeoCoordinates = shipment[key]
    if (!locationGeoCoordinates) {
        return null
    }
    
    const array = locationGeoCoordinates.split(',')
    return { lat: parseFloat(array[0]), lng: parseFloat(array[1]) }
}

function getLatLngFromString(positionString) {
    if (!positionString) {
        return null
    }

    const array = positionString.split(',')
    return { lat: parseFloat(array[0]), lng: parseFloat(array[1]) }
}

export function getIconForShipment(shipment) {
    const shipmentMode = shipment.transportMode ?? shipment.mode
    if (shipmentMode) {
        if (shipmentMode == 'AIR') {
            return <FlightIcon color="action" />
        } else if (shipmentMode == 'ROA') {
            return <LocalShippingIcon color="action" />
        } else {
            return <DirectionsBoatIcon color="action" />
        }
    }
}

const getVesselCurrentLocationIcon = () => {
    return <DirectionsBoatIcon sx={{ backgroundColor: 'white', color: 'black', border: '1px white solid', borderRadius: '5px' }} />
}

