export function phoneNumberValidation(data) {
    //+1-541-754-3010
    let result = data.match(
        /^\+?([0-9]{1,2})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
    )
    return result !== null ? true : false
}
export function emailValidation(data) {
    let result = data.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    return result !== null ? true : false
}
