import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';
import Room from '@material-ui/icons/Room';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(2),
    },
  },
}));

const defaultProps = {
  color: 'primary',
  children: <Room />,
};

export default function MapBadge(props) {

    const classes = useStyles();

    return (
    <div className={classes.root}>
        <Badge badgeContent={props.badgeContent} {...defaultProps} />
    </div>
    );
}
