import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CardTitle from './CardTitle'
import { Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { getOrgNameFromOrganization } from '../../utils/organizations'

const classes = makeStyles(theme => ({}))

export default function OrderInfoCard({ item, organization }) {
    const theme = useTheme()

    useEffect(() => {}, [])

    return (
        <div className="p-0 pl-3 data-data-info">
            <h5 class="text-Curious-blue my-2 font-14">Order Info</h5>

            <Grid container spacing={1} className={theme.bgColorSection}>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={0}>
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                Order #
                            </Typography>
                            <p class="mb-0 font-16  font-nutosans-bold text-black">
                                {(item !== null && item.orderNumber) || '-'}
                            </p>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                Order # Split
                            </Typography>
                            <p class="mb-0 font-14  font-nutosans-bold text-black">
                                {item.orderNumberSplit}
                            </p>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                               {
                                    getOrgNameFromOrganization(organization) === 'HAVI' ? (
                                        'Customer PO #'
                                    ) : (
                                        ' '
                                    )
                               } 
                            </Typography>
                            <p class="mb-0 font-14  font-nutosans-bold text-black">
                                {
                                    getOrgNameFromOrganization(organization) === 'HAVI' ? (
                                        item.customString1
                                    ) : (
                                        ' '
                                    )
                               } 
                            </p>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                Order Date
                            </Typography>
                            <p class="mb-0 font-14  font-nutosans-bold text-black">
                                {(item !== null && item.orderDateString) || '-'}
                            </p>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                Transport Mode
                            </Typography>
                            <p class="mb-0 font-14  font-nutosans-bold text-black">
                                {(item !== null && item.orderTransportMode) ||
                                    '-'}
                            </p>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                {' '}
                            </Typography>
                            <p class="mb-0 font-14  font-nutosans-bold text-black">
                                {' '}
                            </p>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                Supplier
                            </Typography>
                            <p class="mb-0 font-14  font-nutosans-bold text-black">
                                {(item !== null && item.supplierFullName) ||
                                    '-'}
                            </p>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                Buyer
                            </Typography>
                            <p class="mb-0 font-14  font-nutosans-bold text-black">
                                {(item !== null && item.buyerFullName) || '-'}
                            </p>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                {' '}
                            </Typography>
                            <p class="mb-0 font-14  font-nutosans-bold text-black">
                                {' '}
                            </p>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                Origin
                            </Typography>
                            <p class="mb-0 font-14  font-nutosans-bold text-black">
                                {(item !== null && item.originName) || '-'}
                            </p>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                Destination
                            </Typography>
                            <p class="mb-0 font-14  font-nutosans-bold text-black">
                                {(item !== null && item.destinationName) || '-'}
                            </p>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                {' '}
                            </Typography>
                            <p class="mb-0 font-14  font-nutosans-bold text-black">
                                {' '}
                            </p>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                {(item.exWorkRequiredByDateString !== null &&
                                    'Required ExWorks Date') ||
                                    'Estimated ExWorks Date'}
                            </Typography>
                            <p class="mb-0 font-14  font-nutosans-bold text-black">
                                {(item !== null &&
                                    item.exWorkRequiredByDateString) ||
                                    item.dateExFactoryEstString ||
                                    '-'}
                            </p>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                {(item.deliveryRequiredByDateString !== null &&
                                    'Required Delivery Date') ||
                                    'Estimated Delivery Date'}
                            </Typography>
                            <p className="mb-0 font-14  font-nutosans-bold text-black">
                                {(item !== null &&
                                    item.deliveryRequiredByDateString) ||
                                    item.dateDeliveredEstString ||
                                    '-'}
                            </p>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                {' '}
                            </Typography>
                            <p class="mb-0 font-14  font-nutosans-bold text-black">
                                {' '}
                            </p>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                Estimated Lead Time
                            </Typography>
                            <p className="mb-0 font-14  font-nutosans-bold text-black">
                                {(item.leadTime &&
                                    item.leadTime + ' days') ||
                                    '-'}
                            </p>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                Service Level
                            </Typography>
                            <p class="mb-0 font-14  font-nutosans-bold text-black">
                                {(item !== null && item.serviceLevel) || '-'}
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
