export const ApplicationName = 'PSAShipping'

export const QueryParameterNames = {
    ReturnUrl: 'returnUrl',
    Message: 'message'
}

export const AppInfoActions = {
    Info: 'info'
}

export const CustomActions = {
    Index: '/customs'
}

export const AccountingActions = {
    Index: '/accounting'
}

export const BookingActions = {
    Index: '/bookings'
}

export const OrderActions = {
    Index: '/orders',
    OrderLines: '/orderlines',
    Create: '/orders/create',
}

export const ShipmentActions = {
    Index: '/shipments',
    Create: '/shipments/create',
    PackLines: '/packlines',
}

export const ContainerActions = {
    Index: '/containers',
    Delivery: '/containers/delivery',
    Pickup: '/containers/pickup',
}

export const AccountActions = {
    Index: '/account',
    Password: '/password'
}

export const ClientActions = {
    Index: '/clients'
}

export const DataMapping = {
    Index: '/admin/data_mapping',
    Create: '/admin/data_mapping/new'
}

export const UserActions = {
    Index: '/users',
    Admin: '/admin/users',
    Org: '/clientusers'
}

export const OrganizationActions = {
    Index: '/organizations'
}

export const LogoutActions = {
    LogoutCallback: 'logout-callback',
    Logout: 'logout',
    LoggedOut: 'logged-out'
}

export const LoginActions = {
    Login: 'login',
    LoginCallback: 'login-callback',
    LoginFailed: 'login-failed',
    Profile: 'profile',
    Register: 'register'
}
export const ReportActions = {
    Index: '/powerbireports'
}

const prefix = '/authentication'
const appInfoPrefix = '/app'

export const ApplicationPaths = {
    DefaultLoginRedirectPath: '/',
    ApiAuthorizationClientConfigurationUrl: `/_configuration/${ApplicationName}`,
    ApiAuthorizationPrefix: prefix,
    Login: `${prefix}/${LoginActions.Login}`,
    LoginFailed: `${prefix}/${LoginActions.LoginFailed}`,
    LoginCallback: `${prefix}/${LoginActions.LoginCallback}`,
    Register: `${prefix}/${LoginActions.Register}`,
    Profile: `${prefix}/${LoginActions.Profile}`,
    LogOut: `${prefix}/${LogoutActions.Logout}`,
    LoggedOut: `${prefix}/${LogoutActions.LoggedOut}`,
    LogOutCallback: `${prefix}/${LogoutActions.LogoutCallback}`,
    IdentityRegisterPath: '/Identity/Account/Register',
    IdentityManagePath: '/Identity/Account/Manage',
    AppInfo: `${appInfoPrefix}/${AppInfoActions.Info}`,
    Customs: `${CustomActions.Index}`,
    Accounting: `${AccountingActions.Index}`,
    Bookings: `${BookingActions.Index}`,
    Orders: `${OrderActions.Index}`,
    CreateOrder: `${OrderActions.Create}`,
    OrderLines: `${OrderActions.OrderLines}`,
    Shipments: `${ShipmentActions.Index}`,
    CreateShipment: `${ShipmentActions.Create}`,
    CreateShipmentPackLines: `${ShipmentActions.PackLines}`,
    Account: `${AccountActions.Index}`,
    Password: `${AccountActions.Password}`,
    Clients: `${ClientActions.Index}`,
    Organizations: `${OrganizationActions.Index}`,
    Users: `${UserActions.Index}`,
    OrgUsers: `${UserActions.Org}`,
    DataMappingIndex: `${DataMapping.Index}`,
    DataMappingCreate: `${DataMapping.Create}`,
    AdminUsers: `${UserActions.Admin}`,
    Reports: `${ReportActions.Index}`,
    Delivery: `${ContainerActions.Delivery}`,
    Pickup: `${ContainerActions.Pickup}`,
    Container: `${ContainerActions.Index}`,
}
