import React from 'react'
import { PureComponent } from 'react'
import { Route, Redirect } from 'react-router-dom'
import {
    ApplicationPaths,
    QueryParameterNames
} from './ApiAuthorizationConstants'
import authService from './AuthorizeService'
import { trackActivity } from '../../utils/interactions'

export default class AdminAuthorizeRoute extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            ready: false,
            authenticated: false
        }
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() =>
            this.authenticationChanged()
        )
        this.populateAuthenticationState()
    }

    componentWillUnmount() {
        if (this._subscription) {
            authService.unsubscribe(this._subscription)
        }
    }

    render() {
        const { ready, authenticated } = this.state
        const redirectUrl = `${ApplicationPaths.Login}?${
            QueryParameterNames.ReturnUrl
        }=${encodeURI(window.location.href)}`
        if (!ready) {
            return <div />
        } else {
            const { component: PureComponent, ...rest } = this.props
            return (
                <Route
                    {...rest}
                    render={props => {
                        if (authenticated) {
                            trackActivity(props.location.pathname)
                            return <PureComponent {...props} />
                        } else {
                            return <Redirect to={'/'} />
                        }
                    }}
                />
            )
        }
    }

    async populateAuthenticationState() {
        // check if authenticated then if admin or super admin
        const authToken = await authService.getAccessToken()
        const authenticated = !!authToken
        const admin = await authService.isAdmin()
        const superAdmin = await authService.isSuperAdmin()
        if (authenticated && (admin || superAdmin)) {
            this.setState({ ready: true, authenticated: true })
        } else {
            this.setState({ ready: true, authenticated: false })
        }
    }

    async authenticationChanged() {
        this.setState({ ready: false, authenticated: false })
        await this.populateAuthenticationState()
    }
}
