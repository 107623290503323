import React, { PureComponent } from 'react'
import authService from './api-authorization/AuthorizeService'
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants'
import { Container, Grid, CardContent } from '@material-ui/core'
import { CollapseBtn, CollapseIcon } from '@mui-treasury/layout'
import { FormControl, Button, IconButton } from '@material-ui/core'
import { Input, InputAdornment, InputLabel } from '@material-ui/core'
import { Divider, FormHelperText, Typography } from '@material-ui/core'
import { green, red } from '@material-ui/core/colors'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'
import Paper from '@material-ui/core/Paper'
import CloseIcon from '@material-ui/icons/Close'
import AlertMessage from './shared/Alert'
import CardTitle from './shared/CardTitle'
import Icon from '@material-ui/core/Icon'
import { withStyles } from '@material-ui/core/styles'
import { NavLink } from 'react-router-dom'

const styles = theme => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    textFieldForm: {
        width: theme.textFieldForm.width
    }
})

const classes = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2)
    }
}))
const accountPath = { pathname: `${ApplicationPaths.Account}` }

class Password extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            isPosting: false,
            saved: false,
            organization: null,
            rules: {
                minLen: 8,
                minNumbers: 1
            },
            current: {
                value: '',
                show: false,
                isValid: true,
                errorText: ''
            },
            incoming: {
                value: '',
                show: false,
                isValid: true,
                hasMinLen: false,
                hasMinNumber: false,
                errorText: ''
            },
            confirm: {
                value: '',
                show: false,
                isValid: true,
                errorText: ''
            },
            isValidForm: false,

            alert: {
                open: false,
                success: false,
                message: ''
            }
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    componentWillUnmount() {
        if (this._asyncRequest) {
            this._asyncRequest.cancel()
        }
    }

    handleMouseDown = event => {
        event.preventDefault()
    }

    handleClickShowPassword = field => {
        switch (field) {
            case 'current':
                this.setState(prevState => {
                    let current = Object.assign({}, prevState.current)
                    current.show = !current.show
                    return { current }
                })
                break

            case 'incoming':
                this.setState(prevState => {
                    let incoming = Object.assign({}, prevState.incoming)
                    incoming.show = !incoming.show
                    return { incoming }
                })
                break

            case 'confirm':
                this.setState(prevState => {
                    let confirm = Object.assign({}, prevState.confirm)
                    confirm.show = !confirm.show
                    return { confirm }
                })
                break

            default:
                break
        }
    }
    componentDidMount() {
        this.populateConfig()
    }
    async populateConfig() {
        const response = await fetch('api' + ApplicationPaths.AppInfo)
        const data = await response.json()
        this.setState({ organization: data })
    }
    handleChange(event, field, label, isRequired) {
        const rules = this.state.rules

        this.setState(prevState => {
            let alerts = Object.assign({}, prevState.alerts)
            alerts.showSuccess = false
            alerts.showFail = false
            return { alerts }
        })

        let value = event.target.value
        let isValidField = true
        let isValidForm = false
        let msgError = ''

        if (value.length === 0 && isRequired) {
            isValidField = false
            isValidForm = false
            msgError = label + ' cannot be empty.'
        }

        switch (field) {
            case 'current':
                this.setState(prevState => {
                    let current = Object.assign({}, prevState.current)
                    current.value = value
                    current.isValid = isValidField
                    current.errorText = !isValidField ? msgError : ''
                    return { current }
                })

                if (isValidField && this.state.confirm.isValid)
                    isValidForm = true
                break

            case 'incoming':
                let hasMinLen = false
                let hasNumber = false
                let hasLowercase = false
                let hasUppercase = false

                if (isValidField) {
                    if (value.length < rules.minLen) {
                        isValidField = false
                        msgError =
                            label +
                            ' must have at least (' +
                            rules.minLen +
                            ') characters.'
                    } else {
                        hasMinLen = true
                    }

                    hasNumber = /\d/.test(value)

                    if (!hasNumber) {
                        isValidField = false
                        msgError = label + ' must contain at least 1 number.'
                    }

                    hasLowercase = /[a-z]/.test(value)

                    if (!hasLowercase) {
                        isValidField = false
                        msgError =
                            label + ' must contain at least 1 lowercase letter.'
                    }

                    hasUppercase = /[A-Z]/.test(value)

                    if (!hasUppercase) {
                        isValidField = false
                        msgError =
                            label + ' must contain at least 1 uppercase letter.'
                    }

                    const confirmPassword = this.state.confirm.value || ''
                    const isValidCurrentPassword = this.state.current.isValid

                    let errorTextConfirmPassword = ''

                    if (confirmPassword.length > 0) {
                        let valid = true

                        if (value !== confirmPassword) {
                            valid = false
                        } else {
                            isValidField = true
                            isValidForm = isValidCurrentPassword
                        }

                        errorTextConfirmPassword = valid
                            ? ''
                            : 'Confirm Password do not match. '

                        //this setState is maintained so as not to delete the previous message from the Confirm field
                        this.setState(prevState => {
                            let confirm = Object.assign({}, prevState.confirm)
                            confirm.isValid = valid
                            confirm.errorText = errorTextConfirmPassword
                            return { confirm }
                        })
                    }
                }
                this.setState(prevState => {
                    let incoming = Object.assign({}, prevState.incoming)
                    incoming.value = value
                    incoming.hasMinLen = hasMinLen
                    incoming.hasNumber = hasNumber
                    incoming.hasLowercase = hasLowercase
                    incoming.hasUppercase = hasUppercase
                    incoming.isValid = isValidField
                    incoming.errorText = !isValidField ? msgError : ''
                    return { incoming }
                })
                break

            case 'confirm':
                const newPassword = this.state.incoming.value || ''
                const isValidCurrentPassword = this.state.current.isValid

                if (isValidField && newPassword.length > 0) {
                    if (value !== newPassword) {
                        isValidField = false
                        msgError = label + ' do not match. '
                    } else {
                        isValidForm = isValidCurrentPassword
                    }
                }

                this.setState(prevState => {
                    let confirm = Object.assign({}, prevState.confirm)
                    confirm.value = value
                    confirm.isValid = isValidField
                    confirm.errorText = !isValidField ? msgError : ''
                    return { confirm }
                })

                break
            default:
                break
        }

        this.setState({ isValidForm: isValidForm })
    }

    handleSubmit = async event => {
        const { isValidForm, current, confirm } = this.state
        event.preventDefault()

        if (isValidForm) {
            let password = {
                currentPassword: current.value,
                newPassword: confirm.value
            }
            this.UpdatePassword(password)
        }
    }

    handleCloseAlert() {
        this.setState(prevState => {
            let alert = Object.assign({}, prevState.alert)
            alert.open = false
            alert.success = false
            alert.message = ''
            return { alert }
        })
    }

    renderInput = (id, label, placeholder, obj) => {
        const { classes } = this.props
        return (
            <Grid item xs={12}>
                <FormControl>
                    <InputLabel htmlFor={id}>{label}</InputLabel>
                    <Input
                        className={classes.textFieldForm}
                        id={id}
                        placeholder={placeholder}
                        autoComplete="on"
                        required
                        type={obj.show ? 'text' : 'password'}
                        value={obj.value || ''}
                        onChange={e => this.handleChange(e, id, label, true)}
                        error={!obj.isValid}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={e =>
                                        this.handleClickShowPassword(id)
                                    }
                                    onMouseDown={this.handleMouseDown}>
                                    {obj.show ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText
                        id={id + '-helper-text'}
                        error={!obj.isValid}>
                        {obj.errorText}
                    </FormHelperText>
                </FormControl>
            </Grid>
        )
    }

    renderAlert = () => {
        const alert = this.state.alert
        return alert.open ? (
            <div className="w-100">
                <AlertMessage
                    open={alert.open}
                    success={alert.success}
                    message={alert.message}
                    onClose={() => this.handleCloseAlert()}
                />
            </div>
        ) : null
    }

    render() {
        const { current, incoming, confirm, organization, isPosting, isLoading } = this.state
        const backgroundColor =
            organization !== null && organization.colorThemeRGB !== null
                ? `${organization.colorThemeRGB}`
                : '#ffffff'
        const textColor =
            organization !== null && organization.colorThemeTextRGB !== null
                ? `${organization.colorThemeTextRGB}`
                : '#000000'
        const primarycolor =
            organization !== null && organization.colorThemePrimaryRGB !== null
                ? `${organization.colorThemePrimaryRGB}`
                : '#ffffff'
        const secodarycolor =
            organization !== null &&
            organization.colorThemeSecondaryRGB !== null
                ? `${organization.colorThemeSecondaryRGB}`
                : '#000000'
        const teritorycolor =
            organization !== null && organization.colorThemeTertiaryRGB !== null
                ? `${organization.colorThemeTertiaryRGB}`
                : '#000000'
        return (
            <div>
                <CardContent className="p-0">
                    <Paper className={classes.paper}>
                        <form
                            onSubmit={this.handleSubmit}
                            noValidate
                            autoComplete="off">
                            <Grid container spacing={1}>
                                <Grid
                                    container
                                    xs={12}
                                    className="mb-0"
                                    spacing={0}
                                    class="mb-4 w-100 d-flex mobile-mode-no-flex">
                                    <Grid
                                        item
                                        lg={3}
                                        md={3}
                                        sm={12}
                                        xs={12}
                                        direction="column"
                                        spacing={4}>
                                        <div class="inner border-right-separator p-34 position-relative">
                                            <h6 className="xs-text-center text-sliversweetpea font-12 font-weight-400">
                                                ACCOUNT SETTINGS
                                            </h6>
                                            <div className="custom-psa-ul-li-list ">
                                                <ul class="pt-30">
                                                    <li
                                                        className="d-flex align-items-center"
                                                        style={{
                                                            color: backgroundColor
                                                        }}>
                                                        {' '}
                                                        <Icon className="mr-2 font-16">
                                                            person
                                                        </Icon>{' '}
                                                        <NavLink
                                                            key={accountPath.pathname}
                                                            disabled={
                                                                isPosting ||
                                                                isLoading
                                                            }
                                                            style={{
                                                                color: backgroundColor
                                                            }}
                                                            to={ accountPath.pathname}>
                                                            My Account
                                                        </NavLink>
                                                    </li>
                                                    <li
                                                        className="d-flex align-items-center"
                                                        style={{
                                                            color: primarycolor
                                                        }}>
                                                        <Icon className="mr-2 font-16">
                                                            lock
                                                        </Icon>{' '}
                                                        Change Password
                                                    </li>
                                                </ul>
                                            </div>

                                            <div class="separator-arrow">
                                                <CollapseIcon />
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid
                                        item
                                        lg={9}
                                        md={9}
                                        sm={12}
                                        xs={12}
                                        direction="column"
                                        spacing={4}>
                                        <div class="p-34">
                                            <h4 class="xs-text-center font-18 mb-2 px-2 text-black">
                                                Change Password
                                            </h4>
                                            <CardTitle>
                                                <form
                                                    onSubmit={this.handleSubmit}
                                                    noValidate
                                                    autoComplete="off">
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        spacing={4}>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            md={6}
                                                            xs={12}>
                                                            <Grid
                                                                container
                                                                direction="column"
                                                                spacing={4}>
                                                                <Grid
                                                                    item
                                                                    className="icon-controller">
                                                                    {this.renderInput(
                                                                        'current',
                                                                        'Current Password',
                                                                        'Current Password',
                                                                        current
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    className="icon-controller">
                                                                    {this.renderInput(
                                                                        'incoming',
                                                                        'New Password',
                                                                        'New Password',
                                                                        incoming
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    className="icon-controller">
                                                                    {this.renderInput(
                                                                        'confirm',
                                                                        'Confirm New Password',
                                                                        'Confirm New Password',
                                                                        confirm
                                                                    )}
                                                                </Grid>
                                                                <Grid item>
                                                                    <div class="text-right xs-text-center">
                                                                        <Button
                                                                            style={{
                                                                                background: primarycolor,
                                                                                color: textColor
                                                                            }}
                                                                            size="medium"
                                                                            variant="contained"
                                                                            color="secondary"
                                                                            className="mr-2 xs-mb-2 path-link"
                                                                            href={
                                                                                accountPath.pathname
                                                                            }>
                                                                            Cancel
                                                                        </Button>
                                                                        <Button
                                                                            className="MuiButton-containedPrimary submit bg-white"
                                                                            style={{
                                                                                color: primarycolor,
                                                                                border: primarycolor
                                                                            }}
                                                                            type="submit"
                                                                            size="medium"
                                                                            variant="contained"
                                                                            color="secondary"
                                                                            onClick={e =>
                                                                                this.handleSubmit(
                                                                                    e
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                !this
                                                                                    .state
                                                                                    .isValidForm ||
                                                                                this
                                                                                    .state
                                                                                    .isPosting
                                                                            }>
                                                                            Save
                                                                        </Button>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            md={6}
                                                            xs={12}>
                                                            <Container className="themed-container instructions position-relative p-4">
                                                                <Typography
                                                                    className="mb-4"
                                                                    variant="body2"
                                                                    gutterBottom>
                                                                    <div class="font-12">
                                                                        The
                                                                        security
                                                                        of your
                                                                        account
                                                                        is
                                                                        important
                                                                        for us.
                                                                        Before
                                                                        you can
                                                                        proceed,
                                                                        please
                                                                        take a
                                                                        moment
                                                                        to
                                                                        create a
                                                                        more
                                                                        secured
                                                                        password.
                                                                    </div>
                                                                </Typography>
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    gutterBottom>
                                                                    <div class="font-12 my-3">
                                                                        Your
                                                                        Password
                                                                        must
                                                                        have at
                                                                        least:
                                                                    </div>
                                                                </Typography>
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        class="d-flex justify-content-between w-100">
                                                                        <Typography
                                                                            variant="body2"
                                                                            gutterBottom>
                                                                            8
                                                                            characters
                                                                        </Typography>
                                                                        <div>
                                                                            {incoming.hasMinLen ? (
                                                                                <CheckIcon
                                                                                    style={{
                                                                                        color:
                                                                                            green[500]
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <CloseIcon
                                                                                    style={{
                                                                                        color:
                                                                                            red[500]
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </Grid>

                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        class="d-flex justify-content-between w-100">
                                                                        <Typography
                                                                            variant="body2"
                                                                            gutterBottom>
                                                                            1
                                                                            number
                                                                        </Typography>
                                                                        <div>
                                                                            {incoming.hasNumber ? (
                                                                                <CheckIcon
                                                                                    style={{
                                                                                        color:
                                                                                            green[500]
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <CloseIcon
                                                                                    style={{
                                                                                        color:
                                                                                            red[500]
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        class="d-flex justify-content-between w-100">
                                                                        <Typography
                                                                            variant="body2"
                                                                            gutterBottom>
                                                                            1 lowercase letter
                                                                        </Typography>
                                                                        <div>
                                                                            {incoming.hasLowercase ? (
                                                                                <CheckIcon
                                                                                    style={{
                                                                                        color:
                                                                                            green[500]
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <CloseIcon
                                                                                    style={{
                                                                                        color:
                                                                                            red[500]
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        class="d-flex justify-content-between w-100">
                                                                        <Typography
                                                                            variant="body2"
                                                                            gutterBottom>
                                                                            1 uppercase letter
                                                                        </Typography>
                                                                        <div>
                                                                            {incoming.hasUppercase ? (
                                                                                <CheckIcon
                                                                                    style={{
                                                                                        color:
                                                                                            green[500]
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <CloseIcon
                                                                                    style={{
                                                                                        color:
                                                                                            red[500]
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </Container>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {this.renderAlert()}
                                                    </Grid>
                                                </form>
                                            </CardTitle>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </CardContent>
            </div>
        )
    }

    async UpdatePassword(data) {
        this.setState({ isPosting: true })
        const token = await authService.getAccessToken()

        const response = await fetch('api/users/UpdatePassword', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })

        if (response.ok) {
            this.setState(prevState => {
                let alert = Object.assign({}, prevState.alert)
                alert.open = true
                alert.success = true
                alert.message = 'Password is saved.'
                return { alert }
            })
            this.setState({
                saved: true
            })
        } else {
            const data = await response.text()
            this.setState(prevState => {
                let alert = Object.assign({}, prevState.alert)
                alert.open = true
                alert.success = false
                alert.message =
                    'Sorry, there was an error while saving these details: ' +
                    data
                return { alert }
            })
        }

        this.setState({
            isPosting: false,
            isValidForm: false
        })
    }
}
export default withStyles(styles)(Password)
