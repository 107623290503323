import '../custom.css'
import React, { PureComponent } from 'react'

import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'
import authService from './api-authorization/AuthorizeService'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    textFieldForm: {
        width: theme.textFieldForm.width
    },
    link: {
        color: 'blue'
    }
})

class ReportDetails extends PureComponent {
    constructor(props) {
        super(props)
        let reportId = props.match.params.reportId
        let groupId = props.match.params.groupId
        if (
            reportId == 'null' ||
            reportId == undefined ||
            reportId == '' ||
            groupId == 'null' ||
            groupId == undefined ||
            groupId == ''
        ) {
            this.goBack()
        }
        this.state = {
            reportData: {},
            embedUrl: '',
            accessToken: '',
            reportId: props.match.params.reportId,
            groupId: props.match.params.groupId
        }
    }

    goBack = () => {
        this.props.history.goBack()
    }

    componentDidMount() {
        this.populateReportData()
    }

    async populateReportData() {
        const token = await authService.getAccessToken()
        const { reportId, groupId } = this.state

        const user = await authService.getUser()
        let userId = ''
        if (user) {
            userId = user.sub
        } else {
            userId = null
        }

        let data = {
            Id: reportId,
            GroupId: groupId,
            UserId: userId
        }
        await fetch('api/bireport/GetReportDetails', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                }
            })

            .then(d => {
                //const data = d.json()
                this.setState({
                    reportData: d
                })
            })
            .catch(error => {
                this.setState({
                    reportData: {
                        embedUrl: '',
                        token: '',
                        reportId: '',
                        reportName: ''
                    }
                })
            })
    }

    render() {
        const { reportData, embedUrl, accessToken, reportId } = this.state
        return (
            <div class="custom-graph mt-3">
                <h3 class="mb-3">{reportData.reportName}</h3>

                <PowerBIEmbed
                    embedConfig={{
                        type: 'report', // ;lmSupported types: report, dashboard, tile, visual and qna
                        id: reportData.reportId,
                        embedUrl: reportData.embedUrl, //test-bi.psabg.com
                        accessToken: reportData.token,
                        tokenType: models.TokenType.Embed,
                        pageView: 'fitToWidth',
                        settings: {
                            panes: {
                                filters: {
                                    expanded: false,
                                    visible: false
                                }
                            },
                            background: models.BackgroundType.Transparent
                        }
                    }}
                    eventHandlers={
                        new Map([
                            [
                                'loaded',
                                function() {
                                    console.log('Report loaded')
                                }
                            ],
                            [
                                'rendered',
                                function() {
                                    console.log('Report rendered')
                                }
                            ],
                            [
                                'error',
                                function(event) {
                                    console.log(event.detail)
                                }
                            ]
                        ])
                    }
                    cssClassName={'report-style-class'}
                    getEmbeddedComponent={embeddedReport => {
                        window.report = embeddedReport
                    }}
                />
            </div>
        )
    }
}

export default withStyles(styles)(ReportDetails)
