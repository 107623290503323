import React, { PureComponent, useState } from 'react'
import authService from './api-authorization/AuthorizeService'
import {
    Card,
    CardContent,
    FormControlLabel,
    Checkbox
} from '@material-ui/core'
import { CardMedia, CardActions, CardActionArea } from '@material-ui/core'
import {
    Grid,
    TextField,
    Input,
    FormControl,
    LinearProgress
} from '@material-ui/core'
import { MenuItem, Select, Typography } from '@material-ui/core'
import { IconButton, Button } from '@material-ui/core'
import { InputLabel, InputAdornment } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import { phoneNumberValidation } from '../components/shared/Helpers'
import { emailValidation } from '../components/shared/Helpers'
import CardTitle from './shared/CardTitle'
import Paper from '@material-ui/core/Paper'
import Icon from '@material-ui/core/Icon'
import MUIDataTable from 'mui-datatables'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Divider from '@material-ui/core/Divider';
import {
    extractCreateShipment,
    extractSearchShipment,
    extractSeeCustoms,
    extractSeeAccounting,
    extractSeeBooking,
    extractSeeOrder,
    extractSeeContainers,
    extractCreateOrder
} from '../utils/permissions'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import moment from 'moment';

const styles = theme => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    textFieldForm: {
        width: theme.textFieldForm.width
    }
})

const shipmentServiceOptions = [
    {
        id: 1,
        value: 'NORMANHK',
        label: 'Norman HK'
    },
    {
        id: 2,
        value: 'WISEGRID',
        label: 'WiseGrid'
    }
]

const eAdaptorAuthOptions = [
    {
        id: 1,
        value: 'DEFAULT',
        label: 'Default'
    },
    {
        id: 2,
        value: 'EFLGLOBAL',
        label: 'EFL Global'
    }
]

class OrganizationDetails extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            reportAccessList: [],
            dashboardAccessList: [],
            displayNoReports: false,
            displayNoDashboards: false,
            organization: [],
            dateFormatOptions: [],
            user: [],
            orgId: '',
            groupdata: {},
            updLogo: false,
            updData: true,
            files: [],
            showPassword: false,
            eAdaptorPassword: '',
            isValidOrgName: true,
            errorTextOrgName: '',
            isValidOrgAppName: true,
            isValidOrgLinkName: true,
            isValidOrgLinkUrl: true,
            isValidOrgLink2Name: true,
            isValidOrgLink2Url: true,
            isValidOrgLink3Name: true,
            isValidOrgLink3Url: true,
            isValidOrgLink4Name: true,
            isValidOrgLink4Url: true,
            isValidOrgLink5Name: true,
            isValidOrgLink5Url: true,
            isValidBannerText: true,
            errorTextOrgAppName: '',
            errorTextLinkName: '',
            errorTextLinkUrl: '',
            errorTextLink2Name: '',
            errorTextLink2Url: '',
            errorTextLink3Name: '',
            errorTextLink3Url: '',
            errorTextLink4Name: '',
            errorTextLink4Url: '',
            errorTextLink5Name: '',
            errorTextLink5Url: '',
            errorTextEmail: '',
            errorTextSubDomain: '',
            errorTextPhone: '',
            errorTextBannerText: '',
            isValidSubDomain: true,
            isValidEmail: true,
            isValidPhone: true,
            isValidStreet: true,
            errorTextStreet: '',
            isValidForm: true,
            isPosting: false,
            isLoading: true,

            canSeeModuleAccess: false,
            alert: {
                open: false,
                success: false,
                message: ''
            },
            searchShipment: true,
            expanded: 'panel1',
            openSyncHistoryView: false,
            syncHistoryData: ''
        }
    
        this.onSearch = this.onSearch.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.populateOrganizationData = this.populateOrganizationData.bind(this)
        this.handleACCChange = this.handleACCChange.bind(this)
    }

    async componentDidMount() {
        await this.populateDateFormatOptions()
        await this.populateOrganizationData()
        const canSeeModuleAccess = await authService.isSuperAdmin()
        this.setState({ canSeeModuleAccess })
        this.setState({ isLoading: false })
    }

    handleClickShowPassword = () => {
        this.setState({ ...this.state, showPassword: !this.state.showPassword })
    }

    handleMouseDown = event => {
        event.preventDefault()
    }

    onSearch(e) {
        this.handleChange(e, 'searchShipment', false)
    }

    updateOrganizationField = (value, key) => {
        this.setState(prevState => {
            let organization = Object.assign({}, prevState.organization)
            organization[key] = value
            return { organization }
        })
    }

    handleChange2(event, field, isRequired) {
        let value = event.target.value
        let isValidField = true
        let isValidForm = true
        let updData = true
        let msgError = ''

        if (value.length === 0 && isRequired) {
            isValidField = false
            isValidForm = false
            msgError = 'Organization name cannot be empty.'
        }
        switch (field) {
            case 'bigroupId':
                this.setState({
                    errorTextOrgName: msgError,
                    isValidOrgName: isValidField
                })
                this.updateOrganizationField2(value, 'groupId')

                break
            case 'bigroupName':
                this.setState({
                    errorTextOrgName: msgError,
                    isValidOrgName: isValidField
                })
                this.updateOrganizationField2(value, 'groupName')

                break
        }
    }
    updateOrganizationField2 = (value, key) => {
        this.setState(prevState => {
            let groupdata = Object.assign({}, prevState.groupdata)
            groupdata[key] = value
            return { groupdata }
        })
    }

    handleChange(event, field, isRequired) {
        let value = event.target.value
        let isValidField = true
        let isValidForm = true
        let updData = true
        let msgError = ''

        if (value.length === 0 && isRequired) {
            isValidField = false
            isValidForm = false
            msgError = 'Organization name cannot be empty.'
        }

        switch (field) {
            case 'org-details-name':
                this.setState({
                    errorTextOrgName: msgError,
                    isValidOrgName: isValidField
                })
                this.updateOrganizationField(value, 'name')

                break

            case 'org-details-app-name':
                this.setState({
                    errorTextOrgAppName: msgError,
                    isValidOrgAppName: isValidField
                })
                this.updateOrganizationField(value, 'organizationAppName')

                break

            case 'org-details-custom-link-name':
                this.setState({
                    errorTextLinkName: msgError,
                    isValidOrgLinkName: isValidField
                })
                this.updateOrganizationField(
                    value,
                    'organizationCustomLinkName'
                )

                break

            case 'org-details-custom-link-url':
                this.setState({
                    errorTextLinkUrl: msgError,
                    isValidOrgLinkUrl: isValidField
                })
                this.updateOrganizationField(value, 'organizationCustomLinkUrl')

                break

            case 'org-details-custom-link2-name':
                this.setState({
                    errorTextLink2Name: msgError,
                    isValidOrgLink2Name: isValidField
                })
                this.updateOrganizationField(
                    value,
                    'organizationCustomLink2Name'
                )

                break

            case 'org-details-custom-link2-url':
                this.setState({
                    errorTextLink2Url: msgError,
                    isValidOrgLink2Url: isValidField
                })
                this.updateOrganizationField(
                    value,
                    'organizationCustomLink2Url'
                )

                break

            case 'org-details-custom-link3-name':
                this.setState({
                    errorTextLink3Name: msgError,
                    isValidOrgLink3Name: isValidField
                })
                this.updateOrganizationField(
                    value,
                    'organizationCustomLink3Name'
                )

                break

            case 'org-details-custom-link3-url':
                this.setState({
                    errorTextLink3Url: msgError,
                    isValidOrgLink3Url: isValidField
                })
                this.updateOrganizationField(
                    value,
                    'organizationCustomLink3Url'
                )

                break

            case 'org-details-custom-link4-name':
                this.setState({
                    errorTextLink4Name: msgError,
                    isValidOrgLink4Name: isValidField
                })
                this.updateOrganizationField(
                    value,
                    'organizationCustomLink4Name'
                )

                break

            case 'org-details-custom-link4-url':
                this.setState({
                    errorTextLink4Url: msgError,
                    isValidOrgLink4Url: isValidField
                })
                this.updateOrganizationField(
                    value,
                    'organizationCustomLink4Url'
                )

                break

            case 'org-details-custom-link5-name':
                this.setState({
                    errorTextLink5Name: msgError,
                    isValidOrgLink5Name: isValidField
                })
                this.updateOrganizationField(
                    value,
                    'organizationCustomLink5Name'
                )

                break

            case 'org-details-custom-link5-url':
                this.setState({
                    errorTextLink5Url: msgError,
                    isValidOrgLink5Url: isValidField
                })
                this.updateOrganizationField(
                    value,
                    'organizationCustomLink5Url'
                )

                break

            case 'org-details-subdomain':
                this.setState({
                    errorTextSubDomain: msgError,
                    isValidSubDomain: isValidField
                })
                this.updateOrganizationField(value, 'subdomain')
                break

            case 'org-details-theme':
                this.updateOrganizationField(value, 'colorThemeRGB')
                break

            case 'org-details-headertheme':
                this.updateOrganizationField(value, 'colorThemeTextRGB')
                break
            case 'org-details-primarytheme':
                this.updateOrganizationField(value, 'colorThemePrimaryRGB')
                break
            case 'org-details-secondarytheme':
                this.updateOrganizationField(value, 'colorThemeSecondaryRGB')
                break
            case 'org-details-tertiarytheme':
                this.updateOrganizationField(value, 'colorThemeTertiaryRGB')
                break

            case 'org-details-herotext':
                this.updateOrganizationField(value, 'loginPageHeroText')
                break
            
            case 'org-details-heroFontColor':
                this.updateOrganizationField(value, 'loginPageHeroTextColor')
                break

            case 'org-details-heroBackgroundColor':
                this.updateOrganizationField(value, 'loginPageHeroBackgroundColor')
                break

            case 'org-details-bannerBackgroundColor':
                this.updateOrganizationField(value, 'bannerBackgroundColor')
                break

            case 'org-details-bannerTextColor':
                this.updateOrganizationField(value, 'bannerTextColor')
                break

            case 'org-details-bannerText':
                // need to make sure value is equal or less than 2000 characters
                if (value.length > 2000) {
                    isValidField = false
                    isValidForm = false
                    msgError = 'Banner text cannot be more than 2000 characters.'
                }
                this.setState({
                    errorTextBannerText: msgError,
                    isValidBannerText: isValidField
                })
                this.updateOrganizationField(value, 'bannerText')
                break

            case 'org-details-bannerTitle':
                this.updateOrganizationField(value, 'bannerTitle')
                break

            case 'org-details-signupurl':
                this.updateOrganizationField(value, 'signUpURL')
                break

            case 'org-details-shipmentservice':
                this.updateOrganizationField(value, 'shipmentServiceSelected')
                break

            case 'org-details-eadaptorauth':
                this.updateOrganizationField(value, 'eAdaptorAuthMethod')
                break

            case 'org-eAdaptor-authUrl':
                this.updateOrganizationField(value, 'eAdaptorAuthUrl')
                break

            case 'org-details-dateformat': 
                this.updateOrganizationField(value, 'dateFormatId')
                break

            case 'org-eAdaptor-availableCompanyIds':
                this.updateOrganizationField(value, 'cargowiseCompanyCodes')
                break

            case 'org-details-logo':
                this.changePreviewLogo()
                updData = false
                this.setState({ updLogo: true })
                break

            case 'org-address-street':
                this.updateOrganizationField(value, 'companyAddressStreet')
                break

            case 'org-address-region':
                this.updateOrganizationField(value, 'companyAddressRegion')
                break

            case 'org-address-city':
                this.updateOrganizationField(value, 'companyAddressCity')
                break

            case 'org-address-zipcode':
                this.updateOrganizationField(value, 'companyAddressZipCode')
                break

            case 'org-address-country':
                this.updateOrganizationField(value, 'companyAddressCountry')
                break

            case 'org-contact-firstname':
                this.updateOrganizationField(value, 'contactFirstName')
                break

            case 'org-contact-lastname':
                this.updateOrganizationField(value, 'contactLastName')
                break

            case 'org-contact-email':
                var emailValid = emailValidation(value)

                if (!emailValid) {
                    isValidField = false
                    isValidForm = false
                    msgError = 'Email address is not valid.'
                }

                this.setState({
                    errorTextEmail: msgError,
                    isValidEmail: isValidField
                })
                this.updateOrganizationField(value, 'contactEmail')
                break

            case 'org-contact-phone': //+1-541-754-3010
                var phoneValid = phoneNumberValidation(value)

                if (!phoneValid) {
                    isValidField = false
                    isValidForm = false
                    msgError = 'Phone number is not valid.'
                }

                this.setState({
                    errorTextPhone: msgError,
                    isValidPhone: isValidField
                })
                this.updateOrganizationField(value, 'contactPhoneNumber')
                break

            case 'integration-url':
                this.updateOrganizationField(value, 'webServiceIntegrationUrl')
                break

            case 'org-eAdaptor-apiUrl':
                this.updateOrganizationField(value, 'eAdaptorApiUrl')
                break

            case 'org-eAdaptor-companyCode':
                this.updateOrganizationField(value, 'eAdaptorCompanyCode')
                break

            case 'org-eAdaptor-customsCompanyCode':
                this.updateOrganizationField(
                    value,
                    'eAdaptorCustomsCompanyCode'
                )
                break

            case 'org-eAdaptor-enterpriseId':
                this.updateOrganizationField(value, 'eAdaptorEnterpriseId')
                break

            case 'org-eAdaptor-serverId':
                this.updateOrganizationField(value, 'eAdaptorServerId')
                break

            case 'org-eAdaptor-userName':
                this.updateOrganizationField(value, 'eAdaptorUserName')
                break

            case 'org-eAdaptor-password':
                this.setState({ eAdaptorPassword: value })
                this.updateOrganizationField(value, 'eAdaptorPassword')
                break

            case 'searchShipment':
                const searchShipment = this.state.searchShipment
                this.setState({ searchShipment: !searchShipment })
                break

            case 'demo-supplier-address-name':
                this.updateOrganizationField(value, 'demoSupplierAddressName')
                break

            case 'demo-supplier-address-street':
                this.updateOrganizationField(value, 'demoSupplierAddressStreet')
                break

            case 'demo-supplier-address-city':
                this.updateOrganizationField(value, 'demoSupplierAddressCity')
                break

            case 'demo-supplier-address-zipcode':
                this.updateOrganizationField(value, 'demoSupplierAddressZipCode')
                break

            case 'demo-supplier-address-country':
                this.updateOrganizationField(value, 'demoSupplierAddressCountry')
                break

            case 'demo-supplier-address-countrycode':
                this.updateOrganizationField(value, 'demoSupplierAddressCountryCode')
                break

            case 'demo-buyer-address-name':
                this.updateOrganizationField(value, 'demoBuyerAddressName')
                break

            case 'demo-buyer-address-street':
                this.updateOrganizationField(value, 'demoBuyerAddressStreet')
                break

            case 'demo-buyer-address-city':
                this.updateOrganizationField(value, 'demoBuyerAddressCity')
                break

            case 'demo-buyer-address-zipcode':
                this.updateOrganizationField(value, 'demoBuyerAddressZipCode')
                break

            case 'demo-buyer-address-country':
                this.updateOrganizationField(value, 'demoBuyerAddressCountry')
                break
            
            case 'demo-buyer-address-countrycode':
                this.updateOrganizationField(value, 'demoBuyerAddressCountryCode')
                break

            case 'org-details-zoomlevel':
                this.updateOrganizationField(value, 'zoomLevel')
                // update local storage
                localStorage.setItem('zoomLevel', value)
                break
                
            default:
                break
        }

        this.setState({ isValidForm: isValidForm, updData: updData })
    }

    changePreviewLogo() {
        let reader = new FileReader()
        let file = this.refs.file.files[0]
        let files = this.state.files

        reader.readAsArrayBuffer(file)

        reader.onloadend = function(e) {
            var blob = new Blob([this.refs.file.files[0]])
            window.URL = window.URL || window.webkitURL
            var blobURL = window.URL.createObjectURL(blob)

            if (files.length === 0) {
                files.push(file)
            } else {
                files[0] = file
            }
            this.setState(prevState => {
                let organization = Object.assign({}, prevState.organization)
                organization.organizationLogoUrl = blobURL
                return { organization }
            })

            this.setState({ files: files })
        }.bind(this)
    }

    handleSubmit = async event => {
        var { organization, updLogo, files, updData, groupdata } = {
            ...this.state
        }
        organization.eAdaptorPassword = this.state.eAdaptorPassword
        this.setState({ organization })
        delete organization['clients']
        delete organization['id']
        event.preventDefault()

        if (updLogo && files !== null) {
            var formData = new FormData()
            var file = this.state.files[0]
            formData.append('files', file)
            this.updateOrganizationData(formData, updLogo)
        }
        if (updData) {
            this.updateOrganizationData(organization, updLogo)
        }
        this.updateOrganizationBIGroupData(groupdata)
        if (groupdata.groupId != null && groupdata.groupId != '') {
            this.saveOrgReportsData()
            this.saveOrgDashboardsData()
        }
    }

    handleCloseAlert() {
        this.setState(prevState => {
            let alert = Object.assign({}, prevState.alert)
            alert.open = false
            alert.success = false
            alert.message = ''
            return { alert }
        })
    }

    renderAlert = () => {
        const alert = this.state.alert
        return alert.open ? (
            <div className="w-100">
                <Alert
                    variant="filled"
                    severity={alert.success ? 'success' : 'error'}
                    onClose={() => {
                        this.handleCloseAlert()
                    }}>
                    {alert.message}
                </Alert>
            </div>
        ) : null
    }

    handleModuleChange = (key, value) => {
        const { organization } = this.state
        let permissions = null
        // check if key exists in array
        if (organization.modulePermissionArray.some(e => e.propertyKey === key)) {
            permissions = organization.modulePermissionArray.map(
                permission => {
                    if (permission.propertyKey === key) {
                        permission['value'] = value
                    }
                    return permission
                }
            )
        } else {
            permissions = [
                ...organization.modulePermissionArray,
                {
                    propertyKey: key,
                    value: value
                }
            ]
        }

        this.setState({
            organization: {
                ...organization,
                modulePermissionArray: permissions
            }
        })
    }
    
    handleCheckboxChange = (event, field) => {
        const checked = event.target.checked
        
        switch (field) {
            case 'usesOpenTrack':
                this.updateOrganizationField(checked, 'isOpenTrack')
                break
            case 'usesOpenTrackTracking':
                this.updateOrganizationField(checked, 'isOpenTrackTracking')
                break
            case 'showBanner':
                this.updateOrganizationField(checked, 'showBanner')
                break
            default:
                break
        }

    }

    handleReportChange = (key, value) => {
        const { reportAccessList } = this.state
        const reports = reportAccessList.map(report => {
            if (report.reportId === key) {
                report['isChecked'] = value
            }
            return report
        })

        this.setState({
            reportAccessList: reports
        })
    }
    handleDashboardChange = (key, value) => {
        const { dashboardAccessList } = this.state
        const dashboards = dashboardAccessList.map(dashboard => {
            if (dashboard.dashboardId === key) {
                dashboard['isChecked'] = value
            }
            return dashboard
        })
        this.setState({
            dashboardAccessList: dashboards
        })
    }

    renderInput = (
        id,
        label,
        placeholder,
        orgValue,
        required,
        valid = null,
        errorText = null
    ) => {
        const { organization } = this.state
        const { classes } = this.props
        var params = {}
        if (valid !== null && errorText !== null) {
            params['helperText'] = !this.state[valid]
                ? this.state[errorText]
                : ''
            params['error'] = !this.state[valid]
        }

        return (
            <Grid item xs={12}>
                <FormControl className="d-flex flex-grow-1">
                    <TextField
                        className={classes.textFieldForm}
                        id={id}
                        label={label}
                        placeholder={placeholder}
                        value={organization[orgValue] || ''}
                        onChange={e => this.handleChange(e, id, required)}
                        {...params}
                    />
                </FormControl>
            </Grid>
        )
    }

    renderInput2 = (
        id,
        label,
        placeholder,
        orgValue,
        required,
        valid = null,
        errorText = null
    ) => {
        const { groupdata } = this.state
        const { classes } = this.props
        var params = {}
        if (valid !== null && errorText !== null) {
            params['helperText'] = !this.state[valid]
                ? this.state[errorText]
                : ''
            params['error'] = !this.state[valid]
        }

        return (
            <Grid item xs={12}>
                <FormControl className="d-flex flex-grow-1">
                    <TextField
                        className={classes.textFieldForm}
                        id={id}
                        label={label}
                        placeholder={placeholder}
                        value={groupdata[orgValue] || ''}
                        onChange={e => this.handleChange2(e, id, required)}
                        {...params}
                    />
                </FormControl>
            </Grid>
        )
    }

    renderColorSelector = () => {
        const { organization } = this.state
        return (
            <>
                <input
                    type="color"
                    onChange={e =>
                        this.handleChange(e, 'org-details-theme', true)
                    }
                    value={organization.colorThemeRGB}
                />
            </>
        )
    }

    renderHeaderTextColorSelector = () => {
        const { organization } = this.state
        return (
            <input
                type="color"
                onChange={e =>
                    this.handleChange(e, 'org-details-headertheme', true)
                }
                value={organization.colorThemeTextRGB}
            />
        )
    }

    renderPrimaryColorSelector = () => {
        const { organization } = this.state
        return (
            <input
                type="color"
                onChange={e =>
                    this.handleChange(e, 'org-details-primarytheme', true)
                }
                value={organization.colorThemePrimaryRGB}
            />
        )
    }
    renderSecondaryColorSelector = () => {
        const { organization } = this.state
        return (
            <input
                type="color"
                onChange={e =>
                    this.handleChange(e, 'org-details-secondarytheme', true)
                }
                value={organization.colorThemeSecondaryRGB}
            />
        )
    }
    renderTertiaryColorSelector = () => {
        const { organization } = this.state
        return (
            <input
                type="color"
                onChange={e =>
                    this.handleChange(e, 'org-details-tertiarytheme', true)
                }
                value={organization.colorThemeTertiaryRGB}
            />
        )
    }

    renderHeroFontColorSelector = () => {
        const { organization } = this.state
        return (
            <input
                type="color"
                onChange={e =>
                    this.handleChange(e, 'org-details-heroFontColor', true)
                }
                value={organization.loginPageHeroTextColor}
            />
        )
    }

    renderHeroBackgroundColorSelector = () => {
        const { organization } = this.state
        return (
            <input
                type="color"
                onChange={e =>
                    this.handleChange(e, 'org-details-heroBackgroundColor', true)
                }
                value={organization.loginPageHeroBackgroundColor}
            />
        )
    }

    renderBannerBackgroundColorSelector = () => {
        const { organization } = this.state
        return (
            <input
                type="color"
                onChange={e =>
                    this.handleChange(e, 'org-details-bannerBackgroundColor', true)
                }
                value={organization.bannerBackgroundColor}
            />
        )
    }

    renderBannerTextColorSelector = () => {
        const { organization } = this.state
        return (
            <input
                type="color"
                onChange={e =>
                    this.handleChange(e, 'org-details-bannerTextColor', true)
                }
                value={organization.bannerTextColor}
            />
        )
    }

    renderShipmentServiceSelector = () => {
        const { organization } = this.state
        const foundIndex = shipmentServiceOptions.findIndex(
            shipmentService =>
                shipmentService.value === organization.shipmentServiceSelected
        )

        return (
            <Select
                name="shipmentService"
                label="Shipment Service"
                onChange={e =>
                    this.handleChange(e, 'org-details-shipmentservice', true)
                }
                value={
                    shipmentServiceOptions[foundIndex >= 0 ? foundIndex : 0]
                        .value
                }
                input={<Input id="shipmentService" />}>
                {shipmentServiceOptions.map(option => (
                    <MenuItem key={option.id} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        )
    }

    renderZoomLevelSelector = () => {
        const { organization } = this.state
        return (
            <Select
                name="zoomLevel"
                label="Zoom Level"
                onChange={e =>
                    this.handleChange(e, 'org-details-zoomlevel', true)
                }
                value={organization.zoomLevel}
                input={<Input id="zoomLevel" />}>
                <MenuItem key={25} value={25}>
                    25%
                </MenuItem>
                <MenuItem key={33} value={33}>
                    33%
                </MenuItem>
                <MenuItem key={50} value={50}>
                    50%
                </MenuItem>
                <MenuItem key={67} value={67}>
                    67%
                </MenuItem>
                <MenuItem key={75} value={75}>
                    75%
                </MenuItem>
                <MenuItem key={80} value={80}>
                    80%
                </MenuItem>
                <MenuItem key={90} value={90}>
                    90%
                </MenuItem>
                <MenuItem key={100} value={100}>
                    100%
                </MenuItem>
            </Select>
        )
    }

    renderEAdaptorAuthSelector = () => {
        const { organization } = this.state
        const foundIndex = eAdaptorAuthOptions.findIndex(
            authOption => authOption.value === organization.eAdaptorAuthMethod
        )

        return (
            <Select
                name="eAdaptorAuthMethod"
                label="eAdaptor Authentication Method"
                onChange={e =>
                    this.handleChange(e, 'org-details-eadaptorauth', true)
                }
                value={
                    eAdaptorAuthOptions[foundIndex >= 0 ? foundIndex : 0].value
                }
                input={<Input id="eAdaptorAuthMethod" />}>
                {eAdaptorAuthOptions.map(option => (
                    <MenuItem key={option.id} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        )
    }
 
    renderDateFormatSelector = () => {
        const { organization, dateFormatOptions } = this.state

        return (
            <Select
                name="dateFormat"
                labelId="date-format"
                onChange={e =>this.handleChange(e, 'org-details-dateformat', true)}
                value={organization.dateFormatId}
                input={<Input id="dateFormat" />}>
                {dateFormatOptions.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.description}
                    </MenuItem>
                ))}
            </Select>
        )
    }

    render() {
        const { classes } = this.props

        const {
            organization,
            showPassword,
            canSeeModuleAccess,
            expanded,
            adfSyncStatusArray,
            openSyncHistoryView,
            syncHistoryData
            } = this.state

        const eAdaptorPassword = organization.eAdaptorPassword

        const {
            reportAccessList,
            dashboardAccessList,
            displayNoDashboards,
            displayNoReports
        } = this.state

        const backgroundColor =
            organization !== null && organization.colorThemeRGB !== null
                ? `${organization.colorThemeRGB}`
                : '#ffffff'

        const textColor =
            organization !== null && organization.colorThemeTextRGB !== null
                ? `${organization.colorThemeTextRGB}`
                : '#000000'

        const primarycolor =
            organization !== null && organization.colorThemePrimaryRGB !== null
                ? `${organization.colorThemePrimaryRGB}`
                : '#ffffff'

        const secodarycolor =
            organization !== null &&
            organization.colorThemeSecondaryRGB !== null
                ? `${organization.colorThemeSecondaryRGB}`
                : '#000000'

        const teritorycolor =
            organization !== null && organization.colorThemeTertiaryRGB !== null
                ? `${organization.colorThemeTertiaryRGB}`
                : '#000000'

        const handleSyncHistoryViewClickOpen = async (rowClickedData) => {
            var historyData = await this.getSyncHistoryData(rowClickedData) 
            this.setState({syncHistoryData: historyData})
            this.setState({openSyncHistoryView: true})
            }
        
        const handleSyncHistoryViewClickClose = () => {
            this.setState({openSyncHistoryView: false})
            }

        const syncStatusTableHeadCells = [
            {
                name: "syncIcon", 
                label: " ",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var errorText = tableMeta.rowData[4]
                        return (
                           errorText !== null ? 
                            (<ErrorOutlineIcon color='error' />) : 
                            (<CheckCircleOutlineIcon style={{ color: 'green' }} />)
                        )
                    }
                }
            },
            {name: "logId", label: "Id"},
            {name: "syncId", label: "Sync Id"},
            {name: "syncName", label: "Sync Name"},
            {name: "syncError", label: "Sync Error"},
            {name: "syncStatus", label: "Sync Message"},
            {
                name: "syncDate", 
                label: "Last Sync Date",
                options: {
                    customBodyRender: (value) => {return (value !== null ? new Date(
                        value
                    ).toLocaleString() : '')}}
            },
            {
                name: "viewSyncHistory", 
                label: "Sync History",
                options: {
                    customBodyRender: (value, tableMeta) => {
                        const rowData = tableMeta.rowData;
                        return (
                            <div>
                                <IconButton
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        handleSyncHistoryViewClickOpen(rowData);
                                    }}
                                >
                                    View
                                </IconButton>
                                
                            </div>
                        );
                    }
                }
            }
        ]
        
        const syncStatusTableOptions = {
            filter: false,
            viewColumns: false,
            print: false,
            download: false,
            selectableRowsOnClick: false,
            selectableRows: 'none',
            caseSensitive: false,
            rowsPerPageOptions: [10],
            filterType: 'dropdown',
            responsive: 'stacked',
            rowsPerPage: 10,
            searchOpen: false,
            search: false
        }

        return this.state.isLoading ? (
            <LinearProgress color="primary" />
        ) : (
            <div>
                <CardContent class="p-0 psa-shipment-paper-card">
                    {this.renderAlert()}
                    <Paper className={classes.paper} class="psashipmentpaper">
                        <form
                            onSubmit={this.handleSubmit}
                            noValidate
                            autoComplete="off">
                            <div class="accordian-body-padding">
                                <Grid container spacing={1}>
                                    <Grid
                                        container
                                        xs={12}
                                        className="mb-0"
                                        spacing={0}
                                        class="mb-4 w-100">
                                        <Grid
                                            item
                                            xs={12}
                                            direction="column"
                                            spacing={4}>
                                            <div className="no-p-0 custom-psa-accordian">
                                                <ExpansionPanel
                                                    square
                                                    expanded={
                                                        expanded === 'panel1'
                                                    }
                                                    onChange={this.handleACCChange(
                                                        'panel1'
                                                    )}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: primarycolor,
                                                                    color: primarycolor
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }
                                                            class="accordian-card-heading">
                                                            <h5
                                                                style={{
                                                                    color: primarycolor
                                                                }}
                                                                className="font-12">
                                                                3PL Organization
                                                                Information &
                                                                Theme
                                                            </h5>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={4}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-details-name',
                                                                        'Organization Name',
                                                                        'Organization Name',
                                                                        'name',
                                                                        true,
                                                                        'isValidOrgName',
                                                                        'errorTextOrgName'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-details-app-name',
                                                                        'Application Name',
                                                                        'Application Name',
                                                                        'organizationAppName',
                                                                        false,
                                                                        'isValidOrgAppName',
                                                                        'errorTextOrgAppName'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <div class="custom-theme-selector">
                                                                        <FormControl
                                                                            className={
                                                                                classes.textFieldForm
                                                                            }>
                                                                            {this.renderColorSelector()}
                                                                            <InputLabel>
                                                                                {/* Background Color */}
                                                                                Primary
                                                                                background
                                                                                color
                                                                            </InputLabel>
                                                                        </FormControl>
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <div class="custom-theme-selector">
                                                                        <FormControl
                                                                            className={
                                                                                classes.textFieldForm
                                                                            }>
                                                                            {this.renderHeaderTextColorSelector()}
                                                                            <InputLabel>
                                                                                Primary
                                                                                Text
                                                                                Color
                                                                            </InputLabel>
                                                                        </FormControl>
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <div class="custom-theme-selector">
                                                                        <FormControl
                                                                            className={
                                                                                classes.textFieldForm
                                                                            }>
                                                                            {this.renderPrimaryColorSelector()}
                                                                            <InputLabel>
                                                                                Secondary
                                                                                background
                                                                                color
                                                                            </InputLabel>
                                                                        </FormControl>
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <div class="custom-theme-selector">
                                                                        <FormControl
                                                                            className={
                                                                                classes.textFieldForm
                                                                            }>
                                                                            {this.renderSecondaryColorSelector()}
                                                                            <InputLabel>
                                                                                Secondary
                                                                                Text
                                                                                Color
                                                                            </InputLabel>
                                                                        </FormControl>
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <div class="custom-theme-selector">
                                                                        <FormControl
                                                                            className={
                                                                                classes.textFieldForm
                                                                            }>
                                                                            <InputLabel>
                                                                                Tertiary
                                                                                Color
                                                                            </InputLabel>
                                                                            {this.renderTertiaryColorSelector()}
                                                                        </FormControl>
                                                                    </div>
                                                                </Grid>

                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <FormControl
                                                                        className={
                                                                            classes.textFieldForm
                                                                        }>
                                                                        <InputLabel id="date-format">
                                                                            Date
                                                                            Format
                                                                        </InputLabel>
                                                                        {this.renderDateFormatSelector()}
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <div class="position-relative">
                                                                        <FormControl
                                                                            className={
                                                                                classes.formControl
                                                                            }>
                                                                            {organization.organizationLogoUrl && (
                                                                                <Card
                                                                                    className={
                                                                                        classes.card
                                                                                    }>
                                                                                    <CardActionArea>
                                                                                        {}
                                                                                        <CardMedia
                                                                                            style={{
                                                                                                display:
                                                                                                    'none'
                                                                                            }}
                                                                                            id="org-details-logo"
                                                                                            component="img"
                                                                                            alt={
                                                                                                organization.name +
                                                                                                ' logo'
                                                                                            }
                                                                                            image={
                                                                                                organization.organizationLogoUrl
                                                                                            }
                                                                                            title="Organization Logo"
                                                                                        />
                                                                                        <CardContent>
                                                                                            <div
                                                                                                className={
                                                                                                    classes.title
                                                                                                }>
                                                                                                <Typography
                                                                                                    className="mt-1 text-center organization-name"
                                                                                                    variant={
                                                                                                        'h6'
                                                                                                    }
                                                                                                    noWrap>
                                                                                                    {
                                                                                                        organization.name
                                                                                                    }
                                                                                                </Typography>
                                                                                            </div>
                                                                                        </CardContent>
                                                                                    </CardActionArea>
                                                                                    <CardActions>
                                                                                        <div
                                                                                            className={
                                                                                                classes.root
                                                                                            }>
                                                                                            <input
                                                                                                style={{
                                                                                                    display:
                                                                                                        'none'
                                                                                                }}
                                                                                                accept="image/*"
                                                                                                className={
                                                                                                    classes.input
                                                                                                }
                                                                                                id="raised-button-file"
                                                                                                type="file"
                                                                                                ref="file"
                                                                                                onChange={e =>
                                                                                                    this.handleChange(
                                                                                                        e,
                                                                                                        'org-details-logo',
                                                                                                        false
                                                                                                    )
                                                                                                }
                                                                                            />

                                                                                            <label htmlFor="raised-button-file">
                                                                                                <Button
                                                                                                    variant="outlined"
                                                                                                    component="span"
                                                                                                    className={
                                                                                                        classes.button
                                                                                                    }>
                                                                                                    Upload
                                                                                                    Logo
                                                                                                </Button>
                                                                                            </label>
                                                                                        </div>
                                                                                    </CardActions>
                                                                                </Card>
                                                                            )}
                                                                        </FormControl>
                                                                        <div class="position-absolute file-upload">
                                                                            <FormControl
                                                                                className={
                                                                                    classes.textFieldForm
                                                                                }></FormControl>
                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <div class="custom-theme-selector">
                                                                        <FormControl
                                                                            className={
                                                                                classes.textFieldForm
                                                                            }>
                                                                            <InputLabel>
                                                                                Login Page Hero Background Color
                                                                            </InputLabel>
                                                                            {this.renderHeroBackgroundColorSelector()}
                                                                        </FormControl>
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <div class="custom-theme-selector">
                                                                        <FormControl
                                                                            className={
                                                                                classes.textFieldForm
                                                                            }>
                                                                            <InputLabel>
                                                                                Login Page Hero Font Color
                                                                            </InputLabel>
                                                                            {this.renderHeroFontColorSelector()}
                                                                        </FormControl>
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-details-herotext',
                                                                        'Login Page Hero Text',
                                                                        'Login Page Hero Text',
                                                                        'loginPageHeroText',
                                                                        true,
                                                                        'isValidLoginPageHeroText',
                                                                        'errorTextLoginPageHeroText',
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-details-signupurl',
                                                                        'Login Page Signup URL',
                                                                        'Login Page Signup URL',
                                                                        'signUpURL',
                                                                        false,
                                                                        'isValidLoginPageSignUpURL',
                                                                        'errorTextLoginPageSignUpURL',
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <FormControl
                                                                        className={
                                                                            classes.textFieldForm
                                                                        }>
                                                                        <InputLabel>
                                                                            Zoom Level
                                                                        </InputLabel>
                                                                        {this.renderZoomLevelSelector()}
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={12}
                                                                    lg={12}
                                                                    md={12}
                                                                    sm={12}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    onChange={event => {
                                                                                        this.handleCheckboxChange(
                                                                                            event,
                                                                                            'showBanner'
                                                                                        )
                                                                                    }}
                                                                                    color="default"
                                                                                    name=""
                                                                                    checked={
                                                                                        organization?.showBanner
                                                                                    }
                                                                                />
                                                                            }
                                                                            label={
                                                                                'Show Site Banner'
                                                                            }
                                                                        />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <div class="custom-theme-selector">
                                                                        <FormControl
                                                                            className={
                                                                                classes.textFieldForm
                                                                            }>
                                                                            <InputLabel>
                                                                                Site Banner Background Color
                                                                            </InputLabel>
                                                                            {this.renderBannerBackgroundColorSelector()}
                                                                        </FormControl>
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <div class="custom-theme-selector">
                                                                        <FormControl
                                                                            className={
                                                                                classes.textFieldForm
                                                                            }>
                                                                            <InputLabel>
                                                                                Site Banner Font Color
                                                                            </InputLabel>
                                                                            {this.renderBannerTextColorSelector()}
                                                                        </FormControl>
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-details-bannerTitle',
                                                                        'Site Banner Title',
                                                                        'Site Banner Title',
                                                                        'bannerTitle',
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-details-bannerText',
                                                                        'Site Banner Text',
                                                                        'Site Banner Text',
                                                                        'bannerText',
                                                                        false,
                                                                        'isValidBannerText',
                                                                        'errorTextBannerText'
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: primarycolor
                                                    }}></div>
                                                <ExpansionPanel
                                                    square
                                                    expanded={
                                                        expanded === 'panel2'
                                                    }
                                                    onChange={this.handleACCChange(
                                                        'panel2'
                                                    )}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    color: primarycolor,
                                                                    border: primarycolor
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }
                                                            class="accordian-card-heading">
                                                            <h5
                                                                style={{
                                                                    color: primarycolor
                                                                }}
                                                                class="font-12">
                                                                Custom Links
                                                            </h5>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={4}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-details-custom-link-name',
                                                                        'Custom Link Name',
                                                                        'Custom Link Name',
                                                                        'organizationCustomLinkName',
                                                                        false,
                                                                        'isValidOrgLinkName',
                                                                        'errorTextLinkName'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={8}
                                                                    lg={8}
                                                                    md={8}
                                                                    sm={12}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-details-custom-link-url',
                                                                        'Custom Link Url (http:// or https://)',
                                                                        'Custom Link Url (http:// or https://)',
                                                                        'organizationCustomLinkUrl',
                                                                        false,
                                                                        'isValidOrgLinkUrl',
                                                                        'errorTextLinkUrl'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-details-custom-link2-name',
                                                                        'Custom Link Name',
                                                                        'Custom Link Name',
                                                                        'organizationCustomLink2Name',
                                                                        false,
                                                                        'isValidOrgLink2Name',
                                                                        'errorTextLink2Name'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={8}
                                                                    lg={8}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-details-custom-link2-url',
                                                                        'Custom Link Url (http:// or https://)',
                                                                        'Custom Link Url (http:// or https://)',
                                                                        'organizationCustomLink2Url',
                                                                        false,
                                                                        'isValidOrgLink2Url',
                                                                        'errorTextLink2Url'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-details-custom-link3-name',
                                                                        'Custom Link Name',
                                                                        'Custom Link Name',
                                                                        'organizationCustomLink3Name',
                                                                        false,
                                                                        'isValidOrgLink3Name',
                                                                        'errorTextLink3Name'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={8}
                                                                    lg={8}
                                                                    md={8}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-details-custom-link3-url',
                                                                        'Custom Link Url (http:// or https://)',
                                                                        'Custom Link Url (http:// or https://)',
                                                                        'organizationCustomLink3Url',
                                                                        false,
                                                                        'isValidOrgLink3Url',
                                                                        'errorTextLink3Url'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-details-custom-link4-name',
                                                                        'Custom Link Name',
                                                                        'Custom Link Name',
                                                                        'organizationCustomLink4Name',
                                                                        false,
                                                                        'isValidOrgLink4Name',
                                                                        'errorTextLink4Name'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={8}
                                                                    lg={8}
                                                                    md={8}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-details-custom-link4-url',
                                                                        'Custom Link Url (http:// or https://)',
                                                                        'Custom Link Url (http:// or https://)',
                                                                        'organizationCustomLink4Url',
                                                                        false,
                                                                        'isValidOrgLink4Url',
                                                                        'errorTextLink4Url'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-details-custom-link5-name',
                                                                        'Custom Link Name',
                                                                        'Custom Link Name',
                                                                        'organizationCustomLink5Name',
                                                                        false,
                                                                        'isValidOrgLink5Name',
                                                                        'errorTextLink5Name'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={8}
                                                                    lg={8}
                                                                    md={6}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-details-custom-link5-url',
                                                                        'Custom Link Url (http:// or https://)',
                                                                        'Custom Link Url (http:// or https://)',
                                                                        'organizationCustomLink5Url',
                                                                        false,
                                                                        'isValidOrgLink5Url',
                                                                        'errorTextLink5Url'
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: primarycolor
                                                    }}></div>
                                                <ExpansionPanel
                                                    square
                                                    expanded={
                                                        expanded === 'panel3'
                                                    }
                                                    onChange={this.handleACCChange(
                                                        'panel3'
                                                    )}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: primarycolor,
                                                                    color: primarycolor
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }
                                                            class="accordian-card-heading">
                                                            <h5
                                                                style={{
                                                                    color: primarycolor
                                                                }}
                                                                class="font-12">
                                                                Company Address
                                                                Details
                                                            </h5>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={4}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-address-street',
                                                                        'Street',
                                                                        'Street',
                                                                        'companyAddressStreet',
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-address-region',
                                                                        'Region',
                                                                        'Region',
                                                                        'companyAddressRegion',
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-address-city',
                                                                        'Town/City',
                                                                        'Town/City',
                                                                        'companyAddressCity',
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-address-zipcode',
                                                                        'Zip Code',
                                                                        'Zip Code',
                                                                        'companyAddressZipCode',
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-address-country',
                                                                        'Country',
                                                                        'Country',
                                                                        'companyAddressCountry',
                                                                        false
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: primarycolor
                                                    }}></div>
                                                <ExpansionPanel
                                                    square
                                                    expanded={
                                                        expanded === 'panel4'
                                                    }
                                                    onChange={this.handleACCChange(
                                                        'panel4'
                                                    )}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: primarycolor,
                                                                    color: primarycolor
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }
                                                            class="accordian-card-heading">
                                                            <h5
                                                                style={{
                                                                    color: primarycolor
                                                                }}
                                                                class="font-12">
                                                                Admin Contact
                                                                Details
                                                            </h5>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={4}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-contact-firstname',
                                                                        'First Name',
                                                                        'First Name',
                                                                        'contactFirstName',
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-contact-lastname',
                                                                        'Last Name',
                                                                        'Last Name',
                                                                        'contactLastName',
                                                                        true
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-contact-email',
                                                                        'Email',
                                                                        'test@example.com',
                                                                        'contactEmail',
                                                                        false,
                                                                        'isValidEmail',
                                                                        'errorTextEmail'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-contact-phone',
                                                                        'Phone',
                                                                        '+1-541-754-3010',
                                                                        'contactPhoneNumber',
                                                                        false,
                                                                        'isValidPhone',
                                                                        'errorTextPhone'
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: primarycolor
                                                    }}></div>
                                                <ExpansionPanel
                                                    square
                                                    expanded={
                                                        expanded === 'panel5'
                                                    }
                                                    onChange={this.handleACCChange(
                                                        'panel5'
                                                    )}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: primarycolor,
                                                                    color: primarycolor
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }
                                                            class="accordian-card-heading">
                                                            <h5
                                                                style={{
                                                                    color: primarycolor
                                                                }}
                                                                class="font-12">
                                                                Web Credentials
                                                            </h5>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={4}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-eAdaptor-apiUrl',
                                                                        'API URL',
                                                                        'API URL',
                                                                        'eAdaptorApiUrl',
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-eAdaptor-companyCode',
                                                                        'Default Company Id',
                                                                        'Default Company Id',
                                                                        'eAdaptorCompanyCode',
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-eAdaptor-customsCompanyCode',
                                                                        'Default Customs Company Id',
                                                                        'Default Customs Company Id',
                                                                        'eAdaptorCustomsCompanyCode',
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-eAdaptor-enterpriseId',
                                                                        'Enterprise Id',
                                                                        'Enterprise Id',
                                                                        'eAdaptorEnterpriseId',
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-eAdaptor-serverId',
                                                                        'Server Id',
                                                                        'Server Id',
                                                                        'eAdaptorServerId',
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-eAdaptor-userName',
                                                                        'User Name',
                                                                        'User Name',
                                                                        'eAdaptorUserName',
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <FormControl>
                                                                        <InputLabel>
                                                                            User
                                                                            Password
                                                                        </InputLabel>
                                                                        <Input
                                                                            className={
                                                                                classes.textFieldForm
                                                                            }
                                                                            id="org-eAdaptor-password"
                                                                            type={
                                                                                showPassword
                                                                                    ? 'text'
                                                                                    : 'password'
                                                                            }
                                                                            autoComplete="on"
                                                                            onChange={e =>
                                                                                this.handleChange(
                                                                                    e,
                                                                                    'org-eAdaptor-password',
                                                                                    false
                                                                                )
                                                                            }
                                                                            value={
                                                                                eAdaptorPassword ||
                                                                                ''
                                                                            }
                                                                            endAdornment={
                                                                                <InputAdornment position="end">
                                                                                    <IconButton
                                                                                        aria-label="toggle password visibility"
                                                                                        onClick={
                                                                                            this
                                                                                                .handleClickShowPassword
                                                                                        }
                                                                                        onMouseDown={
                                                                                            this
                                                                                                .handleMouseDown
                                                                                        }>
                                                                                        {showPassword ? (
                                                                                            <Visibility />
                                                                                        ) : (
                                                                                            <VisibilityOff />
                                                                                        )}
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-eAdaptor-availableCompanyIds',
                                                                        'Available Company Ids',
                                                                        'Available Company Ids',
                                                                        'cargowiseCompanyCodes',
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                {/* <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <FormControl
                                                                        className={
                                                                            classes.textFieldForm
                                                                        }>
                                                                        <InputLabel>
                                                                            eAdaptor Authentication
                                                                            Method
                                                                        </InputLabel>
                                                                        {this.renderEAdaptorAuthSelector()}
                                                                    </FormControl>
                                                                </Grid> */}
                                                                {/* <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'org-eAdaptor-authUrl',
                                                                        'eAdaptor Authentication URL',
                                                                        'eAdaptor Authentication URL',
                                                                        'eAdaptorAuthUrl',
                                                                        false
                                                                    )}
                                                                </Grid> */}
                                                            </Grid>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: primarycolor
                                                    }}></div>
                                                <ExpansionPanel
                                                    square
                                                    expanded={
                                                        expanded === 'panel6'
                                                    }
                                                    onChange={this.handleACCChange(
                                                        'panel6'
                                                    )}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: primarycolor,
                                                                    color: primarycolor
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }
                                                            class="accordian-card-heading">
                                                            <h5
                                                                style={{
                                                                    color: primarycolor
                                                                }}
                                                                class="font-12">
                                                                Shipment Service
                                                            </h5>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={4}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'integration-url',
                                                                        'Url',
                                                                        'Enter Integration Url',
                                                                        'webServiceIntegrationUrl',
                                                                        false,
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <FormControl
                                                                        className={
                                                                            classes.textFieldForm
                                                                        }>
                                                                        <InputLabel>
                                                                            Shipment
                                                                            Service
                                                                        </InputLabel>
                                                                        {this.renderShipmentServiceSelector()}
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: primarycolor
                                                    }}></div>
                                                <ExpansionPanel
                                                    square
                                                    expanded={
                                                        expanded === 'panel7'
                                                    }
                                                    onChange={this.handleACCChange(
                                                        'panel7'
                                                    )}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: primarycolor,
                                                                    color: primarycolor
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }
                                                            class="accordian-card-heading">
                                                            <h5
                                                                style={{
                                                                    color: primarycolor
                                                                }}
                                                                class="font-12">
                                                                BI Group Details
                                                            </h5>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={4}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput2(
                                                                        'bigroupId',
                                                                        'GroupID',
                                                                        'Enter GroupID',
                                                                        'groupId',
                                                                        false,
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput2(
                                                                        'bigroupName',
                                                                        'Group Name',
                                                                        'Enter GroupName',
                                                                        'groupName',
                                                                        false,
                                                                        false
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: primarycolor
                                                    }}></div>
                                                <ExpansionPanel
                                                    square
                                                    expanded={
                                                        expanded === 'panel8'
                                                    }
                                                    onChange={this.handleACCChange(
                                                        'panel8'
                                                    )}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: primarycolor,
                                                                    color: primarycolor
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }
                                                            class="accordian-card-heading">
                                                            <h5
                                                                style={{
                                                                    color: primarycolor
                                                                }}
                                                                class="font-12">
                                                                Module Access
                                                            </h5>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={1}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                onChange={event => {
                                                                                    this.handleModuleChange(
                                                                                        'ShipmentSearch',
                                                                                        event
                                                                                            .target
                                                                                            .checked
                                                                                    )
                                                                                }}
                                                                                color="default"
                                                                                name="search"
                                                                                disabled={
                                                                                    !canSeeModuleAccess
                                                                                }
                                                                                checked={extractSearchShipment(
                                                                                    organization.modulePermissionArray
                                                                                )}
                                                                            />
                                                                        }
                                                                        label={
                                                                            'Search Shipment'
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                onChange={event => {
                                                                                    this.handleModuleChange(
                                                                                        'ShipmentCreate',
                                                                                        event
                                                                                            .target
                                                                                            .checked
                                                                                    )
                                                                                }}
                                                                                color="default"
                                                                                disabled={
                                                                                    !canSeeModuleAccess
                                                                                }
                                                                                name="search"
                                                                                checked={extractCreateShipment(
                                                                                    organization.modulePermissionArray
                                                                                )}
                                                                            />
                                                                        }
                                                                        label={
                                                                            'Create Shipment'
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                onChange={event => {
                                                                                    this.handleModuleChange(
                                                                                        'Customs',
                                                                                        event
                                                                                            .target
                                                                                            .checked
                                                                                    )
                                                                                }}
                                                                                color="default"
                                                                                disabled={
                                                                                    !canSeeModuleAccess
                                                                                }
                                                                                name="search"
                                                                                checked={extractSeeCustoms(
                                                                                    organization.modulePermissionArray
                                                                                )}
                                                                            />
                                                                        }
                                                                        label={
                                                                            'Customs'
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                onChange={event => {
                                                                                    this.handleModuleChange(
                                                                                        'Accounting',
                                                                                        event
                                                                                            .target
                                                                                            .checked
                                                                                    )
                                                                                }}
                                                                                color="default"
                                                                                disabled={
                                                                                    !canSeeModuleAccess
                                                                                }
                                                                                name="search"
                                                                                checked={extractSeeAccounting(
                                                                                    organization.modulePermissionArray
                                                                                )}
                                                                            />
                                                                        }
                                                                        label={
                                                                            'Accounting'
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                onChange={event => {
                                                                                    this.handleModuleChange(
                                                                                        'Booking',
                                                                                        event
                                                                                            .target
                                                                                            .checked
                                                                                    )
                                                                                }}
                                                                                color="default"
                                                                                disabled={
                                                                                    !canSeeModuleAccess
                                                                                }
                                                                                name="search"
                                                                                checked={extractSeeBooking(
                                                                                    organization.modulePermissionArray
                                                                                )}
                                                                            />
                                                                        }
                                                                        label={
                                                                            'Bookings'
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                onChange={event => {
                                                                                    this.handleModuleChange(
                                                                                        'Order',
                                                                                        event
                                                                                            .target
                                                                                            .checked
                                                                                    )
                                                                                }}
                                                                                color="default"
                                                                                name="search"
                                                                                disabled={
                                                                                    !canSeeModuleAccess
                                                                                }
                                                                                checked={extractSeeOrder(
                                                                                    organization.modulePermissionArray
                                                                                )}
                                                                            />
                                                                        }
                                                                        label={
                                                                            'Orders'
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                onChange={event => {
                                                                                    this.handleModuleChange(
                                                                                        'CreateOrder',
                                                                                        event
                                                                                            .target
                                                                                            .checked
                                                                                    )
                                                                                }}
                                                                                color="default"
                                                                                name="search"
                                                                                disabled={
                                                                                    !canSeeModuleAccess
                                                                                }
                                                                                checked={extractCreateOrder(
                                                                                    organization.modulePermissionArray
                                                                                )}
                                                                            />
                                                                        }
                                                                        label={
                                                                            'Create Order'
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                onChange={event => {
                                                                                    this.handleModuleChange(
                                                                                        'Container',
                                                                                        event
                                                                                            .target
                                                                                            .checked
                                                                                    )
                                                                                }}
                                                                                color="default"
                                                                                name="search"
                                                                                disabled={
                                                                                    !canSeeModuleAccess
                                                                                }
                                                                                checked={extractSeeContainers(
                                                                                    organization.modulePermissionArray
                                                                                )}
                                                                            />
                                                                        }
                                                                        label={
                                                                            'Containers'
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: primarycolor
                                                    }}></div>
                                                <ExpansionPanel
                                                    square
                                                    expanded={
                                                        expanded === 'panel9'
                                                    }
                                                    onChange={this.handleACCChange(
                                                        'panel9'
                                                    )}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: primarycolor,
                                                                    color: primarycolor
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }
                                                            class="accordian-card-heading">
                                                            <h5
                                                                style={{
                                                                    color: primarycolor
                                                                }}
                                                                class="font-12">
                                                                Report Access
                                                            </h5>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={4}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={12}
                                                                    lg={12}
                                                                    md={12}
                                                                    sm={12}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {displayNoReports && (
                                                                        <div>
                                                                            No
                                                                            matching
                                                                            data
                                                                            found
                                                                        </div>
                                                                    )}
                                                                    {reportAccessList.map(
                                                                        report => (
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        onChange={event => {
                                                                                            this.handleReportChange(
                                                                                                report.reportId,
                                                                                                event
                                                                                                    .target
                                                                                                    .checked
                                                                                            )
                                                                                        }}
                                                                                        color="default"
                                                                                        name=""
                                                                                        checked={
                                                                                            report.isChecked
                                                                                        }
                                                                                    />
                                                                                }
                                                                                label={
                                                                                    report.reportName
                                                                                }
                                                                            />
                                                                        )
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: primarycolor
                                                    }}></div>
                                                <ExpansionPanel
                                                    square
                                                    expanded={
                                                        expanded === 'panel10'
                                                    }
                                                    onChange={this.handleACCChange(
                                                        'panel10'
                                                    )}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: primarycolor,
                                                                    color: primarycolor
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }
                                                            class="accordian-card-heading">
                                                            <h5
                                                                style={{
                                                                    color: primarycolor
                                                                }}
                                                                class="font-12">
                                                                Dashboard Access
                                                            </h5>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={4}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {displayNoDashboards && (
                                                                        <div>
                                                                            No
                                                                            matching
                                                                            data
                                                                            found
                                                                        </div>
                                                                    )}
                                                                    {dashboardAccessList.map(
                                                                        dashboard => (
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        onChange={event => {
                                                                                            this.handleDashboardChange(
                                                                                                dashboard.dashboardId,
                                                                                                event
                                                                                                    .target
                                                                                                    .checked
                                                                                            )
                                                                                        }}
                                                                                        color="default"
                                                                                        name=""
                                                                                        checked={
                                                                                            dashboard.isChecked
                                                                                        }
                                                                                    />
                                                                                }
                                                                                label={
                                                                                    dashboard.dashboardName
                                                                                }
                                                                            />
                                                                        )
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: primarycolor
                                                    }}></div>
                                                <ExpansionPanel
                                                    square
                                                    expanded={
                                                        expanded === 'panel18'
                                                    }
                                                    onChange={this.handleACCChange(
                                                        'panel18'
                                                    )}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: primarycolor,
                                                                    color: primarycolor
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }
                                                            class="accordian-card-heading">
                                                            <h5
                                                                checkOn={false}
                                                                style={{
                                                                    color: primarycolor
                                                                }}>
                                                                <CardTitle
                                                                    title=""
                                                                    class="p-0"></CardTitle>{' '}
                                                                Module Options
                                                            </h5>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={4}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={12}
                                                                    lg={12}
                                                                    md={12}
                                                                    sm={12}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    onChange={event => {
                                                                                        this.handleCheckboxChange(
                                                                                            event,
                                                                                            'usesOpenTrack'
                                                                                        )
                                                                                    }}
                                                                                    color="default"
                                                                                    name=""
                                                                                    checked={
                                                                                        organization?.isOpenTrack || false
                                                                                    }
                                                                                />
                                                                            }
                                                                            label={
                                                                                'Use OpenTrack Container Data'
                                                                            }
                                                                        />
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    onChange={event => {
                                                                                        this.handleCheckboxChange(
                                                                                            event,
                                                                                            'usesOpenTrackTracking'
                                                                                        )
                                                                                    }}
                                                                                    color="default"
                                                                                    name=""
                                                                                    checked={
                                                                                        organization?.isOpenTrackTracking || false
                                                                                    }
                                                                                />
                                                                            }
                                                                            label={
                                                                                'Use OpenTrack Tracking Link'
                                                                            }
                                                                        />
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: primarycolor
                                                    }}></div>
                                                <ExpansionPanel
                                                    square
                                                    expanded={
                                                        expanded === 'panel19'
                                                    }
                                                    onChange={this.handleACCChange(
                                                        'panel19'
                                                    )}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: primarycolor,
                                                                    color: primarycolor
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }
                                                            class="accordian-card-heading">
                                                            <h5
                                                                style={{
                                                                    color: primarycolor
                                                                }}
                                                                class="font-12">
                                                                Demo Mode Settings
                                                            </h5>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={4}
                                                                className="mb-20">
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'demo-supplier-address-name',
                                                                        'Demo Supplier Name',
                                                                        'Supplier Name',
                                                                        'demoSupplierAddressName',
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'demo-supplier-address-street',
                                                                        'Demo Supplier Street',
                                                                        'Supplier Street',
                                                                        'demoSupplierAddressStreet',
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'demo-supplier-address-city',
                                                                        'Demo Supplier Town/City',
                                                                        'Supplier Town/City',
                                                                        'demoSupplierAddressCity',
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'demo-supplier-address-zipcode',
                                                                        'Demo Supplier Zip Code',
                                                                        'Supplier Zip Code',
                                                                        'demoSupplierAddressZipCode',
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'demo-supplier-address-country',
                                                                        'Demo Supplier Country',
                                                                        'Supplier Country',
                                                                        'demoSupplierAddressCountry',
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'demo-supplier-address-countrycode',
                                                                        'Demo Supplier Country Code',
                                                                        'Supplier Country Code',
                                                                        'demoSupplierAddressCountryCode',
                                                                        false
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={4}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'demo-buyer-address-name',
                                                                        'Demo Buyer Name',
                                                                        'Buyer Name',
                                                                        'demoBuyerAddressName',
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'demo-buyer-address-street',
                                                                        'Demo Buyer Street',
                                                                        'Buyer Street',
                                                                        'demoBuyerAddressStreet',
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'demo-buyer-address-city',
                                                                        'Demo Buyer Town/City',
                                                                        'Buyer Town/City',
                                                                        'demoBuyerAddressCity',
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'demo-buyer-address-zipcode',
                                                                        'Demo Buyer Zip Code',
                                                                        'Buyer Zip Code',
                                                                        'demoBuyerAddressZipCode',
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'demo-buyer-address-country',
                                                                        'Demo Buyer Country',
                                                                        'Buyer Country',
                                                                        'demoBuyerAddressCountry',
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'demo-buyer-address-countrycode',
                                                                        'Demo Buyer Country Code',
                                                                        'Buyer Country Code',
                                                                        'demoBuyerAddressCountryCode',
                                                                        false
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: primarycolor
                                                    }}></div>
                                                <ExpansionPanel
                                                    square
                                                    expanded={
                                                        expanded === 'panel11'
                                                    }
                                                    onChange={this.handleACCChange(
                                                        'panel11'
                                                    )}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: primarycolor,
                                                                    color: primarycolor
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }
                                                            class="accordian-card-heading">
                                                            <h5
                                                                style={{
                                                                    color: primarycolor
                                                                }}
                                                                class="font-12">
                                                                Sync Status
                                                            </h5>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <Paper elevation={0}>
                                                                            <MUIDataTable
                                                                            title={''}
                                                                            columns={syncStatusTableHeadCells}
                                                                            options={syncStatusTableOptions}
                                                                            data={adfSyncStatusArray ? adfSyncStatusArray : []}
                                                                        />
                                                                    </Paper>
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: primarycolor
                                                    }}></div>
                                            </div>
                                            <div class="text-right mt-4">
                                                <Button
                                                    style={{
                                                        background: primarycolor,
                                                        color: textColor
                                                    }}
                                                    type="submit"
                                                    className={classes.submit}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={e =>
                                                        this.handleSubmit(e)
                                                    }
                                                    disabled={
                                                        !this.state.isValidForm
                                                    }>
                                                    Save
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </form>
                    </Paper>
                </CardContent>

                <Dialog
                    open={openSyncHistoryView}
                    onClose={() => handleSyncHistoryViewClickClose()}
                    maxWidth={"xl"}
                    fullWidth
                    >                  
                    <DialogContent>
                        <MUIDataTable
                        title={'Sync History'}
                        columns={[
                            { name: "logId", label: "Id" },
                            { name: "syncId", label: "Sync Id" },
                            { name: "syncName", label: "Sync Name" },
                            { name: "syncError", label: "Sync Error" },
                            { name: "syncStatus", label: "Sync Message" },
                            { name: "syncDate", label: "Last Sync Date", options: {
                                customBodyRender: (value) => {return (value !== null ? new Date(
                                    value
                                ).toLocaleString() : '')}} 
                            },
                        ]}
                        data={syncHistoryData}
                        options={{
                            filter: true,
                            sort: true,
                            pagination: true,
                            rowsPerPage: 10,
                            selectableRows: false,
                        }}
                        />
                    </DialogContent>
                </Dialog>
            </div>
        )
    }

    async populateDateFormatOptions() { 
        const token = await authService.getAccessToken()
        const response = await fetch('api/dataManagement/GetDateFormats', {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        })
        if (response.ok) {
            const data = await response.json()
            this.setState({
                dateFormatOptions: data
            })
        }
    }

    async populateOrganizationData() {
        const token = await authService.getAccessToken()
        const response = await fetch('api/organizations/GetOrganization', {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        })
        if (response.ok) {
            const data = await response.json()
            this.setState({
                organization: data,
                orgId: data.id,
                eAdaptorPassword: data.eAdaptorPassword,
                adfSyncStatusArray: data.adfSyncStatusArray
            }, () => {
                this.populateOrganizationBIGroupData(data)
            })
        }
    }
    async getSyncHistoryData(rowClickedData) {
        var syncId = rowClickedData[2] // extractID/syncID
        const token = await authService.getAccessToken()
        const response = await fetch('api/organizations/GetSyncHistory?syncId=' + syncId, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
            method: 'GET'
        })
        if (response.ok) 
        {
            const syncHistoryData = await response.json()
            return syncHistoryData
        }
    }

    //to get the organizations->reports
    async getOrgReportsData(id) {
        const token = await authService.getAccessToken()
        const response = await fetch(
            'api/bireport/GetOrganizationBIReports/' + id,
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }
        )
            .then(response => response.json())
            .then(data => {
                if (data == null) {
                    data = []
                }
                this.setState({
                    reportAccessList: data
                })

                if (data.length == 0) {
                    this.setState({
                        displayNoReports: true
                    })
                }
            })
            .catch(error => {
                this.setState({
                    reportAccessList: []
                })
                this.setState({
                    displayNoReports: true,
                    isPosting: false
                })
            })
    }

    //to get the organizations->dashboards
    async getOrgDashboardsData(id) {
        const token = await authService.getAccessToken()
        const response = await fetch(
            'api/bidashboard/GetOrganizationBIDashboards/' + id,
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }
        )
            .then(response => response.json())
            .then(data => {
                if (data == null) {
                    data = []
                }
                this.setState({
                    dashboardAccessList: data
                })
                if (data.length == 0) {
                    this.setState({
                        displayNoDashboards: true
                    })
                }
            })
            .catch(error => {
                this.setState({
                    dashboardAccessList: []
                })
                this.setState({
                    displayNoDashboards: true,
                    isPosting: false
                })
            })
    }

    //to save the org->reports
    async saveOrgReportsData() {
        const token = await authService.getAccessToken()
        const { orgId } = this.state
        const { reportAccessList } = this.state
        let list = []
        reportAccessList.map(x => {
            if (x.isChecked) {
                list.push({
                    PSAOrganizationId: orgId,
                    ReportID: x.reportId,
                    ReportName: x.reportName
                })
            }
        })
        let data = {
            PSAOrganizationId: orgId,
            Reports: list
        }
        let errText = ''
        await fetch('api/organizations/SaveOrganizationBIReports', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    // do nothing
                } else {
                    errText = 'Code[' + response.status + ']'

                    this.setState(prevState => {
                        let alert = Object.assign({}, prevState.alert)
                        alert.open = true
                        alert.success = false
                        alert.message =
                            'Sorry, there was an error while saving these details.' +
                            errText
                        return { alert }
                    })
                }
            })

            .then(d => {
                this.setState({
                    isPosting: false
                })
            })
            .catch(error => {
                this.setState(prevState => {
                    let alert = Object.assign({}, prevState.alert)
                    alert.open = true
                    alert.success = false
                    alert.message =
                        'Sorry, there was an error while saving these details.'
                    return { alert }
                })
                this.setState({
                    isPosting: false
                })
            })
    }
    //to save the org->dashboards
    async saveOrgDashboardsData() {
        const token = await authService.getAccessToken()
        const { dashboardAccessList } = this.state
        const { orgId } = this.state
        let list = []
        dashboardAccessList.map(x => {
            if (x.isChecked) {
                list.push({
                    PSAOrganizationId: orgId,
                    DashboardID: x.dashboardId,
                    DashboardName: x.dashboardName
                })
            }
        })
        let data = {
            PSAOrganizationId: orgId,
            Dashboards: list
        }
        let errText = ''
        await fetch('api/organizations/SaveOrganizationBIDashboards', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    // do nothing
                } else {
                    errText = 'Code[' + response.status + ']'

                    this.setState(prevState => {
                        let alert = Object.assign({}, prevState.alert)
                        alert.open = true
                        alert.success = false
                        alert.message =
                            'Sorry, there was an error while saving these details.' +
                            errText
                        return { alert }
                    })
                }
            })

            .then(d => {
                this.setState({
                    isPosting: false
                })
            })
            .catch(error => {
                this.setState(prevState => {
                    let alert = Object.assign({}, prevState.alert)
                    alert.open = true
                    alert.success = false
                    alert.message =
                        'Sorry, there was an error while saving these details.'
                    return { alert }
                })
                this.setState({
                    isPosting: false
                })
            })
    }
    handleACCChange = panel => (event, expanded) => {
        this.setState({ expanded: expanded ? panel : false })
    }

    //to get the BI-group details
    async populateOrganizationBIGroupData(organization) {
        const token = await authService.getAccessToken()
        const response = await fetch(
            'api/organizations/GetOrganizationGroupDetails',
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }
        )
        if (response.ok) {
            const data = await response.json()
            this.setState({
                groupdata: data
            })
            if (data.groupId != null && data.groupId != '') {
                this.getOrgReportsData(organization.id)
                this.getOrgDashboardsData(organization.id)
            } else {
                this.setState({
                    reportAccessList: [],
                    dashboardAccessList: [],
                    displayNoReports: true,
                    displayNoDashboards: true
                })
            }
        }
    }

    //to update/save the BI-group details
    async updateOrganizationBIGroupData(data) { 
        this.setState({ isPosting: true })
        const { organization } = this.state
        let data_ = {
            GroupId: data.groupId,
            GroupName: data.groupName,
            Id: data.id
        }
        const url = 'api/organizations/PutOrganizationBIGroup'

        const token = await authService.getAccessToken()
        const header = {
            'content-type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${token}`,
            Accept: 'application/json, text/plain, */*'
        }

        await fetch(url, { 
            method: 'PUT',
            headers: header,
            body: JSON.stringify(data_)
        })
            .then(response => {
                if (response.ok) {
                    this.setState({
                        isPosting: false
                    })
                }
            })
            .then(d => {
                this.setState({
                    isPosting: false
                })
            })
            .catch(error => {
                this.setState(prevState => {
                    let alert = Object.assign({}, prevState.alert)
                    alert.open = true
                    alert.success = false
                    alert.message =
                        'Sorry, there was an error while saving this organization.'
                    return { alert }
                })
            })
    }

    async updateOrganizationData(data, updLogo) {
        this.setState({ isPosting: true })
        let errText = ''
        const url =
            'api/organizations' + (updLogo ? '/UpdateOrganizationLogo' : '')

        const token = await authService.getAccessToken()
        const header = {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json, text/plain, */*'
        }
        if (!updLogo) {
            header['Content-Type'] = 'application/json'
        }

        await fetch(url, {
            method: 'PUT',
            headers: header,
            body: updLogo ? data : JSON.stringify(data)
        })
            .then(async response => {
                let results = null
                if (response.ok) {
                    this.setState(prevState => {
                        let alert = Object.assign({}, prevState.alert)
                        alert.open = true
                        alert.success = true
                        alert.message = 'Organization information is saved.'
                        return { alert }
                    })
                    results = response.json()

                    setInterval(function() {
                        window.location.reload()
                    }, 2000)
                } else {
                    const responseText = await response.text()
                    results = responseText
                    errText = 'Code[' + response.status + '] ' + responseText

                    this.setState(prevState => {
                        let alert = Object.assign({}, prevState.alert)
                        alert.open = true
                        alert.success = false
                        alert.message =
                            'Sorry, there was an error while saving these details.' +
                            errText
                        return { alert }
                    })
                }
                return results
            })
            .then(d => {
                if (!updLogo) {
                    this.setState({
                        organization: d ? d : data
                    })
                }
                this.setState({
                    updLogo: false,
                    isPosting: false
                })
            })
            .catch(error => {
                this.setState({
                    updLogo: false,
                    isPosting: false
                })

                this.setState(prevState => {
                    let alert = Object.assign({}, prevState.alert)
                    alert.open = true
                    alert.success = false
                    alert.message =
                        'Sorry, there was an error while saving this organization | ' +
                        error
                    return { alert }
                })
            })
    }
}
export default withStyles(styles)(OrganizationDetails)
