import React, { PureComponent, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'
import authService from './api-authorization/AuthorizeService'
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants'
import { TextField, Tooltip, LinearProgress } from '@material-ui/core'
import { FormControl, InputLabel } from '@material-ui/core'
import { MenuItem, Select, Grid, Button } from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import Alert from '@material-ui/lab/Alert'
import { renderMap } from '../components/shared/MapHelper'
import { getIconForShipment } from '../components/shared/MapHelper'
import DateFnsUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { withStyles } from '@material-ui/core/styles'
import { CardContent } from '@material-ui/core'
import SummaryInfo from './shared/SummaryInfo'
import MaterialTable from 'material-table'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import { Paper } from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import FlagIcon from '@material-ui/icons/Flag'
import localforage from 'localforage'
import { sortData } from '../utils/sorting'
import { prepareCsvExport } from '../utils/exporting'

const styles = theme => ({
    formControl: {
        margin: 0,
        minWidth: '170px',
        fullWidth: true,
        display: 'flex',
        wrap: 'nowrap'
    },
    link: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    }
})

const mapContainerStyle = {
    height: '100%',
    width: '100%'
}

const dateFns = new DateFnsUtils()

class PickupTable extends PureComponent {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
        this.state = {
            items: [],
            shipmentList: [],
            clients: [],
            clientId: null,
            organization: null,
            selectedDate: 2,
            toDate: dateFns.moment().weekday(6),
            fromDate: dateFns.moment().weekday(0),
            minDate: dateFns.moment().weekday(0),
            todayDate: new Date(),
            loading: true,
            error: false,
            rowsPerPage: 50,
            page: 0,
            order: 'asc',
            orderBy: 'shipmentNumber',
            resultsBox1Value: 0,
            resultsBox1Title: 'Total Container',
            resultsBox2Value: 0,
            resultsBox2Title: 'Arrived',
            resultsBox3Value: 0,
            resultsBox3Title: 'Available',
            resultsBox4Value: 0,
            resultsBox4Title: 'Delivered',
            resultsBox5Value: 0,
            resultsBox5Title: null,
            lastCompletedMilestone: 'ALL',
            referenceType: 'SHIPMENT ID',
            dateView: 'ALL',
            itemReference: null,
            mode: 'ALL',
            theme: props.theme,
            searchItem: props.searchItem || 'Pickup Management',
            titleIcon: props.titleIcon || 'assignment_turned_in',
            key: props.itemKey || 'shipment',
            originUNLOCOCodeObject: null,
            originName: null,
            originCountryCodeObject: null,
            destinationUNLOCOCodeObject: null,
            destinationName: null,
            destinationCountryCodeObject: null,
            originUnlocoCodes: [],
            originCountryCodes: [],
            destinationUnlocoCodes: [],
            destinationCountryCodes: [],
            downloadCSVDisabled: false,
            displayAdvancedSearchProperties: false,
            searching: false,
            shipmentFilters: [],
            orgHeaderColumns: [],
            currentSummaryTile: 1,
            dateFiltering: []
        }
    }

    async componentDidMount() {
        this.setState({ loading: true })
        var referredFromDetailPage = await JSON.parse(
            localStorage.getItem('referredFromDetailPage')
        )

        if (referredFromDetailPage) {
            await this.loadAndSetFromLocalForage('organization')
            await this.loadAndSetFromLocalForage('clients')
        } else {
            await this.populateConfig()
            await this.populateClientsData()
        }

        await this.loadAndSetFromLocalStorage('clientId')
        await this.initSearch()
        this.setState({ loading: false })
    }

    async initSearch() {
        // only load search from cache if navigating back from detail page
        var referredFromDetailPage = await JSON.parse(
            localStorage.getItem('referredFromDetailPage')
        )

        if (referredFromDetailPage) {
            await this.loadAndSetFromLocalForageNoKey('shipmentFilters')
            await this.loadAndSetFromLocalStorage('resultsBox1Value')
            await this.loadAndSetFromLocalStorage('resultsBox1Title')
            await this.loadAndSetFromLocalStorage('resultsBox2Value')
            await this.loadAndSetFromLocalStorage('resultsBox2Title')
            await this.loadAndSetFromLocalStorage('resultsBox3Value')
            await this.loadAndSetFromLocalStorage('resultsBox3Title')
            await this.loadAndSetFromLocalStorage('resultsBox4Value')
            await this.loadAndSetFromLocalStorage('resultsBox4Title')
            await this.loadAndSetFromLocalStorage('resultsBox5Value')
            await this.loadAndSetFromLocalStorage('resultsBox5Title')
            await this.loadAndSetFromLocalForage('shipmentList')
            await this.loadAndSetFromLocalForage('items')
            await this.loadAndSetFromLocalStorage('currentSummaryTile')
            // reset 'referred' value in local storage
            localStorage.setItem(
                'referredFromDetailPage',
                JSON.stringify(false)
            )
        } else {
            // clear shipmentfilters?
        }

        await this.loadAndSetFromLocalStorage('itemReference')
        await this.loadAndSetFromLocalStorage('mode')
        await this.loadAndSetFromLocalStorage('referenceType')
        await this.loadAndSetFromLocalStorage('dateView')
        await this.loadAndSetFromLocalStorage('selectedDate')
        await this.loadAndSetFromLocalStorage('fromDate')
        await this.loadAndSetFromLocalStorage('toDate')

        const { selectedDate } = this.state
        this.setDatesFromDateDropdown(selectedDate)
    }
    

    async loadAndSetFromLocalStorage(keyName, jsonParse = false) {
        const { key } = this.state
        const localStorageKey = `${key}.${keyName}`
        const value = jsonParse
            ? await JSON.parse(localStorage.getItem(localStorageKey))
            : await localStorage.getItem(localStorageKey)

        if (value !== null) {
            this.setState({ [`${keyName}`]: value }, () => {
                if (keyName === 'selectedDate') {
                    this.setDatesFromDateDropdown(value)
                }
            })
        }
    }

    async loadAndSetFromLocalForage(keyName) {
        const { key } = this.state
        const localForageKey = `${key}.${keyName}`
        const value = await localforage.getItem(localForageKey)
        if (value !== null) {
            this.setState({ [`${keyName}`]: value })
        }
    }

    async loadAndSetFromLocalForageNoKey(keyName) {
        const value = await localforage.getItem(keyName)
        if (value !== null) {
            this.setState({ [`${keyName}`]: value })
        }
    }

    saveShipmentFilterValuesToState = tableRef => {
        const newColumnFilters = tableRef.current.state.columns.map(column => ({
            field: column.field,
            filterValue: column.tableData.filterValue
        }))
        localforage.setItem('shipmentFilters', newColumnFilters)
        this.setState({ shipmentFilters: newColumnFilters })
    }

    getURLForItem = itemvalue => {
        const { clientId, key } = this.state

        var initialPath = `${ApplicationPaths.Shipments}`
        if (key === 'customs') {
            initialPath = `${ApplicationPaths.Customs}`
        } else if (key === 'booking') {
            initialPath = `${ApplicationPaths.Bookings}`
        }

        return {
            pathname: initialPath + '/' + clientId + '/' + itemvalue
        }
    }

    handleSubmit = async event => {
        this.saveShipmentFilterValuesToState(this.tableRef)
        this.populateTableData(event)
    }

    handleClearAll = async event => {
        for (let [key, value] of Object.entries(localStorage)) {
            if (key.indexOf(`${this.state.key}.`) !== -1) {
                localStorage.removeItem(key)
            }
        }

        localStorage.removeItem('referredFromDetailPage')

        var lfKeys = []

        await localforage
            .keys()
            .then(function(keys) {
                lfKeys = keys
            })
            .catch(function(err) {
                console.log(err)
            })

        for (let lfKey of lfKeys) {
            if (lfKey.indexOf(this.state.key + '.') !== -1) {
                await localforage
                    .removeItem(lfKey)
                    .then(function() {
                        // nothing
                    })
                    .catch(function(err) {
                        console.log(err)
                    })
            }
        }

        window.location.reload()
    }

    setDatesFromDateDropdown = value => {
        let from = null
        let to = null
        const { key, fromDate, toDate } = this.state

        switch (parseInt(value)) {
            case 0: // Today
                from = dateFns.moment().startOf('day')
                to = dateFns.moment().endOf('day')
                break
            case 1: // This Week
                from = dateFns.moment().startOf('week')
                to = dateFns.moment().endOf('week')
                break
            case 2: // This Month
                from = dateFns.moment.utc().startOf('month')
                to = dateFns.moment.utc().endOf('month')
                break
            case 3: // This Year
                from = dateFns.moment.utc().startOf('year')
                to = dateFns.moment.utc().endOf('year')
                break
            case 4: // Next Week
                let nwDate = dateFns.moment.utc(new Date()).add(1, 'week')
                from = dateFns.moment(nwDate).startOf('week')
                to = dateFns.moment(nwDate).endOf('week')
                break
            case 5: // Next Month
                let nmDate = dateFns.moment.utc(new Date()).add(1, 'month')
                from = dateFns.moment(nmDate).startOf('month')
                to = dateFns.moment(nmDate).endOf('month')
                break
            case 6: // Next Year
                let nyDate = dateFns.moment.utc(new Date()).add(1, 'year')
                from = dateFns.moment(nyDate).startOf('year')
                to = dateFns.moment(nyDate).endOf('year')
                break
            case 7: // Last Week
                let lwDate = dateFns.moment.utc(new Date()).subtract(1, 'week')
                from = dateFns.moment(lwDate).startOf('week')
                to = dateFns.moment(lwDate).endOf('week')
                break
            case 8: // Last Month
                let lmDate = dateFns.moment.utc(new Date()).subtract(1, 'month')
                from = dateFns.moment(lmDate).startOf('month')
                to = dateFns.moment(lmDate).endOf('month')
                break
            case 9: // Last Year
                let lyDate = dateFns.moment.utc(new Date()).subtract(1, 'year')
                from = dateFns.moment(lyDate).startOf('year')
                to = dateFns.moment(lyDate).endOf('year')
                break
            case 10: // All Time
                break
            case 11: // Custom
                from = dateFns.moment(fromDate)
                to = dateFns.moment(toDate)
                break
            default:
                break
        }

        localStorage.setItem(key + '.fromDate', from)
        localStorage.setItem(key + '.toDate', to)

        this.setState({
            selectedDate: value,
            fromDate: from,
            toDate: to
        })
    }

    handleChangeValue = (event, elem) => {
        let value = null
        const { key } = this.state

        const customIndex = 10

        switch (elem) {
            case 'fromDate':
                this.setState({ fromDate: event, selectedDate: customIndex })
                localStorage.setItem(key + '.selectedDate', customIndex)
                localStorage.setItem(key + '.fromDate', event)
                break

            case 'toDate':
                this.setState({ toDate: event, selectedDate: customIndex })
                localStorage.setItem(key + '.selectedDate', customIndex)
                localStorage.setItem(key + '.toDate', event)
                break

            case 'selectedDate':
                value = event.target.value
                localStorage.setItem(key + '.selectedDate', value)
                this.setDatesFromDateDropdown(value)
                break

            case 'dateView':
                value = event ? event.target.value : event
                localStorage.setItem(key + '.dateView', value)
                this.setState({
                    dateView: value
                })
                break

            case 'reference-type':
                value = event ? event.target.value : event
                localStorage.setItem(key + '.referenceType', value)
                this.setState({
                    referenceType: value
                })
                break

            case 'client':
                value = event ? event.target.value : event
                localStorage.setItem(key + '.clientId', value)
                this.setState({
                    clientId: value
                })
                break

            case 'mode':
                value = event ? event.target.value : event
                localStorage.setItem(key + '.mode', value)
                this.setState({
                    mode: value
                })
                break

            case 'reference':
                value = event ? event.target.value : event
                localStorage.setItem(key + '.itemReference', value)
                this.setState({
                    itemReference: value
                })
                break

            default:
                break
        }
    }

    getDateObject = (time, substract = false) => {
        var moment = require('moment')
        const format = 'YYYY-MM-DD HH:mm:ss'
        var base = substract ? moment().substract(1, 'month') : moment()
        return {
            From: base.startOf(time).format(format),
            To: base.endOf(time).format(format)
        }
    }

    getDates = () => {
        const { selectedDate } = this.state

        if (selectedDate === 0) {
            return this.getDateObject('day')
        } else if (selectedDate === 1) {
            return this.getDateObject('week')
        } else if (selectedDate === 2) {
            return this.getDateObject('month')
        } else if (selectedDate === 3) {
            return this.getDateObject('month', true)
        } else if (selectedDate === 4) {
            return this.getDateObject('year')
        }

        return this.getDateObject('day')
    }

    renderToolTipOrderRef = orderRefValue => {
        var orderRef = ''
        var array = []
        if (orderRefValue != null) {
            array = orderRefValue.split(',')
            if (array.length <= 2) {
                orderRef = array.join()
            } else {
                orderRef = array[0]
            }
        }

        return (
            <p>
                {orderRef}
                {array.length > 2 ? (
                    <Tooltip
                        title={
                            <>
                                <h6>{array.join()}</h6>
                            </>
                        }
                        placement="right">
                        <MoreHorizIcon />
                    </Tooltip>
                ) : (
                    ''
                )}
            </p>
        )
    }

    renderNavLinkOrderRef = (clientId, shipmentData) => {
        var orderRefs = shipmentData.orderReferenceNumber
        if (orderRefs != null) {
            return (
                <>
                    {orderRefs.split(',').map((refnum, index) => {
                        if (shipmentData.orders) {
                            var order = shipmentData.orders.find(
                                order => order.orderNumber === refnum.trim()
                            )
                            if (order) {
                                return (
                                    <div>
                                        <NavLink
                                            onClick={() => {
                                                this.saveShipmentFilterValuesToState(
                                                    this.tableRef
                                                )
                                            }}
                                            key={index}
                                            to={
                                                '/orders/' +
                                                clientId +
                                                '/' +
                                                btoa(refnum.trim()).replace(
                                                    '/',
                                                    '_'
                                                ) +
                                                '/' +
                                                order.orderNumberSplit
                                            }>
                                            {refnum}
                                        </NavLink>
                                    </div>
                                )
                            } else {
                                return <div>{refnum}</div>
                            }
                        } else {
                            return <div>{refnum}</div>
                        }
                    })}
                </>
            )
        } else {
            return <>-</>
        }
    }

    renderShipmentsTable = items => {
        const {
            organization,
            key,
            searchItem,
            clientId,
            clients,
            shipmentFilters,
            orgHeaderColumns
        } = this.state
        const currentClient = clients.find(e => e.id === clientId)

        let cachedHeaderKeyValue = ''
        cachedHeaderKeyValue =
            currentClient.pickupTableConfigurationArray.length !== 0
                ? 'columnHeaders'.concat('_', clientId)
                : 'columnHeaders'

        const extraCells =
            key === 'booking'
                ? [
                      {
                          title: 'Booking Date',
                          value: 'bookingDate',
                          visible: true
                      }
                  ]
                : []

        // Have to map new property as column header doesn't match field value
        const mappedItems = items.map(item => ({
            ...item,
            transportMode: item.mode ? item.mode : item.transportMode
        }))

        // Map headCells to match required properties for material-table
        function getHeaderColumns() {
            const columnHeaders =
                organization !== null && orgHeaderColumns !== null
                    ? orgHeaderColumns
                          .concat(extraCells)
                          .filter(item => item.visible)
                          .map(headcell => {
                              return {
                                  field: headcell.value,
                                  title: headcell.title,
                                  type: headcell.type,
                                  sortField: headcell.sortField,
                                  hidden: false,
                                  cellStyle: {
                                      whiteSpace: 'nowrap'
                                  },
                                  removable: true,
                                  hiddenByColumnsButton: true
                              }
                          })
                    : []
            return columnHeaders
        }

        // Checking if key exists in local storage
        if (localStorage.getItem(cachedHeaderKeyValue) === null) {
            // If key doesn't exists then store the actual column order in local storage
            localStorage.setItem(
                cachedHeaderKeyValue,
                JSON.stringify(getHeaderColumns())
            )
        } else {
            // Add/remove if updated in db
            let dbColumns = getHeaderColumns()
            let storeColumns = JSON.parse(
                localStorage.getItem(cachedHeaderKeyValue)
            )

            var i = storeColumns.length
            while (i--) {
                var dbCol = dbColumns.find(
                    column => column.field === storeColumns[i].field
                )
                if (dbCol === undefined) {
                    // remove column from local storage
                    storeColumns.splice(i, 1)
                } else {
                    // enforce column metadata from database
                    storeColumns[i].title = dbCol.title
                    storeColumns[i].type = dbCol.type
                    storeColumns[i].sortField = dbCol.sortField
                }
            }
            var i = dbColumns.length
            while (i--) {
                if (
                    storeColumns.find(
                        column => column.field === dbColumns[i].field
                    ) === undefined
                ) {
                    // add column to local storage
                    storeColumns.splice(i, 0, dbColumns[i])
                }
            }

            // update local
            localStorage.setItem(
                cachedHeaderKeyValue,
                JSON.stringify(storeColumns)
            )
        }

        // Retrieve column data from localstorage
        let savedColumns = JSON.parse(
            localStorage.getItem(cachedHeaderKeyValue)
        )

        // Need to add the render function as can't store this in localstorage
        var columnHeaders = savedColumns.map(column => {
            return {
                field: column.field,
                title: column.title,
                type: column.type,
                sortField: column.sortField,
                customSort: (a, b) => sortData(column, a, b),
                hidden: column.hidden,
                removable: column.removable,
                hiddenByColumnsButton: column.hiddenByColumnsButton,
                defaultFilter: getFilter(column.field),
                render: params => {
                    if (column.field === 'transportMode') {
                        return getIconForShipment(params)
                    } else if (column.field === 'shipmentNumber') {
                        return (
                            <NavLink
                                onClick={() => {
                                    this.saveShipmentFilterValuesToState(
                                        this.tableRef
                                    )
                                }}
                                to={this.getURLForItem(params.shipmentNumber)}>
                                {params.shipmentNumber}
                            </NavLink>
                        )
                    } else if (column.field === 'orderReferenceNumber') {
                        return this.renderNavLinkOrderRef(clientId, params)
                    } else if (column.field === 'orderRef') {
                        return this.renderToolTipOrderRef(params.orderRef)
                    } else if (
                        organization.containerTrackingURL !== null &&
                        column.field === 'containerNumber' &&
                        params.containerNumber !== '' &&
                        params.containerNumber !== null
                    ) {
                        return params.containerNumber.split(',').map(c => {
                            return (
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => {
                                        const newWindow = window.open(
                                            organization.containerTrackingURL.replace(
                                                '{CONTAINERNO}',
                                                c
                                            ),
                                            '_blank',
                                            'noopener,noreferrer'
                                        )
                                        if (newWindow) newWindow.opener = null
                                    }}>
                                    {c}
                                </Button>
                            )
                        })
                    } else if (column.field === 'shipmentFlagged') {
                        return params.shipmentFlagged ? 'YES' : null
                    } else {
                        return params[column.field]
                    }
                }
            }
        })

        function handleColumnDrag(sourceIndex, destinationIndex) {
            // take copy of source column
            const sourceColumn = savedColumns[sourceIndex]
            // remove source column
            savedColumns.splice(sourceIndex, 1)
            // insert at destination
            savedColumns.splice(destinationIndex, 0, sourceColumn)
            // update local storage
            localStorage.setItem(
                cachedHeaderKeyValue,
                JSON.stringify(savedColumns)
            )
        }

        function handleChangeColumnHidden(column, hidden) {
            // Update hidden flag
            for (let i = 0; i < savedColumns.length; i++) {
                if (savedColumns[i].field === column.field) {
                    // Set column hidden
                    savedColumns[i].hidden = hidden
                    localStorage.setItem(
                        cachedHeaderKeyValue,
                        JSON.stringify(savedColumns)
                    )
                    break
                }
            }
        }

        function getFilter(field) {
            const columnData = shipmentFilters.find(col => col.field === field)
            if (columnData) {
                return columnData.filterValue
            } else {
                return null
            }
        }

        return (
            <MaterialTable
                tableRef={this.tableRef}
                columns={columnHeaders}
                data={mappedItems}
                isLoading={this.state.searching}
                icons={{
                    SortArrow: forwardRef((props, ref) => (
                        <ArrowDownward {...props} ref={ref} />
                    ))
                }}
                actions={[
                    {
                        icon: () => {
                            return <FlagIcon />
                        },
                        tooltip: 'Toggle Shipment Flagged',
                        onClick: (event, rowData) => {
                            this.toggleShipmentFlagged(rowData)
                        }
                    }
                ]}
                options={{
                    filtering: true,
                    maxBodyHeight: 'calc(100vh - 348px)',
                    pageSizeOptions: [25, 50, 75],
                    sorting: true,
                    pageSize: 25,
                    showTitle: false,
                    search: true,
                    columnsButton: false,
                    doubleHorizontalScroll: true,
                    draggable: true,
                    showFirstLastPageButtons: true,
                    toolbar: true,
                    padding: 'dense',
                    pagination: {
                        rowsPerPage: 'Rows per page:',
                        displayRows: 'off'
                    },
                    exportButton: { csv: true },
                    exportAllData: true,
                    rowStyle: rowData => ({
                        // check if 'hot order'
                        backgroundColor: rowData.shipmentFlagged
                            ? '#F8AFA7'
                            : null
                    }),
                    exportCsv: (columns, data) => prepareCsvExport(columns, data, this.state.organization, searchItem + 'Export_' + Date.now())

                }}
                onColumnDragged={handleColumnDrag}
                onChangeColumnHidden={handleChangeColumnHidden}
            />
        )
    }

    renderSummary = () => {
        const { classes } = this.props
        const {
            resultsBox1Value,
            resultsBox1Title,
            resultsBox2Value,
            resultsBox2Title,
            resultsBox3Value,
            resultsBox3Title,
            resultsBox4Value,
            resultsBox4Title,
            resultsBox5Value,
            resultsBox5Title,
            key,
            currentSummaryTile
        } = this.state

        const handleSummaryTileClick = (tileName, tileId) => {
            // these may have changed
            this.saveShipmentFilterValuesToState(this.tableRef)
            var newShipmentList = this.state.items.filter(
                i => i.status !== null && i.status.includes(tileName)
            )
            this.setState({
                shipmentList: newShipmentList,
                currentSummaryTile: tileId
            })
            localforage.setItem(key + '.shipmentList', newShipmentList)
            localforage.setItem(key + '.currentSummaryTile', tileId)
        }

        return (
            <div className="theme-card root">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs>
                                <SummaryInfo
                                    className="theme-mini-card gm-blue"
                                    title={resultsBox1Title}
                                    data={resultsBox1Value}
                                    isClicked={currentSummaryTile === 1}    
                                    onClick={() => {
                                        this.setState({
                                            shipmentList: this.state.items,
                                            currentSummaryTile: 1
                                        })
                                        localforage.setItem(key + '.shipmentList', this.state.items)
                                        localforage.setItem(key + '.currentSummaryTile', 1)
                                    }}
                                />
                            </Grid>
                            <Grid item xs>
                                <SummaryInfo
                                    className="theme-mini-card gm-dark-orchid"
                                    title={resultsBox2Title}
                                    data={resultsBox2Value}
                                    isClicked={currentSummaryTile === 2}
                                    onClick={() => {
                                        handleSummaryTileClick(resultsBox2Title, 2)
                                    }}
                                />
                            </Grid>
                            <Grid item xs>
                                <SummaryInfo
                                    className="theme-mini-card gm-forest-green"
                                    title={resultsBox3Title}
                                    data={resultsBox3Value}
                                    isClicked={currentSummaryTile === 3}
                                    onClick={() => {
                                        handleSummaryTileClick(resultsBox3Title, 3)
                                    }}
                                />
                            </Grid>
                            <Grid item xs>
                                <SummaryInfo
                                    className="theme-mini-card gm-ruby"
                                    title={resultsBox4Title}
                                    data={resultsBox4Value}
                                    isClicked={currentSummaryTile === 4}
                                    onClick={() => {
                                        handleSummaryTileClick(resultsBox4Title, 4)
                                    }}
                                />
                            </Grid>
                            {resultsBox5Title !== null ? (
                                <Grid item xs>
                                    <SummaryInfo
                                        className="theme-mini-card gm-purple-heart"
                                        title={resultsBox5Title}
                                        data={resultsBox5Value}
                                        isClicked={currentSummaryTile === 5}
                                        onClick={() => {
                                            handleSummaryTileClick(resultsBox5Title, 5)
                                        }}
                                    />
                                </Grid>
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }

    render() {
        const { classes } = this.props

        const {
            clients,
            clientId,
            selectedDate,
            fromDate,
            toDate,
            minDate,
            todayDate,
            referenceType,
            dateView,
            itemReference,
            mode,
            organization,
            titleIcon,
            dateFiltering,
            shipmentList
        } = this.state

        const currentClient = clients.find(e => e.id === clientId)

        const primarycolor =
            organization !== null && organization.colorThemePrimaryRGB !== null
                ? `${organization.colorThemePrimaryRGB}`
                : '#ffffff'
        const secodarycolor =
            organization !== null &&
            organization.colorThemeSecondaryRGB !== null
                ? `${organization.colorThemeSecondaryRGB}`
                : '#000000'
        const teritorycolor =
            organization !== null && organization.colorThemeTertiaryRGB !== null
                ? `${organization.colorThemeTertiaryRGB}`
                : '#ffffff'
        const themeRGB =
            organization !== null && organization.colorThemeRGB !== null
                ? `${organization.colorThemeRGB}`
                : '#000000'
        const themeTextColor =
            organization !== null && organization.colorThemeTextRGB !== null
                ? `${organization.colorThemeTextRGB}`
                : '#000000'

        let contents = this.state.loading ? (
            <LinearProgress color="primary" />
        ) : (
            <div>
                <CardContent className="p-0">
                    <div className="d-flex align-items-center mb-20">
                        <h1 className="mb-0 text-black">
                            {this.state.searchItem + ' Search'}
                        </h1>
                        <Icon className="ml-2 font-40">{titleIcon}</Icon>
                    </div>
                    <Paper className="paper page-cover-height-200px">
                        <Grid container spacing={0} direction="column">
                            <Grid
                                item
                                xs={12}
                                className="mb-0 mb-4 w-100 d-flex">
                                <Grid item xs={3}>
                                    {this.state.error && (
                                        <Grid item xs={12}>
                                            <Alert
                                                className="mb-4"
                                                onClose={() => {
                                                    this.setState({
                                                        error: false
                                                    })
                                                }}
                                                severity="error">
                                                {' '}
                                                Sorry, there was an error while
                                                retrieving items.
                                            </Alert>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0}>
                            <Grid
                                container
                                spacing={0}
                                className="mb-4 w-100 d-flex mobile-mode-no-flex">
                                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <div
                                        className="mr-2 mb-3 map"
                                        style={{
                                            height: mapContainerStyle.height,
                                            position: 'relative',
                                            overflow: 'hidden'
                                        }}>
                                        {renderMap(shipmentList)}
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    xl={6}
                                    lg={6}
                                    md={6}
                                    sm={12}
                                    xs={12}
                                    className="p-25 pb-0 mt-2">
                                    <Grid
                                        container
                                        direction="row"
                                        space={2}
                                        spacing={2}
                                        className="mb-4 searchshipment-col-padding">
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="mb-20">
                                            <FormControl fullWidth={true}>
                                                <InputLabel id="select-label-client">
                                                    Client
                                                </InputLabel>
                                                <Select
                                                    labelId="select-label-client"
                                                    value={
                                                        clientId !== null
                                                            ? clients.findIndex(
                                                                  c =>
                                                                      c.id ===
                                                                      clientId
                                                              ) === -1
                                                                ? clients[0].id
                                                                : clientId
                                                            : clients[0].id
                                                    }
                                                    onChange={e =>
                                                        this.handleChangeValue(
                                                            e,
                                                            'client'
                                                        )
                                                    }>
                                                    {clients.map(function(
                                                        client,
                                                        i
                                                    ) {
                                                        return (
                                                            <MenuItem
                                                                key={i}
                                                                value={
                                                                    client.id
                                                                }>
                                                                {client.name}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="mb-20">
                                            <FormControl className="mr-auto">
                                                <div>
                                                    <InputLabel
                                                        id="select-label-mode"
                                                        className="my-auto">
                                                        Mode
                                                    </InputLabel>
                                                    <Select
                                                        labelId="select-label-mode"
                                                        id="mode"
                                                        value={mode}
                                                        onChange={e =>
                                                            this.handleChangeValue(
                                                                e,
                                                                'mode'
                                                            )
                                                        }>
                                                        <MenuItem value={'ALL'}>
                                                            All
                                                        </MenuItem>
                                                        <MenuItem value={'AIR'}>
                                                            Air
                                                        </MenuItem>
                                                        <MenuItem value={'SEA'}>
                                                            Sea
                                                        </MenuItem>
                                                        <MenuItem value={'ROA'}>
                                                            Road
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={'Courier'}>
                                                            COU
                                                        </MenuItem>
                                                    </Select>
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="mb-20">
                                            <FormControl fullWidth={true}>
                                                <InputLabel id="select-label-referencetype">
                                                    Search Type
                                                </InputLabel>
                                                <Select
                                                    labelId="select-label-referencetype"
                                                    id="reference-type"
                                                    value={referenceType}
                                                    onChange={e =>
                                                        this.handleChangeValue(
                                                            e,
                                                            'reference-type'
                                                        )
                                                    }>
                                                    {this.state.searchItem ===
                                                    'Bookings' ? (
                                                        <MenuItem
                                                            value={
                                                                'BOOKING ID'
                                                            }>
                                                            Booking #
                                                        </MenuItem>
                                                    ) : (
                                                        <MenuItem
                                                            value={
                                                                'SHIPMENT ID'
                                                            }>
                                                            Shipment #
                                                        </MenuItem>
                                                    )}
                                                    <MenuItem
                                                        value={'HOUSE BILL'}>
                                                        House Bill
                                                    </MenuItem>
                                                    <MenuItem
                                                        value={'MASTER BILL'}>
                                                        Master Bill
                                                    </MenuItem>
                                                    <MenuItem
                                                        value={
                                                            'LINKED ORDER REFS'
                                                        }>
                                                        Linked Order Refs
                                                    </MenuItem>
                                                    <MenuItem
                                                        value={'ORDER REFS'}>
                                                        Order Refs
                                                    </MenuItem>
                                                    <MenuItem
                                                        value={'OWNER REFS'}>
                                                        Owner Refs
                                                    </MenuItem>
                                                    <MenuItem
                                                        value={'SHIPPERS REF'}>
                                                        Shipper's Ref
                                                    </MenuItem>
                                                    <MenuItem
                                                        value={'CONTAINER#'}>
                                                        Container #
                                                    </MenuItem>
                                                    <MenuItem value={'VESSEL'}>
                                                        Vessel
                                                    </MenuItem>
                                                    <MenuItem
                                                        value={'SUPPLIERCODE'}>
                                                        Supplier Id
                                                    </MenuItem>
                                                    <MenuItem
                                                        value={'CARRIERID'}>
                                                        Carrier Id
                                                    </MenuItem>
                                                    {currentClient.clientView ===
                                                        'CLARK' ? (
                                                            <MenuItem
                                                                value={'ORIGINCOUNTRY'}>
                                                                Origin Country Code
                                                            </MenuItem>
                                                        ) : null}
                                                    <MenuItem
                                                        value={
                                                            'PickupWAREHOUSEID'
                                                        }>
                                                        Pickup Warehouse Id
                                                    </MenuItem>
                                                    <MenuItem value={'ALL'}>
                                                        All
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="mb-20">
                                            <FormControl
                                                className={classes.formControl}>
                                                <InputLabel id="Reference2">
                                                    Reference # (3 chars min)
                                                </InputLabel>
                                                <TextField
                                                    size="small"
                                                    id="reference"
                                                    labelid="Reference2"
                                                    placeholder="Reference # (3 chars min)"
                                                    value={itemReference || ''}
                                                    onChange={e =>
                                                        this.handleChangeValue(
                                                            e,
                                                            'reference'
                                                        )
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        space={2}
                                        spacing={2}
                                        className="mb-4 searchshipment-col-padding  ">
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="mb-20 mt-2">
                                            <FormControl className="mx-auto">
                                                <div>
                                                    <InputLabel
                                                        className="my-auto mx-auto flex-shrink-1"
                                                        id="select-label-dateview">
                                                        Date View
                                                    </InputLabel>
                                                    <Select
                                                        labelId="select-label-dateview"
                                                        id="dateView"
                                                        value={dateView}
                                                        onChange={e =>
                                                            this.handleChangeValue(
                                                                e,
                                                                'dateView'
                                                            )
                                                        }>
                                                        {this.state
                                                            .searchItem ===
                                                        'Bookings' ? (
                                                            <MenuItem
                                                                value={
                                                                    'BOOKED DATE'
                                                                }>
                                                                Booked Date
                                                            </MenuItem>
                                                        ) : null}
                                                        <MenuItem value={'ETD'}>
                                                            ETD
                                                        </MenuItem>
                                                        <MenuItem value={'ETA'}>
                                                            ETA
                                                        </MenuItem>
                                                        <MenuItem value={'ATA'}>
                                                            ATA
                                                        </MenuItem>
                                                        <MenuItem value={'ATD'}>
                                                            ATD
                                                        </MenuItem>
                                                        <MenuItem value={'Available'}>
                                                            Available
                                                        </MenuItem>
                                                        <MenuItem value={'ED'}>
                                                            Estimated Pickup
                                                        </MenuItem>
                                                        <MenuItem value={'Completed'}>
                                                            Pickup Completed
                                                        </MenuItem>
                                                    </Select>
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="mb-20 mt-2">
                                            <FormControl className="mx-auto">
                                                <div>
                                                    <InputLabel id="select-label-date">
                                                        Date
                                                    </InputLabel>
                                                    <Select
                                                        labelId="select-label-date"
                                                        value={selectedDate}
                                                        onChange={e =>
                                                            this.handleChangeValue(
                                                                e,
                                                                'selectedDate'
                                                            )
                                                        }>
                                                        {dateFiltering.map(
                                                            function(date, i) {
                                                                return (
                                                                    <MenuItem
                                                                        key={i}
                                                                        value={
                                                                            date.id
                                                                        }>
                                                                        {
                                                                            date.label
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                    </Select>
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="">
                                            <FormControl className="mx-auto calender-icon-controller">
                                                <div>
                                                    <MuiPickersUtilsProvider
                                                        utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            disabled={selectedDate === 10 ? true : false}
                                                            autoOk
                                                            format={organization.pickerDateFormat}
                                                            label="From Date"
                                                            value={
                                                                fromDate
                                                                    ? fromDate
                                                                    : minDate
                                                            }
                                                            onChange={e =>
                                                                this.handleChangeValue(
                                                                    e,
                                                                    'fromDate'
                                                                )
                                                            }
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="">
                                            <FormControl className="mx-auto  calender-icon-controller">
                                                <div>
                                                    <MuiPickersUtilsProvider
                                                        className={
                                                            classes.textField
                                                        }
                                                        utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            disabled={selectedDate === 10 ? true : false}
                                                            autoOk
                                                            format={organization.pickerDateFormat} 
                                                            label="To Date"
                                                            value={
                                                                toDate
                                                                    ? toDate
                                                                    : todayDate
                                                            }
                                                            minDate={
                                                                fromDate
                                                                    ? fromDate
                                                                    : todayDate
                                                            }
                                                            onChange={e =>
                                                                this.handleChangeValue(
                                                                    e,
                                                                    'toDate'
                                                                )
                                                            }
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        space={2}
                                        spacing={2}
                                        className="mb-4 searchshipment-col-padding mt-2 ">
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}>
                                            <Button
                                                className="MuiButton-containedPrimary submit w-100"
                                                style={{
                                                    background: primarycolor,
                                                    color: themeTextColor
                                                }}
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                disabled={this.state.searching}
                                                onClick={e =>
                                                    this.handleSubmit(true)
                                                }>
                                                {this.state.searching
                                                    ? 'Loading...'
                                                    : 'QUICK SEARCH'}
                                            </Button>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}>
                                            <Button
                                                style={{
                                                    border: primarycolor,
                                                    color: primarycolor
                                                }}
                                                size="small"
                                                className="submit mb-2 w-100 secondary"
                                                variant="contained"
                                                color="secondary"
                                                onClick={this.handleClearAll}>
                                                RESET SEARCH
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={0}
                                className="mb-2 w-100 d-flex align-items-center mobile-mode-no-flex">
                                <Grid item xs={12} className="px-4 w-100">
                                    {this.renderSummary()}
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} className="w-100 px-3">
                                <div className="psa-shipment-table custom-table-styles w-100">
                                    <Grid
                                        container
                                        spacing={0}
                                        className="mb-4 w-100">
                                        <Grid item xs={12}>
                                            <div className="first-row-filter-hidden">
                                                {this.renderShipmentsTable(
                                                    shipmentList
                                                )}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </CardContent>
            </div>
        )

        return <div>{contents}</div>
    }

    async populateClientsData() {
        const token = await authService.getAccessToken()
        const { clientId, key } = this.state
        const headers = {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }

        const response = await fetch('api/clients/getclientslist?module=pickups', headers)

        if (response.ok) {
            const data = await response.json()
            this.setState({ clients: data }, () => {
                const { clients } = this.state
                const cachedClientId = localStorage.getItem(key + '.clientId')
                if (cachedClientId) {
                    // see if cached client id is in clients array
                    const client = clients.find(
                        client => client.id === cachedClientId
                    )
                    if (client) {
                        // happy days
                    } else {
                        // set client id to first client in clients array
                        this.setState({ clientId: clients[0].id }, () => {
                            localStorage.setItem(key + '.clientId', clients[0].id)
                        })
                    }
                } else {
                    // set client id to first client in clients array
                    this.setState({ clientId: clients[0].id }, () => {
                        localStorage.setItem(key + '.clientId', clients[0].id)
                    })
                }
                localforage.setItem(key + '.clients', data)
            })
        } else {
            this.setState({ loading: false, error: true })
        }
    }

    async populateConfig() {
        const { key } = this.state
        const response = await fetch('api' + ApplicationPaths.AppInfo)
        const data = await response.json()
        this.setState({ 
            organization: data,
            dateFiltering: data.dateFilterTypeArray
        })
        localforage.setItem(key + '.organization', data)
    }

    getQueryString = (useQuickSearch = true) => {
        const {
            clientId,
            referenceType,
            fromDate,
            toDate,
            mode,
            itemReference,
            originUNLOCOCodeObject,
            originCountryCodeObject,
            originName,
            destinationUNLOCOCodeObject,
            destinationCountryCodeObject,
            destinationName,
            dateView,
            searchItem,
            selectedDate
        } = this.state

        const queryString = require('query-string')

        var qs = {}

        qs['SearchItem'] = searchItem
        qs['referenceType'] = referenceType
        qs['Mode'] = mode
        qs['UseQuickSearch'] = true

        if (itemReference !== null && itemReference.length > 1) {
            qs['ShipmentSearchNumber'] = itemReference
        }

        if (originUNLOCOCodeObject !== null) {
            qs['Origin'] = originUNLOCOCodeObject.unloco
        }

        if (originCountryCodeObject !== null) {
            qs['OriginCountryName'] = originCountryCodeObject.countryName
        }

        if (originName !== null) {
            qs['OriginName'] = originName
        }

        if (destinationUNLOCOCodeObject !== null) {
            qs['Destination'] = destinationUNLOCOCodeObject.unloco
        }

        if (destinationCountryCodeObject !== null) {
            qs['DestinationCountryName'] =
                destinationCountryCodeObject.countryName
        }

        if (destinationName !== null) {
            qs['DestinationName'] = destinationName
        }

        qs['DateType'] = dateView

        if (fromDate != null) {
            qs['FromDate'] = fromDate.toISOString(false)
        }

        if (toDate != null) {
            qs['ToDate'] = toDate.toISOString(false)
        }

        if (clientId !== null) {
            qs['ClientId'] = clientId
        }

        qs['SelectedDate'] = selectedDate

        return queryString.stringify(qs)
    }

    async toggleShipmentFlagged(rowData) {
        const token = await authService.getAccessToken()
        const rawResponse = await fetch('api/shipments/FlagShipment', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ClientId: this.state.clientId,
                FlaggedValue: rowData.shipmentFlagged ? false : true,
                ShipmentNumber: rowData.shipmentNumber
            })
        })

        if (rawResponse.ok) {
            // indicate success to user
            this.populateTableData()
        } else {
            var responseText = await rawResponse.text()
            console.log(responseText)
            if (rawResponse.status === 403) {
                // feature not enabled
                alert(responseText)
            } else {
                alert('Failed to flag shipment, please contact support.')
            }
        }
    }

    async populateTableData(useQuickSearch = true) {

        // set summary tiles back to default
        this.setState({ currentSummaryTile: 1 })
        
        const { clientId, key } = this.state
        this.setState({ searching: true })
        const token = await authService.getAccessToken()

        if (clientId !== null) {
            this.setState({ error: false })

            const headers = {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }

            const response = await fetch(
                `api/shipments/GetPickup?` +
                    this.getQueryString(useQuickSearch),
                headers
            )

            if (response.ok) {
                const data = await response.json()

                this.setState({
                    resultsBox1Value: data.resultsBox1Value,
                    resultsBox1Title: data.resultsBox1Title,
                    resultsBox2Value: data.resultsBox2Value,
                    resultsBox2Title: data.resultsBox2Title,
                    resultsBox3Value: data.resultsBox3Value,
                    resultsBox3Title: data.resultsBox3Title,
                    resultsBox4Value: data.resultsBox4Value,
                    resultsBox4Title: data.resultsBox4Title,
                    resultsBox5Value: data.resultsBox5Value,
                    resultsBox5Title: data.resultsBox5Title
                })

                localforage.setItem(key + '.shipmentList', data.pickups)
                localforage.setItem(key + '.items', data.pickups)
                localStorage.setItem(
                    key + '.resultsBox1Value',
                    data.resultsBox1Value
                )
                localStorage.setItem(
                    key + '.resultsBox1Title',
                    data.resultsBox1Title
                )
                localStorage.setItem(
                    key + '.resultsBox2Value',
                    data.resultsBox2Value
                )
                localStorage.setItem(
                    key + '.resultsBox2Title',
                    data.resultsBox2Title
                )
                localStorage.setItem(
                    key + '.resultsBox3Value',
                    data.resultsBox3Value
                )
                localStorage.setItem(
                    key + '.resultsBox3Title',
                    data.resultsBox3Title
                )
                localStorage.setItem(
                    key + '.resultsBox4Value',
                    data.resultsBox4Value
                )
                localStorage.setItem(
                    key + '.resultsBox4Title',
                    data.resultsBox4Title
                )
                localStorage.setItem(
                    key + '.resultsBox5Value',
                    data.resultsBox5Value
                )
                localStorage.setItem(
                    key + '.resultsBox5Title',
                    data.resultsBox5Title
                )

                this.setState({
                    items: data.pickups,
                    shipmentList: data.pickups,
                    loading: false
                })
            } else {
                this.setState({ loading: false, error: true })
            }
        } else {
            this.setState({ loading: false })
        }

        this.setState({ searching: false })
    }
}
export default withStyles(styles)(PickupTable)
