import React from 'react'
import { Grid } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import IconButton from '@material-ui/core/IconButton'

const steps = [
    { index: 0, title: 'Order Details', href: '/orders/create' },
    { index: 1, title: 'Notes', href: '/orders/create/notes' },
    { index: 2, title: 'Lines', href: '/orders/create/lines' },
    { index: 3, title: 'Review', href: '/orders/create/review' }
]

export default function OrderCreationProgress({ index, hideIndexes = [] }) {
    const renderStepButton = (step, checked) => {
        return (
            <IconButton
                color="primary"
                onClick={() => (window.location.href = step.href)}>
                {checked ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
            </IconButton>
        )
    }

    const renderStep = (step, i) => {
        if (hideIndexes.includes(i)) {
            return null
        }

        return (
            <Grid
                container
                alignItems="center"
                justify="center"
                item
                xs={2}
                direction="column">
                <Grid item xs className="mb-2">
                    {step.index < index && renderStepButton(step, true)}
                    {step.index >= index && renderStepButton(step, false)}
                </Grid>
                <Grid item xs>
                    {step.title}
                </Grid>
            </Grid>
        )
    }
    return (
        <div className="py-4">
            <Grid justify="center" container className="w-100" spacing={3}>
                {steps.map((step, i) => {
                    return renderStep(step, i)
                })}
            </Grid>
        </div>
    )
}
