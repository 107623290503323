import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CardTitle from './CardTitle'
import { Tooltip, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { NavLink } from 'react-router-dom'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

const classes = makeStyles(theme => ({}))

export default function ShipmentDetailCard({
    item,
    itemextra,
    shippersRefLabel,
    shippersRef,
    ownerRef,
    orderRef,
    quantity,
    volumeLabel,
    volume,
    weight,
    organization,
    clientId,
    showOrders,
    showShippersRef = true,
    showOwnersRef = true
}) {
    const theme = useTheme()

    const renderNavLinkOrderRef = (clientId, shipmentData) => {
        var orderRefs = shipmentData.orderReferenceNumber
        if (orderRefs) {
            return (
                <>
                    {orderRefs.split(',').map((refnum, index) => {
                        if (shipmentData.orders) {
                            var order = shipmentData.orders.find(
                                order => order.orderNumber === refnum.trim()
                            )
                            if (order) {
                                return (
                                    showOrders ? (
                                        <NavLink
                                            key={index}
                                            to={
                                                '/orders/' +
                                                clientId +
                                                '/' +
                                                btoa(refnum.trim()).replace(
                                                    '/',
                                                    '_'
                                                ) +
                                                '/' +
                                                order.orderNumberSplit
                                            }>
                                            {refnum}
                                        </NavLink>
                                    ) : (
                                        <span>{refnum}</span>
                                    )
                                )
                            } else {
                                return <span>{refnum}</span>
                            }
                        } else {
                            return <span>{refnum}</span>
                        }
                    })}
                </>
            )
        } else {
            return <>-</>
        }
    }

    const renderToolTipOrderRef = orderRef => {
        var orderRefs = ''
        var array = []
        if (item !== null && orderRef != null) {
            array = orderRef.split(',')
            if (array.length <= 2) {
                orderRefs = array.join()
            } else {
                orderRefs = array[0]
            }
        }

        return (
            <>
                {orderRefs}
                {array.length > 2 ? (
                    <Tooltip
                        title={
                            <React.Fragment>
                                <p>{array.join()}</p>
                            </React.Fragment>
                        }
                        placement="right">
                        <MoreHorizIcon />
                    </Tooltip>
                ) : (
                    ''
                )}
            </>
        )
    }

    return (
        <div className="p-0 pl-3">
            <h5 className="text-Curious-blue my-2 font-14">Shipment Details</h5>
            <Grid
                container
                direction="row"
                spacing={1}
                className={theme.bgColorSection}>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={1}>
                        <Grid item xs={4} className="sm-flex-100">
                            <div>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className="font-12 font-raleway-medium text-lviv-blue">
                                    Weight
                                </Typography>
                                <p className="mb-0 font-14  font-nutosans-bold text-black">
                                    {' '}
                                    {(item !== null && weight) || '-'}
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <div>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className="font-12 font-raleway-medium text-lviv-blue">
                                    {volumeLabel}
                                </Typography>
                                <p className="mb-0 font-14  font-nutosans-bold text-black">
                                    {' '}
                                    {(item !== null && volume) || '-'}
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <div>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className="font-12 font-raleway-medium text-lviv-blue">
                                    Quantity
                                </Typography>
                                <p className="mb-0 font-14  font-nutosans-bold text-black">
                                    {' '}
                                    {(itemextra !== null &&
                                        itemextra.quantity) ||
                                        '-'}
                                </p>
                            </div>
                        </Grid>
                        { showShippersRef && (
                            <Grid item xs={4} className="sm-flex-100">
                                <div>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className="font-12 font-raleway-medium text-lviv-blue">
                                        {shippersRefLabel}
                                    </Typography>
                                    <p className="mb-0 font-14  font-nutosans-bold text-black">
                                        {' '}
                                        {(item !== null && shippersRef) || '-'}
                                    </p>
                                </div>
                            </Grid>
                        )}
                        <Grid
                            item
                            xs={4}
                            hidden={
                                organization !== null &&
                                organization.shipmentServiceSelected ==
                                    'NORMANHK'
                            }
                            className="sm-flex-100">
                            <div>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className="font-12 font-raleway-medium text-lviv-blue">
                                    Linked Order #
                                </Typography>
                                <p className="mb-0 font-14  font-nutosans-bold text-black">
                                    {renderNavLinkOrderRef(clientId, item)}
                                </p>
                            </div>
                        </Grid>
                        { showOwnersRef && (
                            <Grid
                                item
                                xs={4}
                                hidden={
                                    organization !== null &&
                                    organization.shipmentServiceSelected ==
                                        'NORMANHK'
                                }
                                className="sm-flex-100">
                                <div>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className="font-12 font-raleway-medium text-lviv-blue">
                                        Owner's Ref#
                                    </Typography>
                                    <p className="mb-0 font-14  font-nutosans-bold text-black">
                                        {(item !== null && ownerRef) || '-'}
                                    </p>
                                </div>
                            </Grid>
                        )}
                        <Grid item xs={4} className="sm-flex-100">
                            <div>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className="font-12 font-raleway-medium text-lviv-blue">
                                    Order's Ref#
                                </Typography>
                                <p className="mb-0 font-14  font-nutosans-bold text-black">
                                    {' '}
                                    {orderRef
                                        ? renderToolTipOrderRef(orderRef)
                                        : '-'}
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={8} className="sm-flex-100">
                            <div>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className="font-12 font-raleway-medium text-lviv-blue">
                                    {' '}
                                    Description{' '}
                                </Typography>
                                <p className="mb-0 font-14  font-nutosans-bold text-black">
                                    {' '}
                                    {(item !== null && item.goodsDescription) ||
                                        '-'}
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
