import authService from '../components/api-authorization/AuthorizeService'

export async function trackActivity(pathname) {
    const token = await authService.getAccessToken()
    const activity = JSON.stringify({
        pathname: pathname
    })
    await fetch(`api/Interactions/LogActivity`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: activity
    })
        .then(response => {
            // thanks for logging
        })
        .catch(error => {
            // thanks for not logging
        })
  }
  