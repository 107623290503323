const orderIdKey = 'order.create.orderId'

export const redirectUrlForPacklines = () => {
    return '/orders/create/review'
}

export const backUrlForReview = () => {
    return '/orders/create/lines'
}

export const getOrderKey = () => {
    return sessionStorage.getItem(orderIdKey)
}

export const removeOrderKey = () => {
    return sessionStorage.removeItem(orderIdKey)
}
