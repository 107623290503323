import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { renderTable } from '../shared/TableHelper'
import CardTitle from '../shared/CardTitle'

const notes = [
    { key: 'title', label: 'Note Type', icon: false },
    { key: 'date', label: 'Date', icon: false },
    { key: 'note', label: 'Detail', icon: false }
]

const notesReview = [
    { key: 'noteTypeCode', label: 'Note Type', icon: false },
    { key: 'noteText', label: 'Text', icon: false }
]

export default function OrderNotesTable({ item, isReview, classes }) {
    return item !== null &&
        item !== undefined &&
        item.notes !== null &&
        item.notes !== undefined ? (
        <CardTitle title="Notes" checkOn={false} disablePadding={true}>
            {renderTable(
                item.notes,
                isReview ? notesReview : notes,
                'notes',
                classes,
                () => {},
                {
                    maxHeight: '200px'
                }
            )}
        </CardTitle>
    ) : (
        <React.Fragment />
    )
}
