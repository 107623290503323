import React from 'react'
import Alert from '@material-ui/lab/Alert'
import { Table, TableContainer, TableBody } from '@material-ui/core'
import { TableHead, TableRow, TableCell } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'

export function renderTable(
    baseArray,
    arrayKeys,
    itemKey,
    classes,
    renderLink = () => {},
    tableStyles
) {
    return (
        <div>
            <TableContainer style={tableStyles}>
                <Table
                    stickyHeader
                    id="key"
                    className=""
                    aria-label="simple table"
                    size="small">
                    <TableHead>
                        <TableRow>
                            {arrayKeys?.map(arrayKey => {
                                return (
                                    <TableCell
                                        className={classes.title}
                                        key={
                                            'header_' +
                                            itemKey +
                                            '_' +
                                            arrayKey.key
                                        }>
                                        {arrayKey.label}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {baseArray?.map((item, index) => {
                            return (
                                <TableRow key={index}>
                                    {arrayKeys?.map(arrayKey => {
                                        return (
                                            <TableCell
                                                key={
                                                    itemKey +
                                                    '_' +
                                                    arrayKey.key +
                                                    '_' +
                                                    index
                                                }>
                                                <div className="d-flex flex-col">
                                                    {arrayKey.icon &&
                                                        item['actualDate'] && (
                                                            <div className="mr-2">
                                                                <CheckIcon />
                                                            </div>
                                                        )}
                                                    {arrayKey.icon &&
                                                        !item['actualDate'] && (
                                                            <div className="mr-2 invisible">
                                                                <CheckIcon />
                                                            </div>
                                                        )}
                                                    {arrayKey.link ? (
                                                        renderLink(
                                                            item,
                                                            index,
                                                            arrayKey
                                                        )
                                                    ) : (
                                                        <div>
                                                            {item[
                                                                arrayKey.key
                                                            ] || '-'}
                                                        </div>
                                                    )}
                                                </div>
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {baseArray?.length === 0 && (
                <Alert severity="info" className="w-100">
                    No records found.
                </Alert>
            )}
        </div>
    )
}
