import React, { useEffect, useState } from 'react'
import OrderCreationProgress from './OrderCreationProgress'
import authService from '../api-authorization/AuthorizeService'
import {
    getOrderKey,
    backUrlForReview,
    removeOrderKey
} from '../../utils/order'
import { getCreatedOrder } from '../../api/api'
import { Grid, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import _, { set } from 'lodash'
import '../shared/OrderDetailCard'
import CreateOrderDetailsCard from '../shared/CreateOrderDetailsCard'
import OrderNotesTable from '../shared/OrderNotesTable'
//import OrderLinesTable from '../shared/OrderLinesTable'
import CreateOrderLinesTable from '../shared/CreateOrderLinesTable'
import ShipmentContactCard from '../shared/ShipmentContactCard'
import DateFnsUtils from '@date-io/moment'
import DialogOk from '../shared/DialogOk'
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants'
import { useHistory } from 'react-router-dom'
import Alert from '@material-ui/lab/Alert'

const classes = makeStyles(theme => ({}))

export default function CreateOrderReview() {
    const [order, setOrder] = useState([])
    const [backURL, setBackURL] = useState('')
    const [dialogOpen, setDialogOpen] = useState(false)
    const [submitSuccess, setSubmitSuccess] = useState(false)
    const [createdOrder, setCreatedOrder] = useState(null)
    const [dialogMessage, setDialogMessage] = useState('')
    const [alert, setAlert] = useState([
        {
            open: false,
            success: false,
            message: ''
        }
    ])
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [organization, setOrganization] = useState(null)

    const dateFns = new DateFnsUtils()
    const history = useHistory();

    useEffect(() => {
        const orderNumber = getOrderKey()

        fetch('api' + ApplicationPaths.AppInfo).then(response => {
            response.json().then(data => {
                setOrganization(data)
            })
        })

        if (orderNumber !== null && orderNumber !== undefined) {
            getCreatedOrder(orderNumber, data => {
                setOrder(data)
                setBackURL(backUrlForReview())
            })
        } else {
            window.location.replace('/orders/create')
        }
    }, [])

    const saveOrderTemplate = async (data) => {
        const templateName = prompt('Please Enter Order Template Name:')
        if (templateName === null || templateName === '' || templateName.length < 4) {
            setIsSubmitting(false)
            setAlert({
                open: true,
                success: false,
                message: 'Please enter a valid template name (minimum 4 characters).'
            })
            return
        } else {
            data.templateName = templateName
        }
        setIsSubmitting(true)
        const token = await authService.getAccessToken()

        await fetch('api/createOrders/SaveOrderTemplate', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(async response => {
                if (response.ok) {
                    setAlert({
                        open: true,
                        success: true,
                        message: 'Order template saved successfully.'
                    })

                } else {
                    setAlert({
                        open: true,
                        success: false,
                        message: 'Sorry, there was an error while saving this order template.'
                    })
                }
            })
            .catch(error => {
                setAlert({
                    open: true,
                    success: false,
                    message:
                        'Sorry, there was an error while saving this order template.'
                })
                setIsSubmitting(false)
            })
        // probably don't need this
        setIsSubmitting(false)
    }

    const saveOrderData = async (data, isTemplate) => {
        setIsSubmitting(true)
        const token = await authService.getAccessToken()
        await fetch('api/createOrders/PutCreateOrder?shouldSubmit=true', {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(async response => {
                if (response.ok) {
                    const newData = await response.json()

                    if (newData.orderCreated === true) {
                        setDialogOpen(true)
                        setSubmitSuccess(true)
                        setCreatedOrder(newData)
                        setDialogMessage(
                            'Order creation was successful, your order reference is ' +
                            newData.orderNumber +
                            ' from ' +
                            newData.organizationName 
                        )
                        removeOrderKey()
                    } else {
                        setDialogOpen(true)
                        setSubmitSuccess(false)
                        setDialogMessage(
                            'There was an error creating this order: ' +
                                newData.errorMessage || ''
                        )
                    }
                } else {
                    setAlert({
                        open: true,
                        success: false,
                        message:
                            'Sorry, there was an error while saving this details.'
                    })
                }
            })
            .catch(error => {
                setAlert({
                    open: true,
                    success: false,
                    message:
                        'Sorry, there was an error while saving this order information.'
                })
                setIsSubmitting(false)
            })
        // probably don't need this
        setIsSubmitting(false)
    }

    const handleCloseAlert = () => {
        setAlert({
            open: false,
            success: false,
            message: ''
        })
    }

    const goToSection = (path) => {
        history.push(path)
    }

    const renderAlert = () => {
        return alert.open ? (
            <div className="w-100">
                <Alert
                    variant="filled"
                    severity={alert.success ? 'success' : 'error'}
                    onClose={() => {
                        handleCloseAlert()
                    }}>
                    {alert.message}
                </Alert>
            </div>
        ) : null
    }

    const getShipperName = () => {
        if (
            order !== null &&
            order.contacts !== null &&
            order.contacts !== undefined
        ) {
            const contacts = order.contacts.filter(
                contact => contact.addressType === 'PIC'
            )
            if (contacts.length > 0) {
                return contacts[0].name
            }
        }

        return '-'
    }

    const getConsigeeName = () => {
        if (
            order !== null &&
            order.contacts !== null &&
            order.contacts !== undefined
        ) {
            const contacts = order.contacts.filter(
                contact => contact.addressType === 'DLV'
            )
            if (contacts.length > 0) {
                return contacts[0].name
            }
        }

        return '-'
    }

    const handleActionDialog = action => {
        setDialogOpen(false)
        if (submitSuccess) {
            goToSection(ApplicationPaths.Orders + '/' + createdOrder.clientId + '/' + btoa(createdOrder.orderNumber) + '/0')
        }
    }

    return (
        <div>
            {renderAlert()}
            <div className="mb-4 w-100">
                <OrderCreationProgress
                    index={3}
                />
                <Grid container spacing={2} className="mb-4">
                    <Grid item xs={8}>
                        <CreateOrderDetailsCard
                            item={order}
                            title={'Order Details'}
                            organization={organization}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <OrderNotesTable
                            item={order}
                            isReview={true}
                            classes={classes}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} className="mb-4">
                    <Grid item xs={4}>
                        {order !== null &&
                            order !== undefined &&
                            order.contacts !== null &&
                            order.contacts !== undefined && (
                                <ShipmentContactCard
                                    title="Shipper"
                                    item={
                                        order.contacts.filter(
                                            contact =>
                                                contact.addressType === 'PIC'
                                        )[0]
                                    }
                                />
                            )}
                    </Grid>
                    <Grid item xs={4}>
                        {order !== null &&
                            order !== undefined &&
                            order.contacts !== null &&
                            order.contacts !== undefined && (
                                <ShipmentContactCard
                                    title="Consignee"
                                    item={
                                        order.contacts.filter(
                                            contact =>
                                                contact.addressType === 'DLV'
                                        )[0]
                                    }
                                />
                            )}
                    </Grid>
                    <Grid item xs={4} />
                </Grid>
                <Grid container className="mb-4">
                    <CreateOrderLinesTable
                        item={order}
                        organization={organization}
                    />
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <div className="w-100 mb-4 d-flex justify-content-end">
                            <Button
                                className={`mr-4 ${classes.submit}`}
                                onClick={() => window.location.replace(backURL)}
                                variant="contained"
                                disabled={isSubmitting}
                                color="primary">
                                Back
                            </Button>
                            <Button
                                onClick={() => {
                                    if (window.confirm('Are you sure you want to clear all details and start over?')) {
                                        removeOrderKey()
                                        window.location.replace('/orders/create')
                                    }
                                }}
                                className={`mr-4 ${classes.submit}`}
                                variant="contained"
                                disabled={isSubmitting}
                                color="primary">
                                Reset Form
                            </Button>
                            <Button
                                onClick={() => {
                                    // need to save as template
                                    saveOrderTemplate(_.omitBy(order, _.isNil))
                                }}
                                className={`mr-4 ${classes.submit}`}
                                variant="contained"
                                disabled={isSubmitting}
                                color="primary">
                                Save As Template
                            </Button>
                            <Button
                                type="submit"
                                onClick={() => {
                                    saveOrderData(
                                        _.omitBy(order, _.isNil)
                                    )
                                }}
                                className={classes.submit}
                                variant="contained"
                                disabled={isSubmitting}
                                color="primary">
                                {isSubmitting ? 'Submitting...' : 'Submit Order'}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
                <DialogOk
                    callback={handleActionDialog}
                    show={dialogOpen}
                    title="Order submission">
                    <p>{dialogMessage}</p>
                </DialogOk>
            </div>
        </div>
    )
}
