const shipmentIdKey = 'shipment.create.shipmentId'

export const isNotFclOrFtl = containerType => {
    return containerType !== 'FCL' && containerType !== 'FTL'
}

export const getShipmentKey = () => {
    return sessionStorage.getItem(shipmentIdKey)
}

export const removeShipmentKey = () => {
    return sessionStorage.removeItem(shipmentIdKey)
}
