import authService from "../api-authorization/AuthorizeService"

export async function retrieveView(clientId, viewCode) {
    const token = await authService.getAccessToken()
    const response = await fetch(
        'api/Views/GetView?viewCode=' + viewCode + ( clientId ? '&clientId=' + clientId : '' ),
        {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
    )
    if (response.ok) {
        const data = await response.json()
        if (!data) {
            return(null)
        } else {
            return(data)
        }
    }
}

export async function retrievePageFields(pageCode) {
    const token = await authService.getAccessToken()
    const response = await fetch(
        'api/Views/GetPageFields?pageCode=' + pageCode,
        {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
    )
    if (response.ok) {
        const data = await response.json()
        if (!data) {
            return(null)
        } else {
            return(data)
        }
    }
}


export async function updateAllViewColumnDefinitionMetaData() {
    const token = await authService.getAccessToken()
    const response = await fetch(
        'api/Views/UpdateAllColumnDefinitionMetaData',
        {
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
            method: 'POST'
        }
    )

    if (response.ok) {
        return true
    }
    else {
        return false
    }
}


export async function retrieveViewDefinitions(viewCode, clientId, isUserView) { 
    const token = await authService.getAccessToken()
    const fetchURL = isUserView ? ('api/Views/GetView?viewCode=' + viewCode + '&clientId=' + clientId) : ('api/Views/GetViews?viewCode=' + viewCode)
    const response = await fetch(
        fetchURL, 
        {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
    )
    if (response.ok) {
        const data = await response.json()
        if (!data) {
            return(null)
        } else {
            if (isUserView) { 
                return [data] 
            }
            return(data)
        }
    }
}

export async function getClientViewsList() {
    const token = await authService.getAccessToken()
    const response = await fetch(
        'api/Views/GetClientViews',
        {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
    )
    if (response.ok) {
        const data = await response.json()
        if (!data) {
            return(null)
        } else {
            return(data)
        }
    }
}

export async function saveUserView(viewCode, columnDefinitions) {
    const token = await authService.getAccessToken()
    const stringColumnDefs = JSON.stringify(columnDefinitions)
    const postDataObj = {
        viewCode: viewCode,
        columnDefinitions: btoa(stringColumnDefs)
    }
    fetch(
        'api/Views/SaveUserView',
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postDataObj)
        }
    )
        .then(async response => {
            if (response.ok) {
                // okay
            }
            else {
                // not okay
            }
        })
        .catch(e => {
            // not okay
        })
}

export async function deleteView(view, handleSuccess, handleError, isUserView ) {
    const token = await authService.getAccessToken()
    const postDataObj = {
        clientViewId: view.clientViewId,
        viewId: view.id,
        userId: view.userId
    }
    fetch(
        isUserView ? 'api/Views/DeleteUserView' : 'api/Views/DeleteView',
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postDataObj)
        }
    )
        .then(async response => {
            if (response.ok) {
                // okay
                handleSuccess()
            }
            else {
                // get error message from response and pass to handleError
                const errorMessage = await response.text()
                handleError(errorMessage)
            }
        })
        .catch(e => {
            // not okay
            handleError(e)
        })
}

export async function createView(viewName, handleSuccess, handleError) {
    const token = await authService.getAccessToken()
    const postDataObj = {
        viewName: viewName
    }
    fetch(
        'api/Views/CreateView',
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postDataObj)
        }
    )
        .then(async response => {
            if (response.ok) {
                // okay
                handleSuccess()
            }
            else {
                // get error message from response and pass to handleError
                const errorMessage = await response.text()
                handleError(errorMessage)
            }
        })
        .catch(e => {
            // not okay
            handleError(e)
        })
}

export async function saveViewColumns(viewCode, columnDefinitions, viewId, clientViewId, isUserView, clientId) {
    const token = await authService.getAccessToken()
    const stringColumnDefs = JSON.stringify(columnDefinitions)
    const postDataObj = {
            viewCode: viewCode,
            id: viewId,
            clientViewId: clientViewId,
            columnDefinitions: btoa(stringColumnDefs),
            clientId: clientId
    }
    
    const apiURL = isUserView ? 'api/Views/SaveUserViewColumns' : 'api/Views/SaveViewColumns'
    await fetch(
        apiURL,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(postDataObj)
        }
    )
        .then(async response => {
            if (response.ok) {
                // okay
            }
            else {
                // not okay
            }
        })
        .catch(e => {
            // not okay
        })
}

export function getViewCodeBySearchItem(searchItem) {
    switch (searchItem) {
        case 'Shipments':
            return('ShipmentSearchGrid')
        case 'Orders':
            return('OrderSearchGrid')
        case 'OrderLines':
            return('OrderLineSearchGrid')
        case 'Bookings':
            return('BookingSearchGrid')
        case 'Customs':
            return('CustomsSearchGrid')
        case 'Shipments':
            return('ShipmentSearchGrid')
        case 'Accounting':
            return('AccountingSearchGrid')
        case 'Container':
            return('ContainerSearchGrid')
    }
}

export function getViewNameBySearchItem(searchItem) {
    switch (searchItem) {
        case 'Shipments':
            return('Shipment Search')
        case 'Orders':
            return('Order Search')
        case 'OrderLines':
            return('Order Line Search')
        case 'Bookings':
            return('Booking Search')
        case 'Customs':
            return('Customs Search')
        case 'Shipments':
            return('Shipment Search')
        case 'Accounting':
            return('Accounting Search')
        case 'Containers':
            return('Container Search')
    }
}
