import React from 'react'
import { Grid } from '@material-ui/core'
import CardTitle from './CardTitle'
import { useTheme } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import DateFnsUtils from '@date-io/moment'
import { getOrgNameFromOrganization } from '../../utils/organizations'

export default function CreateOrderDetailsCard({ item, title, organization }) {
    const theme = useTheme()
    const dateFns = new DateFnsUtils()
    const getFormattedDate = (date, organization) => {
        if (date === null || date === undefined || date === '0001-01-01T00:00:00' || organization === null || organization === undefined) {
            return '-'
        }
        let dateFormat = organization?.pickerDateFormat
        return dateFns.moment(date).format(dateFormat)
    }
    const showEstimatedArrival = () => {
        if (getOrgNameFromOrganization(organization) === 'HAVI') {
            return false
        }
        return true
    }

    const showExFactory = () => {
        if (getOrgNameFromOrganization(organization) === 'HAVI') {
            return false
        }
        return true
    }

    return (
        <CardTitle title={title} checkOn={false}>
            <Grid container spacing={1} className={theme.bgColorSection}>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                Order #
                            </Typography>
                            <p class="mb-0 font-16  font-nutosans-bold text-black">
                                {item.orderNumber || '-'}
                            </p>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                Order # Split
                            </Typography>
                            <p class="mb-0 font-14  font-nutosans-bold text-black">
                                {item.orderNumberSplit || '-'}
                            </p>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                Order Date
                            </Typography>
                            <p class="mb-0 font-14  font-nutosans-bold text-black">
                                {getFormattedDate(item.orderDate, organization)}
                            </p>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                Transport Mode
                            </Typography>
                            <p class="mb-0 font-14  font-nutosans-bold text-black">
                                {item.transportModeCode || '-'}
                            </p>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                Inco Terms
                            </Typography>
                            <p class="mb-0 font-14  font-nutosans-bold text-black">
                                {item.incoTermsCode || '-'}
                            </p>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                Service Level
                            </Typography>
                            <p class="mb-0 font-14  font-nutosans-bold text-black">
                                {item.serviceLevelCode || '-'}
                            </p>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                Origin
                            </Typography>
                            <p class="mb-0 font-14  font-nutosans-bold text-black">
                                {item.originUNLOCOCode || '-'}
                            </p>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                Destination
                            </Typography>
                            <p class="mb-0 font-14  font-nutosans-bold text-black">
                                {item.destinationUNLOCOCode || '-'}
                            </p>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                Load Port
                            </Typography>
                            <p class="mb-0 font-14  font-nutosans-bold text-black">
                                {item.loadPort || '-'}
                            </p>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                Discharge Port
                            </Typography>
                            <p class="mb-0 font-14  font-nutosans-bold text-black">
                                {item.dischargePort || '-'}
                            </p>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                Ex-Works Required By
                            </Typography>
                            <p class="mb-0 font-14  font-nutosans-bold text-black">
                                {getFormattedDate(item.exWorksRequiredBy, organization)}
                            </p>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                Delivery Required By
                            </Typography>
                            <p class="mb-0 font-14  font-nutosans-bold text-black">
                                {getFormattedDate(item.deliveryRequiredBy, organization)}
                            </p>
                        </Grid>
                        {
                            showExFactory() && (
                                <Grid item xs={4} className="sm-flex-100">
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className="font-12 font-raleway-medium text-lviv-blue">
                                        Estimated Ex-Factory
                                    </Typography>
                                    <p class="mb-0 font-14  font-nutosans-bold text-black">
                                        {getFormattedDate(item.estimatedExFactory, organization)}
                                    </p>
                                </Grid>
                            )
                        }
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                Estimated Origin Receipt
                            </Typography>
                            <p class="mb-0 font-14  font-nutosans-bold text-black">
                                {getFormattedDate(item.estimatedOriginReceipt, organization)}
                            </p>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                Estimated Departure
                            </Typography>
                            <p class="mb-0 font-14  font-nutosans-bold text-black">
                                {getFormattedDate(item.estimatedDeparture, organization)}
                            </p>
                        </Grid>
                        {
                            showEstimatedArrival() && (
                                <Grid item xs={4} className="sm-flex-100">
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className="font-12 font-raleway-medium text-lviv-blue">
                                        Estimated Arrival
                                    </Typography>
                                    <p class="mb-0 font-14  font-nutosans-bold text-black">
                                        {getFormattedDate(item.estimatedArrival, organization)}
                                    </p>
                                </Grid>
                            )
                        }
                        <Grid item xs={4} className="sm-flex-100">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="font-12 font-raleway-medium text-lviv-blue">
                                Estimated Delivery
                            </Typography>
                            <p class="mb-0 font-14  font-nutosans-bold text-black">
                                {getFormattedDate(item.estimatedDelivery, organization)}
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CardTitle>
    )
}
